/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Navbar from "../components/navbar/Navbar";
import {
  fetchInvoiceSummary,
  fetchProfileDetails,
  fetchProjectSummary,
  fetchSalesGraphData,
} from "../redux/profile/ProfileActions";
import { getAllInvoices } from "../services/api.services";

function Page({ children }) {
  // selector
  const id = useSelector((state) => state?.Profile?.decode?._id);

  // dispatch
  const dispatch = useDispatch();

  // fetch invoices
  const fetchInvoices = async () => {
    try {
      const form = {
        agency: id,
      };
      const { data } = await getAllInvoices(form);
      if (data?.success) {
        dispatch({
          type: "GET_INVOICES",
          payload: data?.data,
        });
      }
    } catch (e) {}
  };

  useEffect(() => {
    if (id) {
      dispatch(fetchProfileDetails(id));
      dispatch(fetchInvoiceSummary(id));
      dispatch(fetchProjectSummary(id));
      dispatch(fetchSalesGraphData(6, id));
      fetchInvoices();
    }
  }, [id]);

  return (
    <>
      <Navbar />
      {children}
    </>
  );
}

export default Page;
