import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";

function Alert(props) {
  return <MuiAlert elevation={6} {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

function Toast() {
  const classes = useStyles();
  // DISPATCH
  const dispatch = useDispatch();

  // SELECTOR
  const util = useSelector((state) => state.Util);

  // HANDLE CLOSE
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    dispatch({
      type: "ALERT-OFF",
    });
  };

  return (
    <div className={classes.root}>
      <Snackbar open={util.open} autoHideDuration={2000} onClose={handleClose}>
        <Alert severity={util.severity} variant="filled">{util.msg}</Alert>
      </Snackbar>
    </div>
  );
}

export default Toast;
