import React, { useEffect, useState } from "react";
import "./ProjectManagement.scss";
import active from "../../asset/images/active.png";
import completed from "../../asset/images/completed.png";
import create from "../../asset/images/create.png";
import proposal from "../../asset/icons/proposal-maker.svg";
import {} from "@material-ui/core";
import ProjectTable from "../../components/projectTable/ProjectTable";
import NewProject from "../../components/newProject/NewProject";
import Page from "../Page";
import { useSelector } from "react-redux";
import UpdateProject from "../../components/newProject/UpdateProject";
import { useHistory } from "react-router-dom";

// component for card
function Card(props) {
  return (
    <div
      className="card"
      style={{
        cursor: `${props.cursor}`,
        opacity: props.comingSoon ? "0.5" : "1",
      }}
      onClick={props.onClick}
    >
      <div
        style={{
          backgroundColor: `${props.background}`,
          border: `${props.border}`,
        }}
        className="img-ctr"
      >
        <img src={props.img} alt="active" />
      </div>
      <div className="content">
        {props.comingSoon && <small style={{ fontSize: "10px" }}>Beta</small>}
        <p>{props.title}</p>
        <h2>{props.data}</h2>
        <small>{props.subText}</small>
      </div>
    </div>
  );
}

function ProjectManagement() {
  const history = useHistory();

  // state
  const [newProject, setNewProject] = useState(false);
  const [updateProject, setUpdateProject] = useState(false);
  const [currentProject, setCurrentProject] = useState("");

  // selector
  const projects = useSelector((state) => state?.Profile?.profile?.projects);
  const clients = useSelector((state) => state?.Profile?.profile?.clientID);
  const { projectSummary } = useSelector((state) => state.Profile);

  // USE EFFECT
  useEffect(() => {
    const close = (e) => {
      if (e.key === "Escape") {
        setNewProject(false);
        setUpdateProject(false);
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  });
  return (
    <Page>
      <div className="container Project">
        <div className="center">
          <h1>Project Management</h1>
          <div className="card-container">
            <Card
              title="Active projects"
              background="#dfffee"
              img={active}
              data={projectSummary?.activeProjects}
            />
            <Card
              title="Completed"
              background="#ffede2"
              img={completed}
              data={projectSummary?.completedProjects}
            />
            <Card
              background="#f1ebff"
              border="2px dashed #8048f4"
              cursor="pointer"
              img={create}
              data="Create Project"
              onClick={() => setNewProject(true)}
            />
            <Card
              background="#f1ebff"
              border="2px dashed #333"
              cursor="pointer"
              img={proposal}
              data="Create Proposal"
              subText="Powered by AI"
              onClick={() => history.push("/proposal")}
            />
          </div>
          <div className="table-container">
            <ProjectTable
              data={projects}
              setCurrentProject={setCurrentProject}
              setUpdateProject={() => setUpdateProject(true)}
            />
          </div>
        </div>
        {newProject ? (
          <NewProject setNewProject={() => setNewProject(false)} />
        ) : null}
        {updateProject ? (
          <UpdateProject
            setNewProject={() => setUpdateProject(false)}
            currentProject={currentProject}
          />
        ) : null}
      </div>
    </Page>
  );
}

export default ProjectManagement;
