import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchProfileDetails } from "../../redux/profile/ProfileActions";
import { updateAgencyById } from "../../services/api.services";
import "./ResetPassword.scss";

function ResetPassword() {
  const [userdata, setUserdata] = useState({
    password: "",
    rePassword: "",
  });

  // dispatch
  const dispatch = useDispatch();

  // selector
  const profile = useSelector((state) => state.Profile.profile);

  const updateProfile = async () => {
    if (!userdata.password.trim() || !userdata.rePassword.trim()) {
      return dispatch({
        type: "ALERT-ON",
        payload: {
          severity: "error",
          msg: "Enter required data.",
        },
      });
    }
    if (userdata.password !== userdata.rePassword) {
      return dispatch({
        type: "ALERT-ON",
        payload: {
          severity: "error",
          msg: "Password Mismatched.",
        },
      });
    }
    try {
      const formData = {
        _id: profile._id,
        updatedData: { password: userdata.password },
      };
      const { data } = await updateAgencyById(formData);
      if (data?.status) {
        dispatch({
          type: "ALERT-ON",
          payload: {
            severity: "success",
            msg: data?.message,
          },
        });
        dispatch(fetchProfileDetails(profile._id));
      }
    } catch (e) {
      dispatch({
        type: "ALERT-ON",
        payload: {
          severity: "error",
          msg: "Something went wrong, try again.",
        },
      });
    }
  };

  return (
    <div className="resetPassword-container">
      <h2>Password</h2>
      <p>Change your account's password</p>
      <div className="form">
        <input
          type="text"
          placeholder="Type Password"
          onChange={(e) =>
            setUserdata({
              ...userdata,
              password: e.target.value,
            })
          }
        />
        <input
          type="text"
          placeholder="Re Type Password"
          onChange={(e) =>
            setUserdata({
              ...userdata,
              rePassword: e.target.value,
            })
          }
        />
        <button className="save" onClick={updateProfile}>
          CHANGE PASSWORD
        </button>
      </div>
    </div>
  );
}

export default ResetPassword;
