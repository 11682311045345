import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { getAllPlans, initPurchase, razorpayCreateOrder, razorpayCallBack, applyCouponCode, redeemCouponCode, stripePay } from "../../services/api.services";
import "./Plans.scss";
import logo from "../../asset/logo/logo.svg";

function Plans() {
  // SELECTROS
  const { plans } = useSelector((state) => state.Util);
  const { selectedplan, decode } = useSelector((state) => state.Profile);

  // DISPATCH
  const dispatch = useDispatch();

  // HISTORY
  const history = useHistory();


  // STATES
  const [selectedPlan, setSelectedPlan] = useState(0);
  const [couponCodeValue, setCouponCodeValue] = useState(null);
  const [couponError, setCouponError] = useState(false);
  const [couponMessage, setCouponMessage] = useState(null);

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  // ORDER INITIATION
  const planPurchase = async () => {
    try {
      const planData = {
        transactionType: "PACKAGE",
        amount: {
          grossAmount: selectedplan?.price,
          discountAmount: 0.0,
          taxAmount:
            selectedplan?.taxes?.gst * 0.01 * selectedplan?.price +
            selectedplan?.taxes?.sgst * 0.01 * selectedplan?.price,
          netAmount:
            selectedplan?.price +
            selectedplan?.taxes?.gst * 0.01 * selectedplan?.price +
            selectedplan?.taxes?.sgst * 0.01 * selectedplan?.price,
        },
        mode: "online",
        planID: selectedplan?._id,
        agencyID: decode?._id,
      };
      const { data, ...rest } = await initPurchase(planData);
      if (selectedplan?.price === 0) {
        if (!couponError && couponCodeValue) {
          await redeemCouponCode({ couponCode: couponCodeValue });
        }
        return history.push("dashboard");
      }
      if (data?.success) {
        const orderId = data?.orderID;
        if (process.env.REACT_APP_DEFAULT_PG == "razorpay") {
          // create rzp order id
          const { data } = await razorpayCreateOrder(planData?.amount?.netAmount, orderId);

          const options = {
            key: process.env.REACT_APP_RZP_KEY_ID,
            currency: "INR",
            amount: planData?.amount?.netAmount,
            name: "SynchroWork",
            description: "Online Purchase",
            image: logo,
            order_id: data.id,
            handler: async function (response) {
              const { data } = await razorpayCallBack(
                planData?.agencyID,
                orderId,
                planData?.planID,
                "agency",
                "PACKAGE_PURCHASE",
                false,
                response.razorpay_order_id,
                response.razorpay_payment_id,
                response.razorpay_signature);

              if (data.success) {
                dispatch({ type: "REMOVE-ALL" });
                window.location.href = `/dashboard?orderId=${orderId}&status=${data.message}`
              }
              // alert(response.razorpay_payment_id);
              // alert(response.razorpay_order_id);
              // alert(response.razorpay_signature);
            },
            prefill: {
              "name": decode?.name,
              "email": decode?.email,
              "contact": decode?.phone
            },
            notes: {
              "address": `SLV 1177, Silicon Town, Bengaluru, KA-560099`
            },
            theme: {
              "color": "#8048f4"
            }
          };

          const paymentObject = new window.Razorpay(options);

          paymentObject.open();
        } else if (process.env.REACT_APP_DEFAULT_PG == "paytm") {
          // CREATING A HIDDEN FORM FOR PAYMENT
          const cont = document.querySelector("#FormContainer");
          var element = `<form className="" action="https://api.synchrowork.com/v1/pay/paytm/paynow" method="post" id="payform">
            <div className="form-group">
              <label for="">Name: </label>
              <input className="form-control" type="text" name="customerID" value=${planData.agencyID}>
            </div>
            <div className="form-group">
              <label for="">Email: </label>
              <input className="form-control" type="text" name="email" value=${decode?.email}>
            </div>
            <div className="form-group">
              <label for="">Phone: </label>
              <input className="form-control" type="text" name="phone" value=${decode?.phone}>
            </div>
            <div className="form-group">
              <label for="">Amount: </label>
              <input className="form-control" type="text" name="amount" value=${planData.amount.netAmount}>
              <input className="form-control" type="text" name="customerType" value="agency">

            </div>
            <div className="form-group">
              <!-- <label for="">Amount: </label> -->
              <input className="form-control" type="hidden" name="planID" value=${planData?.planID}>
              <input className="form-control" type="hidden" name="orderID" value=${orderId}>
               <input className="form-control" type="hidden" name="paymentAgenda" value="PACKAGE_PURCHASE">
              <input className="form-control" type="hidden" name="isYearlyPayment" value="false">
            </div>
            <div className="form-group">
              <button className="btn form-control btn-primary">Pay Now</button>
            </div>
          </form>`;
          var z = document.createElement("div");
          z.innerHTML = element;
          cont.appendChild(z);
          // var x = document.getElementById("payform");
          document.getElementById("payform").submit();
        } else if (process.env.REACT_APP_DEFAULT_PG == "stripe") {
          const { data } = await stripePay(planData?.amount?.netAmount, orderId, { customer_email: decode?.email, agency: decode?._id, planId: selectedplan?._id, planName: selectedplan?.name });

          window.location.href = data.url;
        } else {
          alert("No Payment Gateway Selected!");
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const goProceed = () => {
    planPurchase();
  };

  const currentPlan = plans[0];

  // COUPON CODE
  const triggerCouponCode = async (coupon) => {
    try {
      const { data } = await applyCouponCode(coupon);
      if (data.success) {
        setCouponError(false);
        setCouponMessage(`${coupon} successfully applied! 🎉`);
        dispatch({
          type: "SELECT-PLAN",
          payload: data?.result?.associatedPlan[0],
        });
        setSelectedPlan(null);
      } else {
        setCouponError(true);
        if (selectedPlan === null) {
          dispatch({
            type: "SELECT-PLAN",
            payload: currentPlan,
          });
          setSelectedPlan(0);
        }

        setCouponMessage(`Coupon not found or expired!`);
      }
    } catch (e) {
      console.log("error", e.message);
    }
  }

  // USE EFFECT
  useEffect(() => {
    dispatch({
      type: "SELECT-PLAN",
      payload: plans[0],
    });
    if (process.env.REACT_APP_DEFAULT_PG == 'razorpay') {
      loadScript("https://checkout.razorpay.com/v1/checkout.js");
    }
  }, [currentPlan]);

  return (
    <div className="Plans-Container">
      <img src={logo} alt="logo" />

      <div className="Plan-Box">
        <div className="header">
          <h2>Choose a plan</h2>
          {/* <p style={{ marginBottom: "1rem" }}>
            Know why we are different? <Link to="/">Read more</Link>
          </p> */}
        </div>

        <div className="plan-box">
          <div className="plan-selection">
            {plans.map((item, index) => (
              <div
                className="plan"
                style={{
                  backgroundColor: selectedPlan === index ? "#F1EBFF" : "#f2f2f3",
                  borderColor: selectedPlan === index ? "#8048F4" : "#d5d5d5",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setSelectedPlan(index);
                  dispatch({
                    type: "SELECT-PLAN",
                    payload: item,
                  });
                }}
              >
                <div className="left">
                  <h4>{item?.name} Plan</h4>
                  <small>{item?.details?.description}</small>
                </div>
                <div className="right">
                  <h4>{index === 0 ? "FREE" : "₹" + item?.price + "/mo"}</h4>
                </div>
              </div>
            ))}
          </div>
          <div className="plan-checkout">
            <h4>Order Summary</h4>
            <table className="order-summary">
              <tr>
                <td colSpan={2}><strong>Selected Plan</strong></td>
                <td className="left-space">{selectedplan?.name}</td>
              </tr>
              <tr>
                <td colSpan={2}><strong>Total</strong></td>
                <td className="left-space">₹{selectedplan?.price}</td>
              </tr>
              <tr>
                <td colSpan={2}><strong>Tax</strong></td>
                <td className="left-space">₹0</td>
              </tr>
              <tr>
                <td colSpan={2}><strong>Grand Total</strong></td>
                <td className="left-space">₹{selectedplan?.price}/-</td>
              </tr>
            </table>
            <div className="coupon-code">
              <h5>Have a coupon code?</h5>
              <div className="input-group">
                <input type="text" placeholder="eg: SYN250" onChange={(e) => setCouponCodeValue(e.target.value)} />
                <button className="apply-discount-btn" onClick={() => triggerCouponCode(couponCodeValue)}>Apply</button>
              </div>
              <small id="coupon-message" style={!couponError ? { color: 'green' } : { color: 'red' }}>
                {couponMessage}
              </small>
            </div>
            <div className="button-ctr">
              <button className={`btn2 proceed ${!selectedplan ? 'disabled' : ''}`} onClick={goProceed}>
                Proceed
              </button>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
}

export default Plans;
