import React from "react";
import Icon from "../../asset/icons/icon.png";
import { Helmet } from "react-helmet";

function HelmetComponent({ title }) {
  return (
    <Helmet>
      <title>{title}</title>
      <link rel="icon" type="image/png" href={Icon} sizes="16x16" />
    </Helmet>
  );
}

export default HelmetComponent;
