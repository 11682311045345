/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import ClientTable from "../../components/clientTable/ClientTable";
import "./ClientManagement.scss";
import Page from "../Page";
import SearchClient from "../../components/searchClient/SearchClient";
import { useDispatch, useSelector } from "react-redux";
import { fetchProfileDetails } from "../../redux/profile/ProfileActions";
import PackageUpgrade from "../../components/packageUpgrade/PackageUpgrade";


function ClientManagement() {
  // state
  const [addNew, setAddNew] = React.useState(false);
  const [packageUpgrade, setPackageUpgrade] = React.useState(false);

  // selector
  const id = useSelector((state) => state?.Profile?.decode?._id);
  const clients = useSelector((state) => state?.Profile?.profile?.clientID);
  // const projects = useSelector((state) => state?.Profile?.profile?.projects);
  const planID = useSelector(
    (state) => state?.Profile?.profile?.planDetails?.planID
  );
  // dispatch
  const dispatch = useDispatch();

  // fetch all details
  const fetchDetails = () => {
    dispatch(fetchProfileDetails(id));
  };

  return (
    <>
      <Page>
        <div className="container Client-Management">
          <div className="client-heading">
            <h1>Client Management</h1>
            <button
              onClick={() =>
                clients?.length >= planID?.limitations?.clients
                  ? setPackageUpgrade(true)
                  : setAddNew(true)
              }
            >
              <i class="fa fa-plus" aria-hidden="true"></i>Add New Client
            </button>
          </div>
          <div className="table-container">
            <ClientTable fetch={fetchDetails} data={clients} />
          </div>
        </div>
        {packageUpgrade && (
          <PackageUpgrade
            message={`You've reached your package limit of ${planID?.limitations?.clients} clients`}
            CTA={{ CTAText: "Upgrade Package", link: "/settings" }}
            closeModal={() => setPackageUpgrade(false)}
          />
        )}
        {addNew && (
          <SearchClient
            fetch={fetchDetails}
            closeModal={() => setAddNew(false)}
          />
        )}
      </Page>

    </>
  );
}

export default ClientManagement;
