import axios from "axios";

// BASE URL - LOCAL & CLOUD
// const baseURL = "https://api.synchrowork.com/v1";
const baseURL = process.env.REACT_APP_API_HOST;

// URL function returning the url
function URL(url) {
  return `${baseURL}/${url}`;
}

export const callBackend = async (method, url, data = {}, additionalHeader) => {
  let authToken = localStorage.getItem("SYNCHRO-TOKEN");

  switch (method) {
    case "GET": {
      let config = {
        method: "get",
        url: URL(url),
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };
      return await axios(config);
    }
    case "POST": {
      let config = {
        method: "post",
        url: URL(url),
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
          ...additionalHeader,
        },
        data,
      };

      return await axios(config);
    }
    case "DELETE": {
      let config = {
        method: "delete",
        url: URL(url),
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        },
        data,
      };

      return await axios(config);
    }
    case "PUT": {
      let config = {
        method: "put",
        url: URL(url),
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        },
        data,
      };

      return await axios(config);
    }
    default:
      break;
  }
};
