import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Checkbox from "@material-ui/core/Checkbox";
import { useDispatch, useSelector } from "react-redux";
import { capitalize } from "../../utilities/CapitalizeLetter";
import { Link } from "react-router-dom";
import {
  deleteInvoiceById,
  getAllInvoices,
  updateInvoiceById,
} from "../../services/api.services";
import { fetchInvoiceSummary } from "../../redux/profile/ProfileActions";
// import { fetchProfileDetails } from "../../redux/profile/ProfileActions";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
    padding: "8px 0",
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

export default function InvoiceTable() {
  const classes = useStyles();
  // STATES
  const [selected, setSelected] = React.useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [search, setSearch] = useState("");

  // selectors
  const invoices = useSelector((state) => state.Profile.invoices);
  const id = useSelector((state) => state?.Profile?.decode?._id);

  // DISPATCH
  const dispatch = useDispatch();

  // HISTORY

  const columns = [
    { field: "invoiceNumber", headerName: "Invoice #" },
    { field: "status", headerName: "Status" },
    { field: "billDue", headerName: "Due Date" },
    { field: "project", headerName: "Project" },
    { field: "client", headerName: "Client" },
    { field: "amount", headerName: "Amount" },
    // { field: "view", headerName: "View" },
  ];

  const refreshData = async () => {
    try {
      const form = {
        agency: id,
      };
      const resp = await getAllInvoices(form);
      if (resp?.data?.success) {
        dispatch({
          type: "GET_INVOICES",
          payload: resp?.data?.data,
        });
      }
    } catch (e) { }
  };

  const deleteInvoice = async () => {
    try {
      const { data } = await deleteInvoiceById(selected);

      if (data?.success) {
        dispatch({
          type: "ALERT-ON",
          payload: {
            severity: "success",
            msg: "Invoice successfully deleted.",
          },
        });
        refreshData();
        dispatch(fetchInvoiceSummary(id));
      }
    } catch (e) {
      dispatch({
        type: "ALERT-ON",
        payload: {
          severity: "error",
          msg: e.message,
        },
      });
    }
  };

  const updateInvoiceStatus = async () => {
    try {
      var newInvoiceData = {
        status: "Paid",
      };

      const response = await updateInvoiceById(selected, newInvoiceData);
      if (response?.data?.success) {
        dispatch({
          type: "ALERT-ON",
          payload: {
            severity: "success",
            msg: "Invoice Status Updated",
          },
        });
        refreshData();
      }
    } catch (e) {
      dispatch({
        type: "ALERT-ON",
        payload: {
          severity: "error",
          msg: e.message,
        },
      });
    }
  };

  const singleClick = (e, id) => {
    if (selected !== id) {
      setSelected(id);
    } else {
      setSelected("");
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // const isSelected = (id) => selected.indexOf(id) !== -1;

  // search in table
  const searchTable = (data) => {
    return data?.filter(
      (datas) =>
        datas?.project?.name
          ?.toString()
          ?.toLowerCase()
          ?.includes(search.toString().toLowerCase()) ||
        datas?.project?.client?.name
          ?.toString()
          ?.toLowerCase()
          ?.includes(search.toString().toLowerCase()) ||
        datas?.status
          ?.toString()
          ?.toLowerCase()
          ?.includes(search.toString().toLowerCase()) ||
        datas?.dueDate
          ?.toString()
          ?.toLowerCase()
          ?.includes(search.toString().toLowerCase())
    );
  };

  return (
    <div className={classes.root}>
      <div className="table-header">
        <h2>Invoices</h2>
        <div className="control">
          <button disabled={!selected} onClick={deleteInvoice}>
            <i class="fa fa-trash" aria-hidden="true"></i>
          </button>
          <button disabled={!selected} onClick={updateInvoiceStatus}>
            <i class="fa fa-check-circle" aria-hidden="true"></i> Mark as paid
          </button>
          <div class="form">
            <span>
              <i class="fa fa-search" aria-hidden="true"></i>
            </span>
            <input
              placeholder="Search"
              type="text"
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className={classes.paper}>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
          >
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox"> </TableCell>
                {columns.map((row, id) => (
                  <TableCell key={id} align={"left"} style={{ fontWeight: 'bold', padding: "5px 0" }}>
                    {row.headerName}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {invoices.length > 0 &&
                searchTable(invoices)
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = row?._id === selected;
                    const labelId = row._id;

                    return (
                      <TableRow
                        hover
                        // onClick={(event) => handleClick(event, row._id)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        selected={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isItemSelected}
                            inputProps={{ "aria-labelledby": labelId }}
                            onChange={(event) => singleClick(event, row._id)}
                          />
                        </TableCell>
                        <TableCell
                          component="th"
                          id={index}
                          scope="row"
                          padding="none"
                          align="left"
                        >
                          <Link
                            style={{ textDecoration: "none" }}
                            className="table-button"
                            to={`/invoice/${row?._id}`}
                          >
                            {row?.invoiceNumber}
                          </Link>
                        </TableCell>
                        <TableCell align="left" style={{ fontSize: "13px" }}>
                          <strong className={`uppercase rounded-sm p-2 ${row?.status?.toLowerCase() === 'paid' ? 'bg-[#D5F5E3]' : 'bg-[#FCF3CF]'}`}>{row.status}</strong></TableCell>
                        <TableCell align="left">
                          {new Date(row.dueDate).toDateString()}
                        </TableCell>
                        <TableCell align="left">
                          {row?.project?.name || '-'}
                        </TableCell>
                        <TableCell align="left">
                          {row?.receiverDetails?.receiverName}
                        </TableCell>

                        <TableCell align="left">
                          <strong>{row?.currency} {row.grandTotal.toLocaleString("en-US")}</strong>
                        </TableCell>
                      </TableRow>
                    );
                  })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={searchTable(invoices)?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </div>
    </div>
  );
}
