import {
  addResource,
  createInvoice,
  createProject,
  currentUserDetails,
  deleteProject,
  getInvoiceSummary,
  getProjectSummary,
  getSalesReportGraphData,
  updateProjectById,
  getTopClients,
  getTodoItems,
  createTodoItem,
  modifyTodoItem,
  updateAgencyById
} from "../../services/api.services";

// ACTIONS
export const fetchProfileDetails = (id) => async (dispatch, getState) => {
  try {
    const { data } = await currentUserDetails(id);
    if (data?.success) {
      dispatch({
        type: "GET-DETAILS",
        payload: data?.data,
      });
      dispatch({
        type: "SET_SELECTED_CURRENCY",
        payload: data?.data?.selectedCurrency
      });
    } else {
      dispatch({
        type: "ALERT-ON",
        payload: {
          severity: "error",
          msg: "Something went wrong !",
        },
      });
    }
  } catch (e) { }
};

export const fetchInvoiceSummary = (id, currency) => async (dispatch) => {
  try {
    const { data } = await getInvoiceSummary(id, currency);
    if (data?.success) {
      dispatch({
        type: "GET_INVOICE_SUMMARY",
        payload: data?.result,
      });
    }
  } catch (e) { }
};

export const fetchProjectSummary = (id, currency) => async (dispatch) => {
  try {
    const { data } = await getProjectSummary(id, currency);
    if (data?.success) {
      dispatch({
        type: "GET_PROJECT_SUMMARY",
        payload: data?.result,
      });
    }
  } catch (e) { }
};

export const fetchSalesGraphData = (requestedDuration, id, currency) => async (dispatch) => {
  try {
    const { data } = await getSalesReportGraphData(requestedDuration, id, currency);
    if (data?.success) {
      dispatch({
        type: "GET_SALESGRAPH_DATA",
        payload: data,
      });
    }
  } catch (e) { }
};

export const addResouurceToAgency =
  (id, form) => async (dispatch, getState) => {
    try {
      const { data } = await addResource(form);
      if (data?.success) {
        dispatch({
          type: "ALERT-ON",
          payload: {
            severity: "success",
            msg: data?.message,
          },
        });
        const resp = await currentUserDetails(id);
        if (resp?.data?.success) {
          dispatch({
            type: "GET-DETAILS",
            payload: resp?.data?.data,
          });
        }
      } else {
        dispatch({
          type: "ALERT-ON",
          payload: {
            severity: "error",
            msg: data?.message,
          },
        });
      }
    } catch (e) { }
  };

export const addProjectToAgency = (id, form) => async (dispatch) => {
  try {
    const { data } = await createProject(form);
    if (data?.success) {
      dispatch({
        type: "ALERT-ON",
        payload: {
          severity: "success",
          msg: "Project Created",
        },
      });
      const resp = await currentUserDetails(id);
      if (resp?.data?.success) {
        dispatch({
          type: "GET-DETAILS",
          payload: resp?.data?.data,
        });
      }
      return true;
    } else {
      dispatch({
        type: "ALERT-ON",
        payload: {
          severity: "error",
          msg: data?.message,
        },
      });
      return false;
    }
  } catch (e) {
    console.log(e);
  }
};

export const deleteProjectFromAgency = (id, form) => async (dispatch) => {
  try {
    const { data } = await deleteProject(form);
    if (data?.success) {
      dispatch({
        type: "ALERT-ON",
        payload: {
          severity: "success",
          msg: "Project Deleted",
        },
      });
      const resp = await currentUserDetails(id);
      if (resp?.data?.success) {
        dispatch({
          type: "GET-DETAILS",
          payload: resp?.data?.data,
        });
      }
    } else {
      dispatch({
        type: "ALERT-ON",
        payload: {
          severity: "error",
          msg: "Something went wrong !",
        },
      });
    }
  } catch (e) { }
};

export const addInvoiceToProject = (id, form) => async (dispatch) => {
  try {
    const { data } = await createInvoice(form);
    if (data?.success) {
      dispatch({
        type: "ALERT-ON",
        payload: {
          severity: "success",
          msg: "Invoice Created",
        },
      });
      return {
        status: true,
        ids: data?.data?._id,
      };
    } else {
      dispatch({
        type: "ALERT-ON",
        payload: {
          severity: "error",
          msg: data?.message,
        },
      });
      return {
        status: false,
        ids: null
      };
    }
  } catch (e) {
    dispatch({
      type: "ALERT-ON",
      payload: {
        severity: "error",
        msg: e?.message,
      },
    });
  }
};

export const modifyProject = (id, form) => async (dispatch) => {
  try {
    const { data } = await updateProjectById(form);
    if (data?.success) {
      dispatch({
        type: "ALERT-ON",
        payload: {
          severity: "success",
          msg: data?.message,
        },
      });
      const resp = await currentUserDetails(id);
      if (resp?.data?.success) {
        dispatch({
          type: "GET-DETAILS",
          payload: resp?.data?.data,
        });
      }
    } else {
      dispatch({
        type: "ALERT-ON",
        payload: {
          severity: "error",
          msg: data?.message,
        },
      });
    }
  } catch (e) { }
};

export const fetchTopClients = (id = null) => async (dispatch) => {
  try {
    const { data } = await getTopClients();
    if (data?.success) {
      dispatch({
        type: "GET_TOP_CLIENTS",
        payload: data?.data
      });
    } else {
      dispatch({
        type: "ALERT-ON",
        payload: {
          severity: "error",
          msg: data?.message,
        },
      });
    }
  } catch (e) {

  }
}

// todo items actions
export const fetchToDoItems = (id = null) => async (dispatch) => {
  try {
    const { data } = await getTodoItems();
    if (data) {
      dispatch({
        type: "GET_TODO_ITEMS",
        payload: data.data
      });
    }
  } catch (e) { }
}

export const addNewToDoItem = (data) => async (dispatch) => {
  try {
    const res = await createTodoItem(data);
    if (res) {
      dispatch({
        type: "ADD_TODO_ITEMS",
        payload: { ...data, _id: res.data.data }
      });

      dispatch({
        type: "ALERT-ON",
        payload: {
          severity: "success",
          msg: "Created new to-do item",
        },
      });
    }
  } catch (e) {
    dispatch({
      type: "ALERT-ON",
      payload: {
        severity: "error",
        msg: e?.message,
      },
    });
  }
}

export const modifyToDoItem = (data) => async (dispatch) => {
  try {
    const res = await modifyTodoItem(data);

    if (res) {

      dispatch({
        type: "REMOVE_TODO_ITEM",
        payload: data._id
      });

      dispatch({
        type: "ALERT-ON",
        payload: {
          severity: "success",
          msg: "Marked Complete!",
        },
      });
    }
  } catch (e) {
    dispatch({
      type: "ALERT-ON",
      payload: {
        severity: "error",
        msg: e?.message,
      },
    });
  }
}

// set currency
export const updateSelectedCurrency = (currency) => async (dispatch) => {
  try {
    const { data } = await updateAgencyById({ updatedData: { selectedCurrency: currency } });
    if (data) {
      dispatch({
        type: "SET_SELECTED_CURRENCY",
        payload: currency
      });
      dispatch({
        type: "ALERT-ON",
        payload: {
          severity: "success",
          msg: "Currency updated!",
        },
      });

      return true;
    } else {
      dispatch({
        type: "ALERT-ON",
        payload: {
          severity: "error",
          msg: "Unable to update currency!",
        },
      });
      return false;
    }
  } catch (e) {
    dispatch({
      type: "ALERT-ON",
      payload: {
        severity: "error",
        msg: e?.message,
      },
    });
  }

}