import { combineReducers } from "redux";
import { ProfileReducer } from "./profile/ProfileReducer";
// import ProfileReducer from "./profile/ProfileReducer";
import UtilityReducer from "./utility/UtilityReducer";

const RootReducer = combineReducers({
  Profile: ProfileReducer,
  Util: UtilityReducer,
});

export default RootReducer;
