import React, { useEffect, useState } from "react";
import Page from "../Page";
import "./ProjectProposal.scss";
import { useDispatch, useSelector } from "react-redux";
import { generateProposal, getProposalLimit, redeemProposalToken } from "../../services/api.services";
import Showdown from "showdown";
import TextEditor from "./TextEditor";

function ProjectProposal() {
  const [project, setProject] = useState({
    name: "",
    description: "",
    budget: ""
  });
  const [clientName, setClientName] = useState("");

  const [result, setResult] = useState(null);
  const [resultLoading, setResultLoading] = useState(false);
  const [html, setHTML] = useState(null);
  const [proposalLimit, setProposalLimit] = useState(null);

  const dispatch = useDispatch();
  const clients = useSelector((state) => state?.Profile?.profile?.clientID);
  const agencyName = useSelector((state) => state?.Profile?.profile?.name);

  const handleChange = (e) => {
    setProject({ ...project, [e.target.name]: e.target.value });
  };

  const getProposal = async () => {
    try {
      setResultLoading(true);
      setResult(null);
      const body = {
        agencyName,
        project,
        clientName,
      };

      const { data } = await generateProposal(body);

      let converter = new Showdown.Converter({
        tables: true,
        tasklists: true,
        tablesHeaderId: true,
        metadata: true,
      });

      const html = converter.makeHtml(data?.data?.content);
      setHTML(html);
      setResultLoading(false);

      await redeemProposalToken();
      await getLimit();
    } catch (e) {
      setResultLoading(false);
      setResult(null);
      dispatch({
        type: "ALERT-ON",
        payload: {
          severity: "error",
          msg: e.message,
        },
      });
    }
  };

  const getLimit = async () => {
    try {
      const { data: { data: { availableLimit } } } = await getProposalLimit();
      setProposalLimit(availableLimit);
    } catch (e) {
      dispatch({
        type: "ALERT-ON",
        payload: {
          severity: "error",
          msg: e.message,
        },
      });
    }
  }

  useEffect(() => {
    getLimit();
  }, []);

  useEffect(() => {
    setResult(html);
  }, [html]);

  return (
    <Page>
      <div className="container Project Proposal">
        <div className="center flex flex-row items-center justify-center">
          <div className="heading">
            <h1 className="flex flex-row items-center justify-center">
              AI Proposal Generator
            </h1>
          </div>
          <div className="token-limit">
            <i className="fa fa-diamond"></i> {proposalLimit} DAILY TOKENS LEFT
          </div>
        </div>

        <div className="Proposal_Container">
          <div className="Proposal_Editor">
            <>
              <label>
                Project Name
                <input
                  type="text"
                  placeholder="eg: Jade & Co. Website"
                  name="name"
                  value={project.name}
                  onChange={handleChange}
                />
              </label>
              <div className="two-col">
                <label>
                  Client Name
                  <select
                    name="clientName"
                    value={clientName}
                    onChange={(e) => setClientName(e.target.value)}
                  >
                    <option value="" disabled selected>
                      Select Client
                    </option>
                    {clients?.map((client) => (
                      <option value={client?.name}>{client?.name}</option>
                    ))}
                  </select>
                </label>

                <label>
                  Budget
                  <input
                    type="text"
                    placeholder="eg $100-$500"
                    name="budget"
                    value={project.budget}
                    onChange={handleChange}
                  />
                </label>
              </div>
              <label>
                Description
                <textarea
                  type="text"
                  placeholder="Describe the project in 100-500 words"
                  name="description"
                  value={project.description}
                  onChange={handleChange}
                />
              </label>
              <div className="Button_Container">
                <button className={!resultLoading ? 'generate' : 'disabled'} onClick={!resultLoading ?  ( proposalLimit > 0 ? getProposal : () => alert('Daily tokens exhausted!') ) : null}>
                  GENERATE PROPOSAL
                </button>
              </div>

            </>
          </div>
          <div className="Proposal_Result">
            {resultLoading &&
              <div className="ai-loader">
                <lottie-player src="https://assets1.lottiefiles.com/temp/lf20_csjVxr.json" background="transparent" speed="1" style={{ width: "150px", height: "150px" }} loop autoplay>
                </lottie-player>
                <span>Generating proposal...</span></div>}
            {result ? <TextEditor content={result} /> : !resultLoading && <p style={{ color: "#ccc" }}>Waiting for input...</p>}
          </div>
        </div>
      </div>
    </Page>
  );
}

export default ProjectProposal;
