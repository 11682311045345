/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ResetPassword from "../../components/resetPassword/ResetPassword";
// import { useDispatch, useSelector } from "react-redux";
import UpdatePlan from "../../components/updatePlan/UpdatePlan";
import { fetchProfileDetails } from "../../redux/profile/ProfileActions";
import { updateAgencyById } from "../../services/api.services";
import { uploadImage } from "../../services/file.service";
import Page from "../Page";
import "./Setting.scss";

function Settings() {
  // dispatch
  const dispatch = useDispatch();

  // state
  // const [visible, setVisible] = useState(false);
  const [userdata, setUserdata] = useState({
    name: "",
    address: "",
    pan: "",
    logo: "",
  });
  const [selectedFile, setSelectedFile] = useState();
  const [newTaxItem, setNewTaxItem] = useState(false);

  // selector
  const profile = useSelector((state) => state.Profile.profile);
  const allPlans = useSelector((state) => state?.Util?.plans);

  const [taxRule, setTaxRule] = useState(profile?.defaultTaxRule || null);
  const [paymentID, setPaymentID] = useState(profile?.paymentID || null);

  const [taxInput, setTaxInput] = useState({
    title: "",
    percentage: null
  })

  const setDataFromRedux = () => {
    setUserdata({
      ...userdata,
      name: profile?.name,
      address: profile?.address,
      pan: profile?.pan,
      logo: profile?.logoURL,
    });
  };

  const setCurrentPlanId = useSelector(
    (state) => state?.Profile?.profile?.planDetails?.planID?._id
  );

  const updateProfile = async (updatedData) => {
    try {
      if (selectedFile) {
        const resp = await uploadImage(selectedFile);
        const logoURL = `${process.env.REACT_APP_API_HOST}${resp?.data?.imagePath}`;
        const formData = {
          _id: profile._id,
          updatedData: { ...userdata, logoURL },
        };
        const { data } = await updateAgencyById(formData);
        if (data?.status) {
          dispatch({
            type: "ALERT-ON",
            payload: {
              severity: "success",
              msg: data?.message,
            },
          });
          dispatch(fetchProfileDetails(profile._id));
        }
      } else {
        const formData = {
          _id: profile._id,
          updatedData
        };
        const { data } = await updateAgencyById(formData);
        if (data?.status) {
          dispatch({
            type: "ALERT-ON",
            payload: {
              severity: "success",
              msg: data?.message,
            },
          });
          dispatch(fetchProfileDetails(profile._id));
        }
      }
    } catch (e) {
      dispatch({
        type: "ALERT-ON",
        payload: {
          severity: "error",
          msg: "Something went wrong, try again.",
        },
      });
    }
  };

  // use effect
  useEffect(() => {
    setDataFromRedux();
    dispatch({
      type: "SELECT-PLAN",
      payload: allPlans.filter((item) => item?._id === setCurrentPlanId)[0],
    });
  }, [profile, setCurrentPlanId]);

 
  // LOGOUT
  // history
  const history = useHistory();
  const logoutHandler = () => {
    dispatch({
      type: "LOG-OUT",
    });
    history.push("/");
  };

  return (
    <Page>
      <div className="container setting">
        <div className="Header">
          <h1>Settings</h1>
          <button onClick={logoutHandler}>LOG OUT</button>
        </div>
        <div className="settings-top">
        <div className="form-container" id="business-profile">
            <h3>Business Profile</h3>
            <div className="form">
              <div className="form-data">
                <label>Business Name</label>
                <input
                  placeholder="Name"
                  value={userdata?.name}
                  onChange={(e) =>
                    setUserdata({
                      ...userdata,
                      name: e.target.value,
                    })
                  }
                ></input>
              </div>
              <div className="form-data">
                <label>PAN Number</label>
                <input
                  placeholder="ABCDE1234F"
                  value={userdata?.pan}
                  onChange={(e) =>
                    setUserdata({
                      ...userdata,
                      pan: e.target.value,
                    })
                  }
                ></input>
              </div>

              <div className="form-data">
                <label>Address</label>
                <textarea
                  placeholder="Detailed Address"
                  value={userdata?.address}
                  onChange={(e) =>
                    setUserdata({
                      ...userdata,
                      address: e.target.value,
                    })
                  }
                ></textarea>
              </div>
              <div className="form-data">
                <label>Logo</label>
                <div className="inline-display">
                  <input
                    onChange={(e) => {
                      setSelectedFile(e.target.files[0]);
                    }}
                    type="file"
                    style={{ marginBottom: 0 }}
                  />

                  <img
                    src={
                      selectedFile
                        ? URL.createObjectURL(selectedFile)
                        : userdata?.logo
                    }
                    style={{ width: "50px", marginLeft: 10 }}
                    alt="logo"
                  />
                </div>
              </div>

            </div>
            <div className="bottom">
              <button className="save" onClick={() => updateProfile(userdata)}>
                SAVE
              </button>
            </div>
          </div>
        <div className="form-container" id="payments">
            <div className="Header">
              <h3>Direct Payments</h3>
            </div>

            <div className="list-item form">
              <div className="form-data" style={{width: '95%'}}>
                <label>UPI Address</label>
                <input
                        placeholder="Eg: xxx@upi, xxx@okaxis, etc."
                        value={paymentID}
                        onChange={(e) =>
                          setPaymentID(e.target.value)
                        }
                      />
              </div>

              <small>&#9432; Clients will be able to pay directly to your UPI address</small>
            </div>

            <div className="bottom">
              <button id="save-tax" className="save bordered" onClick={()=>updateProfile({paymentID: paymentID})}>
                SAVE
              </button>
            </div>
          </div>

          <div className="form-container" id="tax-rules">
            <div className="Header">
              <div>
                <h3>Tax Rule</h3>
              </div>
              <div className="popup-container">
                {newTaxItem ? (
                  <button
                    className="openCloseButton"
                    onClick={() => {
                      setNewTaxItem(false);
                    }}
                  >
                    <i class="fa fa-times" aria-hidden="true"></i>
                  </button>
                ) : (
                  <button
                    className="openCloseButton"
                    onClick={() => {
                      setNewTaxItem(true);
                    }}
                  >
                    <i class="fa fa-plus" aria-hidden="true"></i>Add
                  </button>
                )}

                {newTaxItem ? (
                  <div className="add-more">
                    <div className="input-box">
                      <p>Title</p>
                      <input
                        placeholder="Eg: CGST, SGST, GST, etc."
                        onChange={(e) =>
                          setTaxInput({
                            ...taxInput,
                            title: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="input-box">
                      <p>Percentage (%)</p>
                      <input
                        placeholder="Eg: 18, 21, 9, etc."
                        onChange={(e) =>
                          setTaxInput({
                            ...taxInput,
                            percentage: e.target.value,
                          })
                        }
                      />
                    </div>
                    <button
                      className="submit"
                      onClick={() => {
                        updateProfile(profile?.taxRule ? {taxRule: [...profile.taxRule, taxInput]} : {taxRule: taxInput});
                        setNewTaxItem(false);
                      }}
                    >
                      ADD
                    </button>
                  </div>
                ) : null}
              </div>
            </div>

            <div className="list-item form">
              {/* <DeletableItems itemList={profile && profile?.taxRule}/> */}
              <div className="form-data" style={{width: '95%'}}>
                <label>Default Tax</label>
                <select onChange={(e)=>setTaxRule(e.target.value)}>
                  {
                    profile && !profile?.taxRule?.length && <option selected disabled>No tax rule added</option>
                  }
                  {
                    profile && profile?.taxRule?.map((d, i) => (
                      <option value={d._id} selected={i ===0 || d._id === profile?.defaultTaxRule}>
                        {d.title} - {d.percentage}% 
                      </option>
                    ))
                  }
                </select>
              </div>

              <small>&#9432; This information will be used in invoice creation page.</small>
            </div>

            <div className="bottom">
              <button id="save-tax" className="save bordered" onClick={()=>updateProfile({defaultTaxRule: taxRule})}>
                SAVE
              </button>
            </div>
          </div>
        
        </div>
        <UpdatePlan />

        <ResetPassword />
      </div>
    </Page>

  );
}

export default Settings;
