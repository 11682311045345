import axios from "axios";

var FormData = require("form-data");
// var fs = require("fs");

// IMAGE UPLOAD
export const uploadImage = async (file) => {
  let authToken = localStorage.getItem("SYNCHRO-TOKEN");

  var data = new FormData();
  data.append("image", file);

  var config = {
    method: "post",
    url: `${process.env.REACT_APP_API_HOST}/files/image/upload`,
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
    data: data,
  };

  return await axios(config);
};
