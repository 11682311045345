import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";
// import jsPDF from "jspdf";
// import html2canvas from "html2canvas";
// import axios from "axios";
import "../invoicePage/InvoicePage.scss";
import send from "../../asset/images/send.png";
import receive from "../../asset/images/receive.png";
import InvoiceSender from "../../components/invoiceDetails/InvoiceSender";
import InvoiceReceiver from "../../components/invoiceDetails/InvoiceReceiver";
import { Snackbar } from "@material-ui/core";
import logoFile from "../../asset/images/logo-small.png";
import loader from "../../asset/loaders/loader.svg";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { currencyJSON } from "../../asset/json/currency";
import { fetchProfileDetails } from "../../redux/profile/ProfileActions";
import { getInvoiceById, updateInvoiceById } from "../../services/api.services";

function EditInvoice(props) {
  // DISPATCH
  const dispatch = useDispatch();

  // SELECTORS
  const id = useSelector((state) => state?.Profile?.decode?._id);
  const projects = useSelector((state) => state?.Profile?.profile?.projects);
  const profile = useSelector((state) => state.Profile.profile);

  // image conversion
  const getBase64FromUrl = async (url) => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      };
    });
  };

  // initial state & data loading
  const [invoiceData, setInvoiceData] = React.useState({
    data: {},
    loading: true,
  });

  const [image, setImage] = useState("");

  const loadData = async () => {
    try {
      const data = await getInvoiceById(props.match.params.id);
      if (data.data.success === true) {
        const DATA = data.data.data;
        setInvoiceData({ data: DATA, loading: false });
        setData(DATA.itemDetails);
        setStatus(DATA?.status);
        setInvoiceNote(DATA.invoiceNote);
        setDates({
          invoice: new Date(DATA.creationDate).toISOString().slice(0, 10),
          due: new Date(DATA.dueDate).toISOString().slice(0, 10),
        });
        setSelectedCurrency(DATA?.currency);
        setSelectedProject(DATA?.project);
        setSenderDetails({
          senderName: DATA.senderDetails.senderName,
          email: DATA.senderDetails.email,
          phone: DATA.senderDetails.phone,
          taxRegistration: DATA.senderDetails.taxRegistration,
          website: DATA.senderDetails.website,
          address: DATA.senderDetails.address,
        });
        setReceiverDetails({
          receiverName: DATA.receiverDetails.receiverName,
          organisation: DATA.receiverDetails.organisation,
          country: DATA.receiverDetails.country,
          email: DATA.receiverDetails.email,
          phone: DATA.receiverDetails.phone,
          address: DATA.receiverDetails.address,
        });
        getBase64FromUrl(DATA?.invoiceLogo)
          .then((res) => setImage(res))
          .catch((e) => console.log(e));
      }
    } catch (err) {
      setInvoiceData({ data: err.message, loading: true });
    }
  };

  React.useEffect(() => {
    dispatch(fetchProfileDetails(id));
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  // states
  const history = useHistory();
  const [selectedProject, setSelectedProject] = useState("");
  const [selectedcurrency, setSelectedCurrency] = useState("");
  const [data, setData] = React.useState([]);
  const [openSender, setOpenSender] = React.useState(false);
  const [openReceiver, setOpenReceiver] = React.useState(false);
  const [billGenerated, setBillGenerated] = React.useState(true);
  const [invoiceNote, setInvoiceNote] = React.useState("");
  const [status, setStatus] = React.useState("");
  const [taxValue, setTaxValue] = React.useState(0);

  const [dates, setDates] = React.useState({
    invoice: "",
    due: "",
  });
  const [snack, setSnack] = React.useState(false);

  const [senderDetails, setSenderDetails] = useState({
    senderName: "",
    email: "",
    phone: "",
    taxRegistration: "",
    website: "",
    address: "",
  });

  const [receiverDetails, setReceiverDetails] = useState({
    receiverName: "",
    organisation: "",
    country: "",
    email: "",
    phone: "",
    address: "",
  });

  const handleSenderInput = (e) => {
    setSenderDetails({ ...senderDetails, [e.target.name]: e.target.value });
  };

  // const handleReceiverInput = (e) => {
  //   setReceiverDetails({ ...receiverDetails, [e.target.name]: e.target.value });
  // };
  const handleReceiverInput = (e) => {
    if (e.target.name === "receiverName") {
      const selectedClient = profile?.clientID?.filter(
        (item) => item._id === e.target.value
      )[0];
      return setReceiverDetails({
        ...receiverDetails,
        receiverName: selectedClient?.name,
        organisation: selectedClient?.organizationName,
        country: "",
        email: selectedClient?.email,
        phone: selectedClient?.phone,
        address: "",
      });
    }
    return setReceiverDetails({
      ...receiverDetails,
      [e.target.name]: e.target.value,
    });
  };

  const handleOpenSender = () => {
    setOpenSender(true);
  };
  const handleCloseSender = () => {
    setOpenSender(false);
  };

  const handleOpenReceiver = () => {
    setOpenReceiver(true);
  };
  const handleCloseReceiver = () => {
    setOpenReceiver(false);
  };

  const [addData, setAddData] = React.useState({
    id: uuidv4(),
    productDescription: "",
    qty: "",
    rate: "",
  });

  const [edit, setEdit] = React.useState(false);

  const filterArray = (id) => {
    const result = data.filter((word) => word.id !== id);
    setData(result);
  };
  const addDataTable = () => {
    setData([...data, addData]);
    setAddData({
      ...data,
      id: uuidv4(),
      productDescription: "",
      qty: "",
      rate: "",
    });
  };

  const updateDataTable = () => {
    const updateData = data.map((item) => {
      if (item.id === addData.id) {
        item.productDescription = addData.productDescription;
        item.qty = addData.qty;
        item.rate = addData.rate;
      }
      return item;
    });
    setData(updateData);
    setAddData({
      ...data,
      id: "",
      productDescription: "",
      qty: "",
      rate: "",
    });
    setEdit(false);
  };

  // const createPDF = () => {
  //   html2canvas(document.querySelector("#capture")).then((canvas) => {
  //     // document.body.appendChild(canvas);
  //     var contentWidth = 950;
  //     var contentHeight = canvas.height;

  //     //One page pdf shows the height of canvas generated by html page;
  //     var pageHeight = (contentWidth / 592.28) * 841.89;
  //     //html page height without pdf generation
  //     var leftHeight = contentHeight;
  //     //Page offset
  //     var position = 0;
  //     //a4 paper size [595.28841.89], width and height of image in pdf of canvas generated by html page
  //     var imgWidth = 595.28;
  //     var imgHeight = (592.28 / contentWidth) * contentHeight;

  //     //Return picture dataURL, parameters: picture format and sharpness (0-1)
  //     var pageData = canvas.toDataURL("image/jpeg", 1.0);

  //     //Direction is vertical by default, dimension ponits, format A4 [595.28841.89]
  //     var pdf = new jsPDF("", "pt", "a4");
  //     pdf.internal.scaleFactor = 50;

  //     //There are two heights to distinguish, one is the actual height of the html page, and the height of the generated pdf page (841.89)
  //     //When the content does not exceed the display range of one page of pdf, paging is not required
  //     if (leftHeight < pageHeight) {
  //       pdf.addImage(pageData, "JPEG", 0, 0, imgWidth, imgHeight);
  //     } else {
  //       while (leftHeight > 0) {
  //         pdf.addImage(pageData, "JPEG", 0, position, imgWidth, imgHeight);
  //         leftHeight -= pageHeight;
  //         position -= 841.89;
  //         //Avoid adding blank pages
  //         if (leftHeight > 0) {
  //           pdf.addPage();
  //         }
  //       }
  //     }
  //     pdf.output("dataurlnewwindow");
  //     // only enable when pdf download is required
  //     // pdf.save(Math.floor(Math.random() * 101));
  //   });
  // };

  // const exportPdf = () => {
  //   setBillGenerated(false);
  //   setTimeout(() => {
  //     createPDF();
  //   }, 10);
  // };

  const UpdateInvoice = async () => {
    var newInvoiceData = {
      projectID: uuidv4(),
      invoiceType: "monthly",
      language: "ENG(US)",
      currency: selectedcurrency,
      receipientId: uuidv4(),
      creationDate: dates.invoice,
      dueDate: dates.due,
      senderDetails: senderDetails,
      receiverDetails: receiverDetails,
      itemDetails: data,
      subTotal: data.reduce(function (total, current) {
        return total + current.qty * current.rate;
      }, 0),
      taxes: {
        gst:
          (taxValue / 200) *
          data.reduce(function (total, current) {
            return total + current.qty * current.rate;
          }, 0),
        sgst:
          (taxValue / 200) *
          data.reduce(function (total, current) {
            return total + current.qty * current.rate;
          }, 0),
      },
      grandTotal:
        data.reduce(function (total, current) {
          return total + current.qty * current.rate;
        }, 0) +
        (taxValue / 100) *
        data.reduce(function (total, current) {
          return total + current.qty * current.rate;
        }, 0),
      status: status,
      invoiceNote: invoiceNote,
      project: selectedProject,
    };

    const response = await updateInvoiceById(
      props.match.params.id,
      newInvoiceData
    );
    if (response?.data?.success) {
      history.push(`/invoice/${props.match.params.id}`);
    }
  };

  const Status = (status) => {
    return (
      <>
        {status === "Pending" ? (
          <p className="pending">Pending</p>
        ) : (
          <p className="paid">Paid</p>
        )}
      </>
    );
  };

  // const getStatus = (a, b) => {
  //   return new Date(b).getTime() - new Date(a).getTime() > 0
  //     ? "Pending"
  //     : "Paid";
  // };

  return (
    <>
      {invoiceData.loading ? (
        <div className="loader-container">
          <img src={loader} alt="icon" />
        </div>
      ) : (
        <div className="NewInvoice-container">
          <div className="heading">
            <div className="leftHead">
              <button onClick={() => history.goBack()}>
                <i class="fa fa-arrow-left" aria-hidden="true"></i>
              </button>
              <h1>Update Invoice #{invoiceData?.data?.invoiceNumber}</h1>
            </div>
          </div>
          <div className="invoice-container">
            <div className="left-invoice">
              <div className="invoice-box" id="capture">
                <div className="invoiceNum">
                  <div className="header">
                    <div
                      className="logo-selector"
                      style={{ flexDirection: "column", alignItems: "flex-start" }}
                    >
                      {Status(invoiceData.data.status)}
                      <br />
                      <h1>Invoice</h1>
                      <br />
                      <p>
                        <span style={{ fontWeight: "bold", margin: "0 8px" }}>
                          {invoiceData?.data?.invoiceNumber}
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className="header">
                    <img width="72px" src={image} alt="logo" />
                    {/* <h2>VIVID MYND</h2> */}
                  </div>

                </div>

                <div className="invoiceNum">
                  <div className="header" onClick={handleOpenSender}>
                    <div className="logo-selector">
                      <h4>From</h4>
                      {senderDetails.senderName ? (
                        <p className="company">{senderDetails.senderName}</p>
                      ) : (
                        <p className="company">Sender Name</p>
                      )}

                      {senderDetails.address ? (
                        <>
                          <p>{senderDetails.address}</p>
                          <p>{senderDetails.email}</p>
                          <p>{senderDetails.phone}</p>
                          {senderDetails.taxRegistration && (
                            <>
                              <p
                                className="company"
                                style={{ marginTop: "12px" }}
                              >
                                Tax Registration Number
                              </p>
                              <p>{senderDetails.taxRegistration}</p>
                            </>
                          )}
                        </>
                      ) : (
                        <p>Sender Contact Details</p>
                      )}
                    </div>
                  </div>
                  <div className="header" onClick={handleOpenReceiver}>
                    <div className="logo-selector">
                      <h4>To</h4>
                      {receiverDetails.receiverName ? (
                        <p className="company">{receiverDetails.receiverName}</p>
                      ) : (
                        <p className="company">Receiver Name</p>
                      )}

                      {receiverDetails.address ? (
                        <>
                          <p>{receiverDetails.organisation}</p>
                          <p>{receiverDetails.country}</p>
                          <p>{receiverDetails.address}</p>
                          <p>{receiverDetails.email}</p>
                          <p>{receiverDetails.phone}</p>
                        </>
                      ) : (
                        <p>Receiver Contact Details</p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="invoiceNum">
                  <div className="header">
                    {/* <img src={send} alt="send" /> */}
                    <div
                      className="logo-selector"
                      style={{
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                    >
                      <label>Invoice Date</label>
                      {billGenerated ? (
                        <input
                          type="Date"
                          value={dates.invoice}
                          onChange={(e) =>
                            setDates({ ...dates, invoice: e.target.value })
                          }
                        />
                      ) : (
                        <p>{dates.invoice}</p>
                      )}
                    </div>
                  </div>
                  <div className="header">
                    <div
                      className="logo-selector"
                      style={{
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                    >
                      <label>Due</label>
                      {billGenerated ? (
                        <input
                          type="Date"
                          value={dates.due}
                          onChange={(e) =>
                            setDates({ ...dates, due: e.target.value })
                          }
                        />
                      ) : (
                        <p>{dates.due}</p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="table">
                  <div className="Header">
                    <div className="Title Description">Description</div>
                    <div className="Title Quantity">Quantity</div>
                    <div className="Title Rate">Rate</div>
                    <div className="Title Amount">Amount</div>
                    <div className="Title Edit"></div>
                  </div>
                  {data.map((item) => (
                    <div className="ItemMap" key={item.id}>
                      <div className="Body Description">
                        {item.productDescription}
                      </div>
                      <div className="Body Quantity">{item.qty}</div>
                      <div className="Body Rate">
                      {selectedcurrency} {item.rate}
                      </div>
                      <div className="Body Amount">
                      {selectedcurrency} {item.qty * item.rate}
                      </div>
                      {billGenerated ? (
                        <div className="Body Edit">
                          <button
                            onClick={() => {
                              setEdit(true);
                              setAddData({
                                ...addData,
                                productDescription: item.productDescription,
                                qty: item.qty,
                                rate: item.rate,
                                id: item.id,
                              });
                            }}
                          >
                            <i class="fa fa-pencil" aria-hidden="true"></i>
                          </button>
                          <button
                            onClick={() => {
                              // data.filter((ITEM) => ITEM.id !== item.id);
                              filterArray(item.id);
                            }}
                          >
                            <i class="fa fa-trash-o" aria-hidden="true"></i>
                          </button>
                        </div>
                      ) : (
                        <div className="Body Edit"></div>
                      )}
                    </div>
                  ))}

                  {billGenerated && (
                    <div className="InputRow">
                      <div className="Desc">
                        <input
                          style={{ height: "auto" }}
                          placeholder="Item Name & Description"
                          type="text"
                          value={addData.productDescription}
                          onChange={(e) =>
                            setAddData({
                              ...addData,
                              productDescription: e.target.value,
                            })
                          }
                        />
                      </div>
                      <div className="Quantity">
                        <input
                          style={{ height: "auto" }}
                          placeholder="Quantity"
                          type="number"
                          value={addData.qty}
                          onChange={(e) =>
                            setAddData({ ...addData, qty: e.target.value })
                          }
                        />
                      </div>
                      <div className="Rate">
                        <input
                          placeholder="Rate"
                          type="number"
                          value={addData.rate}
                          onChange={(e) =>
                            setAddData({ ...addData, rate: e.target.value })
                          }
                        />
                      </div>
                      <div className="blank"></div>
                      <div className="blank">
                        {billGenerated && (
                          <>
                            {edit ? (
                              <button
                                className="addUpdateIcon"
                                onClick={() => {
                                  setEdit(false);
                                  updateDataTable();
                                }}
                              >
                                Update
                              </button>
                            ) : (
                              // <button className="EditInvoice" onClick={addDataTable}>
                              <button className="addUpdateIcon" onClick={addDataTable}>
                                + Add
                              </button>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  )}
                  <div className="Bar"></div>
                  <div className="invoiceSummary">
                    <div className="title">
                      <h3>Invoice Summary</h3>
                      <table>
                        <tr>
                          <td style={{ textAlign: "left" }}>Subtotal</td>
                          <td>
                          {selectedcurrency} {data.reduce(function (total, current) {
                              return total + current.qty * current.rate;
                            }, 0)}
                          </td>
                        </tr>
                        <tr>
                          <td style={{ textAlign: "left" }}>
                            {" "}
                            Tax{" "}
                            {billGenerated ? (
                              <input
                                value={taxValue}
                                placeholder=""
                                style={{ width: "64px", padding: "4px" }}
                                // type="number"
                                onChange={(e) => setTaxValue(e.target.value)}
                              />
                            ) : (
                              taxValue
                            )}
                            %
                          </td>
                          <td>
                          {selectedcurrency} {(taxValue / 100) *
                              data.reduce(function (total, current) {
                                return total + current.qty * current.rate;
                              }, 0)}
                          </td>
                        </tr>
                        <tr>
                          <td style={{ textAlign: "left" }}>Total</td>
                          <td>
                          {selectedcurrency} {data.reduce(function (total, current) {
                              return total + current.qty * current.rate;
                            }, 0) +
                              (taxValue / 100) *
                              data.reduce(function (total, current) {
                                return total + current.qty * current.rate;
                              }, 0)}{" "}
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div className="note-box">
                    {billGenerated ? (
                      <textarea
                        className="textArea"
                        placeholder="Invoice Note"
                        onChange={(e) => setInvoiceNote(e.target.value)}
                        value={invoiceNote}
                      />
                    ) : (
                      <p style={{ margin: "32px 0" }}>
                        {invoiceNote.split(/\n/g).map((item, i) => (
                          <>
                            {item}
                            <br />
                          </>
                        ))}
                      </p>
                    )}
                    {/* <h3>
                    <span>Email</span> : test@gmail.com
                  </h3> */}
                  </div>
                </div>
              </div>
              <InvoiceSender
                openSender={openSender}
                handleCloseSender={handleCloseSender}
                handleSenderInput={handleSenderInput}
                senderDetails={senderDetails}
              />
              <InvoiceReceiver
                openReceiver={openReceiver}
                handleCloseReceiver={handleCloseReceiver}
                handleReceiverInput={handleReceiverInput}
                receiverDetails={receiverDetails}
              />
            </div>

            <div className="right-invoice">
              <div className="input-row">
                <h2>Invoice Settings</h2>
                <div className="input-box">
                  <h3>Currency<span style={{ color: 'red' }}>*</span></h3>
                  <select
                    className="input"
                    onChange={(e) => setSelectedCurrency(e.target.value)}
                    value={selectedcurrency}
                  >
                    <option value="" defaultValue hidden>
                      Select Currency
                    </option>
                    {currencyJSON.map((item, index) => (
                      <option value={item.cc}>{item.symbol} {item.name}</option>
                    ))}
                  </select>
                </div>

                <div className="input-box">
                  <h3>Project<span style={{ color: 'red' }}>*</span></h3>
                  <select
                    className="input"
                    onChange={(e) => setSelectedProject(e.target.value)}
                    value={selectedProject}
                  >
                    <option value="" defaultValue hidden>
                      Select Project
                    </option>
                    {projects?.length > 0 &&
                      projects?.map((item, index) => (
                        <option value={item?._id}>{item?.name}</option>
                      ))}
                  </select>
                </div>
              </div>
              <div className="invoice-action">
                {billGenerated ? (
                  <button
                    onClick={() => {
                      setBillGenerated(false);
                    }}
                    className="generate"
                  >
                    Update Invoice
                  </button>
                ) : (
                  <>
                    <button
                      onClick={() => setBillGenerated(true)}
                      className="edit"
                    >
                      Edit
                    </button>
                    <button onClick={UpdateInvoice} className="generate">
                      Save
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>


          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            open={snack}
            autoHideDuration={1200}
            onClose={() => setSnack(false)}
            message="Link Copied"
          />
        </div>
      )}
    </>
  );
}

export default EditInvoice;
