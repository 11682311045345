import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import cancelled from '../../asset/icons/payment-cancelled.png';

function Cancel() {
    const history = useHistory();
    const [countdown, setCountdown] = useState(5);

    useEffect(() => {
        const timer = setTimeout(() => {
            if (countdown > 0) {
                setCountdown(countdown - 1);
            } 
        }, 1000);

        return () => clearTimeout(timer);
    }, [countdown]);

    useEffect(() => {
        if (countdown === 0) {
            history.push('/');
        }
    }, [countdown]);

    return (
        <div className="Login-Container">
            <div className="Form-Container flex items-center justify-center flex-col">
                <img src={cancelled} alt="payment-cancelled" style={{height: '80px', width: '80px'}} />
                <h3 className="text-20">Payment Cancelled!</h3>
                <span className="text-14">Redirecting in {countdown}sec...</span>
            </div>
        </div>
    );
}

export default Cancel;
