import React from "react";
import { NavLink, useHistory } from "react-router-dom";
import "./Navbar.scss";
import dummyProfile from "../../asset/icons/profile-dummy.svg";
import logo from "../../asset/logo/icon.png";
import { currencyJSON } from "../../asset/json/currency";
import { useSelector, useDispatch } from "react-redux";
import { updateSelectedCurrency } from "../../redux/profile/ProfileActions";
import { Menu, MenuItem } from "@material-ui/core";

function Navbar() {
  // dispatch
  const dispatch = useDispatch();
  const history = useHistory();
  // selector
  const { profile, selectedCurrency } = useSelector((state) => state.Profile);
  // const id = useSelector((state) => state?.Profile?.decode?._id);
  const changeSelectedCurrency = async (value) => {
    const status = await dispatch(updateSelectedCurrency(value));

    if (status) {
      window.location.reload();
    }
  }

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const logoutHandler = () => {
    dispatch({
      type: "LOG-OUT",
    });
    history.push("/");
  };

  return (
    <nav className="h-[60px] bg-grey-500">
      <div className="my-0 mx-auto py-1 px-0 w-[15%] flex items-center justify-center">
        <img src={logo} alt="logo" className="!h-[32px] !w-auto" />
      </div>
      <div className="links w-[70%] flex justify-start items-center">
        <div className="link-box">
          <NavLink
            exact
            classname="a"
            activeClassName="activeLink"
            to="/dashboard"
          >
            <span>Dashboard</span>
          </NavLink>

        </div>
        <div className="link-box">
          <NavLink
            exact
            classname="a"
            activeClassName="activeLink"
            to="/client-management"
          >
            <span>Clients</span>
          </NavLink>
        </div>
        <div className="link-box">
          <NavLink
            exact
            classname="a"
            activeClassName="activeLink"
            to="/proposal"
          >
            <span>AI Proposal</span>
          </NavLink>
        </div>
        <div className="link-box">
          <NavLink
            exact
            classname="a"
            activeClassName="activeLink"
            to="/project-management"
          >
            <span>Projects</span>
          </NavLink>
        </div>
        <div className="link-box">
          <NavLink
            exact
            classname="a"
            activeClassName="activeLink"
            to="/invoice-management"
          >
            <span>Invoices</span>
          </NavLink>
        </div>
        <div className="link-box">
          <NavLink
            exact
            classname="a"
            activeClassName="activeLink"
            to="/expenses"
          >
            <span>Expenses</span>
          </NavLink>
        </div>
        <div className="link-box">
          <NavLink
            exact
            classname="a"
            activeClassName="activeLink"
            to="/reports"
          >
            <span>Reports</span>
          </NavLink>
        </div>

        <div className="link-box">
          <NavLink
            exact
            classname="a"
            activeClassName="activeLink"
            to="/resource-management"
          >

            <span>Team</span>
          </NavLink>
        </div>
      </div>
      <div className="w-[15%] mt-0 flex items-center justify-center">
        <select className="rounded-md mb-0 bg-transparent !text-white mr-1 w-[70px] !py-1.5 !pl-1" onChange={(e) => changeSelectedCurrency(e.target.value)}>
          {currencyJSON.map((item, index) => (
            <option value={item.cc} className="text-black" selected={selectedCurrency === item.cc ? true : false}>{item.cc}</option>
          ))}
        </select>
        <img src={profile?.logoURL !== undefined ? profile?.logoURL : dummyProfile} alt="profile" className="!h-[32px] !w-auto cursor-pointer float-right border border-[#ffffff] rounded-md" onClick={handleClick} id="basic-button" aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined} />
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem>
            <NavLink
              exact
              classname="a"
              to="/settings"
            >
              <span>Profile</span>
            </NavLink></MenuItem>
          <MenuItem>
            <button onClick={logoutHandler}>
              <span>Logout</span>
            </button>
          </MenuItem>
        </Menu>
      </div>
    </nav>
  );
}

export default Navbar;
