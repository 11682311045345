import React from "react";
import { useDispatch } from "react-redux";
import './ToDoItemList.scss';
import { modifyToDoItem, fetchToDoItems } from '../../redux/profile/ProfileActions';

function Item ({title, description, dueDate, _id}) {
    const dispatch = useDispatch();
    const updateTodoItem = () => {
        dispatch(modifyToDoItem({itemID: _id, updatedContent: {status: 'done'}}));
        dispatch(fetchToDoItems());
    }
    return (
        <div className="ToDoItem">
            <div className="RadioButton">
                <input type="checkbox" id="check" onChange={()=> {updateTodoItem()}}/>
            </div>
            <div className="Item">
                <p className="ItemTitle">{title}</p>
                <p className="ItemDescription">{description}</p>
                <small>Due on <strong>{new Date(dueDate).toDateString()}</strong></small>
            </div>
        </div>
    )
}

function ToDoItemListWidget(props) {
    const { listData } = props;
    return (
        <div className="ToDoItemWidgetContainer">
            {
                listData && listData?.map((d, index) => (
                    <Item title={d.title} description={d.description} dueDate={d.dueDate} _id={d._id} key={index}/>
                ))
            }
        </div>
    )
}

export default ToDoItemListWidget;