import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getExpenses, createExpense, updateExpense, deleteExpense, getExpenseSummary } from '../../services/api.services';
import ExpenseForm from '../../components/expenses/ExpenseForm';
// import ExpenseTable from '../../components/expenses/ExpenseTable';
import ExpenseList from '../../components/expenses/ExpenseList';
import Page from '../Page';
import TotalExpenseIcon from '../../asset/icons/coins-stack.svg';
import ProjectExpenseIcon from '../../asset/icons/receipt-subtract.svg';
import OtherExpenseIcon from '../../asset/icons/payment-cash-out-3.svg';

// let getExpenses = () => { }, createExpense = () => { }, updateExpense = () => { }, deleteExpense = () => { };

// component for card
function Card(props) {
    return (
      <div
        className="w-64 bg-white items-center justify-evenly p-4 flex rounded-lg shadow-[0 1px 10px 0 rgba(0, 0, 0, 0.02)] mr-8 border border-solid border-[#e8e8e8]"
        style={{
          cursor: `${props.cursor}`,
          opacity: props.comingSoon ? "0.5" : "1",
        }}
        onClick={props.onClick}
      >
        <div
          style={{
            backgroundColor: `${props.background}`,
            border: `${props.border}`,
          }}
          className="w-16 h-16 rounded-full flex items-center justify-center"
        >
          <img src={props.img} alt="active" className='h-[32px] w-[32px]'/>
          {/* <i className='fa fa-receipt'></i> */}
        </div>
        <div className="content ml-1.5">
          <p>{props.title}</p>
          <h2>{props.data}</h2>
          <small>{props.subText}</small>
        </div>
      </div>
    );
  }

const ExpenseTracker = () => {
    const dispatch = useDispatch();
    const { selectedCurrency } = useSelector((state) => state?.Profile);

    const [expenses, setExpenses] = useState([]);
    const [expenseSummary, setExpenseSummary] = useState(null);
    const [isExpenseFormOpen, setIsExpenseFormOpen] = useState(false);
    const [editExpense, setEditExpense] = useState(null);

    useEffect(() => {
        fetchExpenses();
        fetchExpenseSummary();
    }, []);

    useEffect(()=>{
        fetchExpenseSummary();
    },[expenses])

    const fetchExpenses = async () => {
        try {
            const { data: { data } } = await getExpenses();
            if (data)
                setExpenses(data);
        } catch (error) {
            dispatch({
                type: "ALERT-ON",
                payload: {
                    severity: "error",
                    msg: error.message,
                },
            });
        }
    };

    const fetchExpenseSummary = async () => {
        try {
            const { data: { data } } = await getExpenseSummary();
            if (data)
                setExpenseSummary(data);
        } catch (error) {
            dispatch({
                type: "ALERT-ON",
                payload: {
                    severity: "error",
                    msg: error.message,
                },
            });
        }
    }

    const handleAddExpense = () => {
        setIsExpenseFormOpen(true);
        setEditExpense(null);
    };

    const handleEditExpense = (expense) => {
        console.log(expense);
        setIsExpenseFormOpen(true);
        setEditExpense(expense);
    };

    const handleDeleteExpense = async (expenseId) => {
        try {
            await deleteExpense(expenseId);
            fetchExpenses();
        } catch (error) {
            dispatch({
                type: "ALERT-ON",
                payload: {
                    severity: "error",
                    msg: error.message,
                },
            });
        }
    };

    const handleCreateExpense = async (formData) => {
        try {
            await createExpense(formData);
            setIsExpenseFormOpen(false);
            fetchExpenses();
        } catch (error) {
            dispatch({
                type: "ALERT-ON",
                payload: {
                    severity: "error",
                    msg: error.message,
                },
            });
        }
    };

    const handleUpdateExpense = async (formData) => {
        try {
            await updateExpense(formData);
            setIsExpenseFormOpen(false);
            fetchExpenses();
        } catch (error) {
            dispatch({
                type: "ALERT-ON",
                payload: {
                    severity: "error",
                    msg: error.message,
                },
            });
        }
    };

    const handleCancelExpenseForm = () => {
        setIsExpenseFormOpen(false);
        setEditExpense(null);
    };

    return (
        <Page>
            <div className="container p-4">
                <div className="flex flex-col my-3">
                    <h1 className="font-bold text-[#333333] w-full">Expense Management</h1>
                    <div className="w-full flex items-center justify-start mt-5">
                        <Card
                            title="Total Expenses"
                            background="#ffede2"
                            img={TotalExpenseIcon}
                            data={selectedCurrency+" "+expenseSummary?.totalExpensesThisMonth?.toLocaleString()}
                        />
                        <Card
                            title="Project Expenses"
                            background="#dfffee"
                            img={ProjectExpenseIcon}
                            data={selectedCurrency+" "+expenseSummary?.totalProjectExpensesThisMonth?.toLocaleString()}
                        />
                        <Card
                            title="Other Expenses"
                            background="#f1ebff"
                            img={OtherExpenseIcon}
                            data={selectedCurrency+" "+expenseSummary?.totalOtherExpensesThisMonth?.toLocaleString()}
                        />
                    </div>
                    <small className='text-12 text-grey-50 mt-2 w-full py-1 px-2 bg-grey-100 rounded-md'>Showing current month's stats</small>
                </div>

                <div className="flex justify-end mb-4">
                    <button
                        className="bg-purple text-white px-4 py-2 rounded-md border-none text-14"
                        onClick={handleAddExpense}
                    >
                        Add Expense
                    </button>
                </div>

                {isExpenseFormOpen && (
                    <ExpenseForm
                        onCancel={handleCancelExpenseForm}
                        onCreateExpense={handleCreateExpense}
                        onUpdateExpense={handleUpdateExpense}
                        editExpense={editExpense}
                        selectedCurrency={selectedCurrency}
                    />
                )}

                <ExpenseList
                    expenses={expenses}
                    onEditExpense={handleEditExpense}
                    onDeleteExpense={handleDeleteExpense}
                    selectedCurrency={selectedCurrency}
                />


            </div>
        </Page>
    );
};

export default ExpenseTracker;
