import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import logo from '../../asset/logo/icon.png';
import { initPurchase, razorpayCreateOrder, razorpayCallBack, applyCouponCode, redeemCouponCode, stripePay } from "../../services/api.services";
import "./UpdatePlan.scss";
import moment from "moment";
import { Modal } from "@material-ui/core";
import { useHistory } from "react-router-dom";

function UpdatePlan() {
  const [plan, setPlan] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState("");
  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  // selectors
  const currentPlan = useSelector(
    (state) => state?.Profile?.profile?.planDetails?.planID?._id
  );
  const currentPlanDetails = useSelector(
    (state) => state?.Profile?.profile?.planDetails
  );
  const plans = useSelector((state) => state.Util.plans);
  const { selectedplan, profile, decode } = useSelector(
    (state) => state.Profile
  );

  React.useEffect(() => {
    setSelectedPlan(currentPlan);
    if (process.env.REACT_APP_DEFAULT_PG == 'razorpay') {
      loadScript("https://checkout.razorpay.com/v1/checkout.js");
    }
  }, [currentPlan]);

  // dispatch
  const dispatch = useDispatch();

  // HISTORY
  const history = useHistory();
  // INIT ORDER
  const planPurchase = async () => {
    try {
      const planData = {
        transactionType: "PACKAGE",
        amount: {
          grossAmount: !plan ? selectedplan?.price : selectedplan?.yearlyPrice,
          discountAmount: 0.0,
          taxAmount: !plan
            ? selectedplan?.taxes?.gst * 0.01 * selectedplan?.price +
            selectedplan?.taxes?.sgst * 0.01 * selectedplan?.price
            : selectedplan?.taxes?.gst * 0.01 * selectedplan?.yearlyPrice +
            selectedplan?.taxes?.sgst * 0.01 * selectedplan?.yearlyPrice,
          netAmount: !plan
            ? selectedplan?.price +
            selectedplan?.taxes?.gst * 0.01 * selectedplan?.price +
            selectedplan?.taxes?.sgst * 0.01 * selectedplan?.price
            : selectedplan?.yearlyPrice +
            selectedplan?.taxes?.gst * 0.01 * selectedplan?.yearlyPrice +
            selectedplan?.taxes?.sgst * 0.01 * selectedplan?.yearlyPrice,
        },
        mode: "online",
        planID: selectedplan?._id,
        agencyID: decode?._id,
      };
      const { data, ...rest } = await initPurchase(planData);
      if (selectedplan?.price === 0) {
        if (!couponError && couponCodeValue) {
          await redeemCouponCode({ couponCode: couponCodeValue });
        }
        return history.push("dashboard");
      }
      if (data?.success) {
        const orderId = data?.orderID;
        if (process.env.REACT_APP_DEFAULT_PG == "razorpay") {
          // create rzp order id
          const { data } = await razorpayCreateOrder(planData?.amount?.netAmount, orderId);

          const options = {
            key: process.env.REACT_APP_RZP_KEY_ID,
            currency: "INR",
            amount: planData?.amount?.netAmount,
            name: "SynchroWork",
            description: "Online Purchase",
            image: logo,
            order_id: data.id,
            handler: async function (response) {
              const { data } = await razorpayCallBack(
                planData?.agencyID,
                orderId,
                planData?.planID,
                "agency",
                "PACKAGE_PURCHASE",
                plan,
                response.razorpay_order_id,
                response.razorpay_payment_id,
                response.razorpay_signature);

              if (data.success) {
                dispatch({ type: "REMOVE-ALL" });
                window.location.href = `/dashboard?orderId=${orderId}&status=${data.message}`
              }
              // alert(response.razorpay_payment_id);
              // alert(response.razorpay_order_id);
              // alert(response.razorpay_signature);
            },
            prefill: {
              "name": decode?.name,
              "email": decode?.email,
              "contact": decode?.phone
            },
            notes: {
              "address": `SLV 1177, Silicon Town, Bengaluru, KA-560099`
            },
            theme: {
              "color": "#8048f4"
            }
          };

          const paymentObject = new window.Razorpay(options);

          paymentObject.open();
        } else if (process.env.REACT_APP_DEFAULT_PG == "paytm") {
          // CREATING A HIDDEN FORM FOR PAYMENT
          const cont = document.querySelector("#FormContainer");
          var element = `<form className="" action="${process.env.REACT_APP_API_HOST}/pay/paytm/paynow" method="post" id="payform">
              <div className="form-group">
                <label for="">Name: </label>
                <input className="form-control" type="text" name="customerID" value=${planData.agencyID}>
              </div>
              <div className="form-group">
                <label for="">Email: </label>
                <input className="form-control" type="text" name="email" value=${decode?.email}>
              </div>
              <div className="form-group">
                <label for="">Phone: </label>
                <input className="form-control" type="text" name="phone" value=${decode?.phone}>
              </div>
              <div className="form-group">
                <label for="">Amount: </label>
                <input className="form-control" type="text" name="amount" value=${planData.amount.netAmount}>
                <input className="form-control" type="text" name="customerType" value="agency">

              </div>
              <div className="form-group">
                <!-- <label for="">Amount: </label> -->
                <input className="form-control" type="hidden" name="planID" value=${planData?.planID}>
                <input className="form-control" type="hidden" name="orderID" value=${orderId}>
                <input className="form-control" type="hidden" name="paymentAgenda" value="PACKAGE_PURCHASE">
                <input className="form-control" type="hidden" name="isYearlyPayment" value="${plan}">

              </div>
              <div className="form-group">
                <button className="btn form-control btn-primary">Pay Now</button>
              </div>
            </form>`;
          var z = document.createElement("div");
          z.innerHTML = element;
          cont.appendChild(z);
          document.getElementById("payform").submit();
        } else if (process.env.REACT_APP_DEFAULT_PG == "stripe") {
          const { data } = await stripePay(planData?.amount?.netAmount, orderId, { customer_email: decode?.email, agency: decode?._id, planId: selectedplan?._id, planName: selectedplan?.name });

          window.location.href = data.url;
        } else {
          alert("No Payment Gateway Selected!");
        }

      }
    } catch (e) {
      console.log(e);
    }
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // COUPON CODE
  const [couponCodeValue, setCouponCodeValue] = useState(null);
  const [couponError, setCouponError] = useState(false);
  const [couponMessage, setCouponMessage] = useState(null);
  const [couponApplied, setCouponApplied] = useState(false);
  const triggerCouponCode = async (coupon) => {
    try {
      const { data } = await applyCouponCode(coupon);
      if (data.success) {
        setCouponApplied(true);
        setCouponError(false);
        setCouponMessage(`${coupon} successfully applied! 🎉`);
        dispatch({
          type: "SELECT-PLAN",
          payload: data?.result?.associatedPlan[0],
        });
        setSelectedPlan(null);
      } else {
        setCouponError(true);
        setCouponApplied(false);
        if (selectedPlan === null) {
          dispatch({
            type: "SELECT-PLAN",
            payload: currentPlan,
          });
          setSelectedPlan(0);
        }

        setCouponMessage(`Coupon not found or expired!`);
      }
    } catch (e) {
      console.log("error", e.message);
    }
  }

  return (
    <>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="Modal">
          <h4>Have a coupon code?</h4>
          <div className="ModalBody">
            <div className="input-group">
              <input type="text" placeholder="eg: SYN250" onChange={(e) => setCouponCodeValue(e.target.value)} />
              <button className="apply-discount-btn" onClick={() => triggerCouponCode(couponCodeValue)}>Apply</button>
            </div>
            <small id="coupon-message" style={!couponError ? { color: 'green' } : { color: 'red' }}>
              {couponMessage}
            </small>
          </div>
        </div>
      </Modal>

      <div className="Plan-container !bg-[#f9f9f9]">
        <div className="Header">
          <div className="Left">
            <h2>Your Plans</h2>
            <p>{currentPlanDetails?.planID?.name} Plan is <strong>ACTIVE</strong> till {moment(currentPlanDetails?.expiryDate).format('dddd, MMMM Do YYYY, h:mm:ss a')}</p>
          </div>
        </div>
        <div className="Plans">
          {plans.length > 0 &&
            plans.map((item, index) => (
              <div
                className="plan !h-[120px]"
                style={{
                  backgroundColor:
                    selectedPlan === item?._id ? "#ffffff" : "#f2f2f2",
                  borderColor: selectedPlan === item?._id ? "#8048F4" : "#d5d5d5",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setSelectedPlan(item?._id);
                  dispatch({
                    type: "SELECT-PLAN",
                    payload: item,
                  });
                }}
              >
                <div className="w-full">
                  <h2 className="!text-30">
                    {item?.price === 0
                      ? "FREE"
                      : !plan
                        ? '₹' + item?.price + '/mo'
                        : '₹' + item?.yearlyPrice + '/yr'}
                  </h2>
                  <p className="text-16">{item?._id === currentPlan ? <small><i className="fa fa-check-circle" style={{ color: "#42b277" }}></i></small> : ''} {item?.name} Plan</p>
                  
                </div>

                <small>{item?.details?.description}</small>
              </div>
            ))}
        </div>
        <div className="Summary">
          <div className="coupon-code">
            <h5>Have a coupon code?</h5>
            <div className="coupon-form">
              <div className="input-group">
                <input type="text" placeholder="eg: SYN250" onChange={(e) => setCouponCodeValue(e.target.value)} />
                <button className="apply-discount-btn" onClick={() => triggerCouponCode(couponCodeValue)}>Apply</button>
              </div>
              <small id="coupon-message" style={!couponError ? { color: 'green' } : { color: 'red' }}>
                {couponMessage}
              </small>
            </div>

          </div>
          <div className="FinalPrice">
            <div className="Total">
              <h4>Total</h4>
              <p>
                INR{" "}
                {!plan
                  ? selectedplan?.price +
                  selectedplan?.taxes?.gst * 0.01 * selectedplan?.price +
                  selectedplan?.taxes?.sgst * 0.01 * selectedplan?.price
                  : selectedplan?.yearlyPrice +
                  selectedplan?.taxes?.gst * 0.01 * selectedplan?.yearlyPrice +
                  selectedplan?.taxes?.sgst *
                  0.01 *
                  selectedplan?.yearlyPrice}{" "}
                /-
              </p>
              {/* <p>(18% GST + 18% SGST inclusive)</p> */}
            </div>
            {
              selectedplan?.renewable || couponApplied ?
                (selectedPlan === currentPlan ? (
                  !moment(currentPlanDetails?.expiryDate).isAfter ? <button onClick={planPurchase}>Renew Now</button> : <button disabled style={{ background: "#a1a1a1" }}>Renew Now</button>
                ) : (
                  <button onClick={planPurchase}>Change Plan</button>
                )) : null
            }
          </div>
        </div>
      </div>
    </>

  );
}

export default UpdatePlan;
