import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import moment from "moment/moment";
import { TableFooter } from "@material-ui/core";
import { CSVLink } from "react-csv";
import { Link } from "react-router-dom";
import { Bar, BarChart, CartesianGrid, Cell, Legend, Pie, PieChart, Tooltip, XAxis, YAxis } from "recharts";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    paper: {
        width: "100%",
        marginBottom: theme.spacing(2),
        padding: "8px 0",
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1,
    },
}));

export default function InvoiceReportTable({
    data, selectedCurrency, paidAmount, pendingAmount
}) {
    const classes = useStyles();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(data?.length || 50);
    const [search, setSearch] = useState("");
    const [graphData, setGraphData] = useState([{ type: "Paid", total: paidAmount }, { type: "Pending", total: pendingAmount }]);

    const columns = [
        { field: "invoiceNumber", headerName: "Invoice #" },
        { field: "receiverDetails", headerName: "Client" },
        { field: "creationDate", headerName: "Created On" },
        { field: "dueDate", headerName: "Due Date" },
        { field: "paidDate", headerName: "Paid Date" },
        { field: "status", headerName: "Status" },
        { field: "currency", headerName: "Currency" },
        { field: "taxes", headerName: "Tax" },
        { field: "subTotal", headerName: "Sub Total" },
        { field: "grandTotal", headerName: "Total" }
    ];

    // search in table
    const searchTable = (data) => {
        return data?.filter(
            (datas) =>
                datas?.invoiceNumber
                    ?.toString()
                    ?.toLowerCase()
                    ?.includes(search.toString().toLowerCase()) ||
                datas?.receiverDetails?.receiverName
                    ?.toString()
                    ?.toLowerCase()
                    ?.includes(search.toString().toLowerCase()) ||
                datas?.status
                    ?.toString()
                    ?.toLowerCase()
                    ?.includes(search.toString().toLowerCase())
        );
    };

    const csvData = [
        columns?.map((d, i) => d?.headerName),
        ...data.map(({ invoiceNumber, receiverDetails, creationDate, dueDate, paidDate, status, currency, taxes, subTotal, grandTotal }) => [
            invoiceNumber,
            receiverDetails?.receiverName,
            moment(creationDate).format("DD-MMM-YYYY"),
            moment(dueDate).format("DD-MMM-YYYY"),
            moment(paidDate).format("DD-MMM-YYYY"),
            status,
            currency,
            taxes?.gst + taxes?.cgst,
            subTotal,
            grandTotal
        ]),
    ];

    return (
        <>
            <div className="bg-white p-5 border border-[#e8e8e8] shadow-sm w-full rounded">
                <h3 className="font-semibold text-16 text-grey-500 mb-2">Total Revenue</h3>
                <div className="flex flex-row items-center">
                    <span className="text-black text-24 font-bold">
                        {selectedCurrency} {data?.reduce((acc, obj) => { return obj?.grandTotal + acc }, 0)?.toLocaleString('en-US')}
                    </span>
                </div>
            </div>
            <div className="flex flex-row items-center justify-center mt-5">
                <div className="bg-white p-5 border border-[#e8e8e8] shadow-sm w-1/3 mr-5 rounded">
                    <h3 className="font-semibold text-16 text-grey-500 mb-2">Paid vs. Pending Invoices</h3>
                    <PieChart width={300} height={250}>
                        <Pie data={graphData} dataKey="total" nameKey="type" cx="50%" cy="50%" innerRadius={50} fill="#333" stroke="transparent">
                            {graphData?.map((entry, index) => (
                                <Cell
                                    key={entry + index}
                                    fill={`${entry?.type?.toLowerCase() === 'paid' ? '#82ca9d' : '#ffb900'}`}
                                />
                            ))}
                        </Pie>
                        <Tooltip />
                        <Legend formatter={(value, entry, index) => <span className="text-black text-12">{value}</span>} />
                    </PieChart>

                </div>
                <div className=" bg-white p-5 border border-[#e8e8e8] shadow-sm w-2/3 rounded">
                    <h3 className="font-semibold text-16 text-grey-500 mb-2">Invoicing Trend</h3>
                    <BarChart width={730} height={250} data={data}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="creationDate" fontSize={12} />
                        <YAxis fontSize={12} />
                        <Tooltip />
                        <Legend formatter={(value, entry, index) => <span className="text-black text-12">Grand Total</span>} />
                        <Bar dataKey="grandTotal" fill="#82ca9d" radius={[6, 6, 0, 0]} />
                    </BarChart>

                </div>
            </div>
            <div className={classes.root}>
                <div className="table-header">
                    <div className="control">
                        <div class="form">
                            <span>
                                <i class="fa fa-search" aria-hidden="true"></i>
                            </span>
                            <input
                                placeholder="Search.."
                                type="text"
                                onChange={(e) => setSearch(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="float-right">
                        <CSVLink className="w-[60px] border border-grey-50 text-14 p-3 rounded-md" filename="synchrowork-report.csv" data={csvData}>
                            Export to CSV
                        </CSVLink>
                    </div>
                </div>
                <div className={classes.paper}>
                    <TableContainer sx={{ maxHeight: 440 }}>
                        <Table
                            className={classes.table}
                            aria-labelledby="tableTitle"
                            aria-label="enhanced table"
                            stickyHeader
                        >
                            <TableHead>
                                <TableRow>
                                    {columns.map((row, id) => (
                                        <TableCell key={id} align={"center"} style={{ fontWeight: 'bold' }}>
                                            {row.headerName}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {data?.length > 0 &&
                                    searchTable(data)
                                        ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        ?.map((row, index) => {

                                            return (
                                                <TableRow
                                                    hover
                                                    tabIndex={-1}
                                                    key={row._id}
                                                >
                                                    <TableCell
                                                        style={{ whiteSpace: "nowrap", fontSize: "13px" }}
                                                        align="center"
                                                    >
                                                        <Link
                                                            style={{ textDecoration: "none" }}
                                                            className="table-button"
                                                            to={`/invoice/${row?._id}`}
                                                        >
                                                            {row?.invoiceNumber}
                                                        </Link>
                                                    </TableCell>
                                                    <TableCell
                                                        style={{ whiteSpace: "nowrap", fontSize: "13px" }}
                                                        align="center"
                                                    >
                                                        {row?.receiverDetails?.receiverName}
                                                    </TableCell>
                                                    <TableCell
                                                        style={{ whiteSpace: "nowrap", fontSize: "13px" }}
                                                        align="center"
                                                    >
                                                        {moment(row?.creationDate).format("DD-MMM-YYYY")}
                                                    </TableCell>
                                                    <TableCell
                                                        style={{ whiteSpace: "nowrap", fontSize: "13px" }}
                                                        align="center"
                                                    >
                                                        {moment(row?.dueDate).format("DD-MMM-YYYY")}
                                                    </TableCell>
                                                    <TableCell
                                                        style={{ whiteSpace: "nowrap", fontSize: "13px" }}
                                                        align="center"
                                                    >
                                                        {moment(row?.paidDate).format("DD-MMM-YYYY")}
                                                    </TableCell>
                                                    <TableCell align="center" style={{ fontSize: "13px" }}>
                                                        <strong className={`uppercase rounded-sm p-1 ${row?.status?.toLowerCase() === 'paid' ? 'bg-[#D5F5E3]' : 'bg-[#FCF3CF]'}`}>{row.status}</strong>
                                                    </TableCell>
                                                    <TableCell align="center" style={{ fontSize: "13px" }}>
                                                        {row?.currency}
                                                    </TableCell>
                                                    <TableCell align="center" style={{ fontSize: "13px" }}>
                                                        {row?.taxes?.gst + row?.taxes?.csgst}
                                                    </TableCell>
                                                    <TableCell align="center" style={{ fontSize: "13px" }}>
                                                        {row?.subTotal}
                                                    </TableCell>
                                                    <TableCell align="center" style={{ fontSize: "13px" }}>
                                                        {row?.grandTotal}
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                            </TableBody>

                            <TableFooter>
                                <TableRow>
                                    <TableCell align="center" style={{ fontSize: "13px", fontWeight: "bold" }} colSpan={8}>
                                        {''}
                                    </TableCell>
                                    <TableCell align="center" style={{ fontSize: "13px", fontWeight: "bold" }} colSpan={1}>
                                        Grand Total
                                    </TableCell>
                                    <TableCell align="center" style={{ fontSize: "13px", fontWeight: "bold" }} colSpan={1}>
                                        {selectedCurrency} {data?.reduce((acc, obj) => { return obj?.grandTotal + acc }, 0)?.toLocaleString('en-US')}
                                    </TableCell>
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </div>
            </div>
        </>

    );
}
