import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { login } from "../../services/api.services";
import jwt_decode from "jwt-decode";
import "./Login.scss";
import logo from "../../asset/logo/logo.svg";
import googleSignin from "../../asset/icons/btn-google-signin.png";

function Login() {
  // HISTORY
  const history = useHistory();
  // DISPATCH
  const dispatch = useDispatch();
  // STATE
  const [input, setInput] = useState({
    email: "",
    password: "",
  });

  const userLogin = async () => {
    try {
      const { data } = await login(input);
      if (!data?.success) {
        dispatch({
          type: "ALERT-ON",
          payload: {
            severity: "error",
            msg: data?.message,
          },
        });
      } else {
        const decode = jwt_decode(data?.data);
        dispatch({
          type: "LOG-IN",
          payload: data?.data,
        });
        dispatch({
          type: "DECODE",
          payload: decode?.AGENCY,
        });

        if(decode?.AGENCY?.planDetails !== undefined)
          history.push("dashboard");
        else
          history.push("plans");
      }
    } catch (e) {
      dispatch({
        type: "ALERT-ON",
        payload: {
          severity: "error",
          msg: e.message,
        },
      });
    }
  };

  const onEnterPress = (e) => {
    if(e.key === 'Enter') {
      document.getElementById("login-button").click();
    }
  }

  useEffect( ()=> {
    if(localStorage.getItem("SYNCHRO-TOKEN")) {
      history.push('/dashboard');
    }
  })

  return (
    <div className="Login-Container">
      <img src={logo} alt="logo" />
      <div className="Form-Container">
        <h2>Agency Login</h2>
        <p>
          Not having an account? <Link to="/sign-up">Signup for free</Link>
        </p>
        <div className="input-box">
            <label>
              Email
            </label>
        <input
          type="email"
          placeholder="Enter your email id"
          onChange={(e) => setInput({ ...input, email: e.target.value })}
          onKeyDown={(e) => onEnterPress(e)}
        />
        </div>
        <div className="input-box">
            <label>
              Password
            </label>
        <input
          type="password"
          placeholder="Password"
          onChange={(e) => setInput({ ...input, password: e.target.value })}
          onKeyDown={(e) => onEnterPress(e)}
        />
        </div>
        <div className="button-ctr">
          <p>
            Forget password? <Link to="/forgot-password">Reset</Link>
          </p>
          <button onClick={() => userLogin()} id="login-button">Login</button>
        </div>
        {/* <div className="google-signin">
          <p className="">or</p>
          <a href={`${process.env.REACT_APP_API_HOST}/agency/google`}>
            <img src={googleSignin} alt="google sign"/>
          </a>
        </div> */}
      </div>
    </div>
  );
}

export default Login;
