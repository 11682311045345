import React, { useState } from "react";
import "./InvoiceManagement.scss";
import active from "../../asset/images/active.png";
import payment from "../../asset/images/payment.png";
import generate from "../../asset/images/generate.png";
import {} from "@material-ui/core";
import InvoiceTable from "../../components/invoiceTable/InvoiceTable";
import NewInvoice from "../../components/newInvoice/NewInvoice";
import Page from "../Page";
import { useSelector } from "react-redux";

// component for card
function Card(props) {
  return (
    <div
      className="card"
      style={{
        cursor: `${props.cursor}`,
      }}
      onClick={props.onClick}
    >
      <div
        style={{
          backgroundColor: `${props.background}`,
          border: `${props.border}`,
        }}
        className="img-ctr"
      >
        <img src={props.img} alt="active" />
      </div>
      <div className="content">
        <p>{props.title}</p>
        <h2>{props.data}</h2>
      </div>
    </div>
  );
}

function InvoiceManagement() {
  const [newInvoice, setNewInvoice] = useState(false);
  // selector
  const { profile, projectSummary, invoiceSummary, selectedCurrency } = useSelector(
    (state) => state.Profile
  );
  return (
    <Page>
      <div className="container Invoice">
        <div className="center">
          <h1>Invoice Management</h1>
          <div className="card-container">
            {/* <Card
              title="Active projects"
              background="#dfffee"
              img={active}
              data={projectSummary?.activeProjects}
            /> */}
            <Card
              title="Pending Payment"
              background="#ffede2"
              img={payment}
              data={`${selectedCurrency} ${invoiceSummary?.totalPending}`}
            />
            <Card
              title="Collected Payment"
              background="#ffede2"
              img={payment}
              data={`${selectedCurrency} ${invoiceSummary?.totalCollected}`}
            />
            <Card
              background="#f1ebff"
              border="2px dashed #8048f4"
              cursor="pointer"
              img={generate}
              data="Generate"
              onClick={() => setNewInvoice(true)}
            />
          </div>
          <div className="table-container">
            <InvoiceTable />
          </div>
        </div>
        {newInvoice ? (
          <NewInvoice setNewInvoice={() => setNewInvoice(false)} />
        ) : null}
      </div>
    </Page>
  );
}

export default InvoiceManagement;
