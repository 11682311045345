import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { signup } from "../../services/api.services";
import "./SignUp.scss";
import logo from "../../asset/logo/logo.svg";

function SignUp() {
  // STATE
  const [input, setInput] = useState({
    email: "",
    phone: "",
    name: "",
    agencyID: "",
    businessType: "",
    password: "",
    rePassword: "",
  });

  // DISPATCH
  const dispatch = useDispatch();

  // history
  const history = useHistory();

  //  ON INPUT CHANGE
  const onInputChange = (e) => {
    setInput({
      ...input,
      [e.target.name]: e.target.value,
    });
  };

  const onEnterPress = (e) => {
    if(e.key === 'Enter') {
      document.getElementById("signup-button").click();
    }
  }

  const createAccount = async () => {
    if (input?.password !== input?.rePassword) {
      return dispatch({
        type: "ALERT-ON",
        payload: {
          severity: "error",
          msg: "Password mismatched !",
        },
      });
    }
    try {
      input.agencyID = input?.name?.toLowerCase().replace(" ", "-");
      const { rePassword, ...rest } = input;
      const { data } = await signup(rest);

      if (!data?.success) {
        dispatch({
          type: "ALERT-ON",
          payload: {
            severity: "error",
            msg: data?.message,
          },
        });
      } else {
        const decode = jwt_decode(data?.data);

        dispatch({
          type: "LOG-IN",
          payload: data?.data,
        });
        dispatch({
          type: "DECODE",
          payload: decode?.AGENCY,
        });
        history.push("/plans");
      }
    } catch (e) {
      dispatch({
        type: "ALERT-ON",
        payload: {
          severity: "error",
          msg: e.message,
        },
      });
    }
  };

  // HISTORY
  // const history = useHistory();

  return (
    <div className="Signup-Container">
      <img src={logo} alt="logo" />
      <div className="Form-Container">
        <h2>Create free account</h2>
        <p>
          Already have an account? <Link to="/">Login here</Link>
        </p>
        <div className="Form-Box">
          <div className="input-box">
            <label>
              Organization Name
            </label>
            <input
              type="text"
              placeholder="eg: Fastzap Pvt. Ltd, Sushant's Designs"
              name="name"
              onChange={onInputChange}
            />
          </div>
          <div className="input-box">
            <label>
              Business Type
            </label>
            {/* <input
              type="text"
              placeholder="Agency ID"
              name="agencyID"
              onChange={onInputChange}
            /> */}
            <select 
              name="businessType"
              onChange={onInputChange}>
              <option value="agency">Agency</option>
              <option value="freelancer">Freelancer</option>
            </select>
          </div>
        </div>
        <div className="Form-Box">
          <div className="input-box">
            <label>
              Email
            </label>
            <input
              type="email"
              placeholder="Enter your email id"
              name="email"
              onChange={onInputChange}
            />
          </div>
          <div className="input-box">
            <label>
              Contact No.
            </label>
          <input
            type="number"
            placeholder="Contact Number"
            name="phone"
            onChange={onInputChange}
          />
          </div>
        </div>
        
        <div className="Form-Box">
        <div className="input-box">
            <label>
              Password
            </label>
          <input
            type="password"
            placeholder="*******"
            name="password"
            onChange={onInputChange}
          />
          </div>
          <div className="input-box">
            <label>
              Re-type Password
            </label>
          <input
            type="password"
            placeholder="*******"
            name="rePassword"
            onChange={onInputChange}
            onKeyDown={(e) => onEnterPress(e)}
          />
          </div>
        </div>

        <div className="button-ctr">
          <p>
            <a href="https://synchrowork.com/privacy-policy">Privacy Policy</a> | <a href="https://synchrowork.com/cookie-policy">Cookie Policy</a>
          </p>
          <button onClick={createAccount} id="signup-button">Sign Up</button>
        </div>
      </div>
    </div>
  );
}

export default SignUp;
