import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
// import jsPDF from "jspdf";
// import html2canvas from "html2canvas";
import "../../pages/invoicePage/InvoicePage.scss";
import InvoiceSender from "../invoiceDetails/InvoiceSender";
import InvoiceReceiver from "../invoiceDetails/InvoiceReceiver";
import { Snackbar } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { currencyJSON } from "../../asset/json/currency";
import { addInvoiceToProject } from "../../redux/profile/ProfileActions";
import { getAgencyLastInvoiceNumber } from '../../services/api.services';

function NewInvoice(props) {
  // DISPATCH
  const dispatch = useDispatch();

  // SELECTORS
  const id = useSelector((state) => state?.Profile?.decode?._id);
  const decode = useSelector((state) => state?.Profile?.decode);
  const profile = useSelector((state) => state.Profile.profile);
  const defaultCurrency = useSelector((state) => state.Profile.selectedCurrency);

  // state
  const history = useHistory();
  const [data, setData] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedcurrency, setSelectedCurrency] = useState(defaultCurrency || "");
  const [image, setImage] = useState("");
  const [openSender, setOpenSender] = useState(false);
  const [openReceiver, setOpenReceiver] = useState(false);
  const [billGenerated, setBillGenerated] = useState(true);
  const [invoiceNote, setInvoiceNote] = useState("");
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [taxValue, setTaxValue] = useState(profile?.taxRule?.filter((item) => item._id == profile?.defaultTaxRule)[0]?.percentage || 0);
  const [snackMessage, setSnackMessage] = useState("");

  const [dates, setDates] = useState({
    invoice: "",
    due: "",
  });
  const [snack, setSnack] = useState(false);

  const [senderDetails, setSenderDetails] = useState({
    senderName: decode?.name,
    email: decode?.email,
    phone: decode?.phone,
    taxRegistration: "",
    website: "",
    address: "",
  });

  const [receiverDetails, setReceiverDetails] = useState({
    receiverName: "",
    organisation: "",
    country: "",
    email: "",
    phone: "",
    address: "",
  });

  // selector
  const projects = useSelector((state) => state?.Profile?.profile?.projects) || null;

  // HANDLER
  const handleSenderInput = (e) => {
    setSenderDetails({ ...senderDetails, [e.target.name]: e.target.value });
  };

  const handleReceiverInput = (e) => {
    if (e?.target?.name === "receiverName") {
      const selectedClient = profile?.clientID?.filter(
        (item) => item._id === e.target.value
      )[0];
      return setReceiverDetails({
        ...receiverDetails,
        receiverName: selectedClient?.name,
        organisation: selectedClient?.organizationName,
        country: "",
        email: selectedClient?.email,
        phone: selectedClient?.phone,
        address: "",
      });
    }

    if (e.target.name === 'phone') {
      const re = /^(?!\b(0)\1+\b)(\+?\d{1,3}[. -]?)?\(?\d{3}\)?([. -]?)\d{3}\3\d{4}$/;
      if (e.target.value.length === 10 || !re.test(e.target.value)) {
        e.preventDefault();
      }
    }
    return setReceiverDetails({
      ...receiverDetails,
      [e.target.name]: e.target.value,
    });
  };

  const handleOpenSender = () => {
    setOpenSender(true);
  };
  const handleCloseSender = () => {
    setOpenSender(false);
  };

  const handleOpenReceiver = () => {
    setOpenReceiver(true);
  };
  const handleCloseReceiver = () => {
    setOpenReceiver(false);
  };

  const [addData, setAddData] = useState({
    id: uuidv4(),
    productDescription: "",
    qty: "",
    rate: "",
  });

  const [edit, setEdit] = useState(false);

  const filterArray = (id) => {
    const result = data.filter((word) => word.id !== id);
    setData(result);
  };
  const addDataTable = () => {
    if (addData && addData?.rate !== "" && addData?.qty !== "" && addData?.productDescription !== "") {
      setData([...data, addData]);
      setAddData({
        ...data,
        id: uuidv4(),
        productDescription: "",
        qty: "",
        rate: "",
      });
    } else {
      setSnackMessage("Cannot accept blank entry!");
      setSnack(true);
    }

  };

  const updateDataTable = () => {
    const updateData = data.map((item) => {
      if (item.id === addData.id) {
        item.productDescription = addData.productDescription;
        item.qty = addData.qty;
        item.rate = addData.rate;
      }
      return item;
    });
    setData(updateData);
    setAddData({
      ...data,
      id: "",
      productDescription: "",
      qty: "",
      rate: "",
    });
    setEdit(false);
  };

  const createNewInvoice = async () => {

    var newInvoiceData = {
      // projectID: uuidv4(),
      invoiceNumber: invoiceNumber,
      invoiceLogo: profile?.logoURL,
      invoiceType: "monthly",
      language: "ENG(US)",
      currency: selectedcurrency,
      receipientId: uuidv4(),
      creationDate: dates.invoice,
      dueDate: dates.due,
      senderDetails: senderDetails,
      receiverDetails: receiverDetails,
      itemDetails: data,
      subTotal: data.reduce(function (total, current) {
        return total + current.qty * current.rate;
      }, 0),
      taxes: {
        gst:
          (taxValue / 200) *
          data.reduce(function (total, current) {
            return total + current.qty * current.rate;
          }, 0),
        sgst:
          (taxValue / 200) *
          data.reduce(function (total, current) {
            return total + current.qty * current.rate;
          }, 0),
      },
      grandTotal:
        data.reduce(function (total, current) {
          return total + current.qty * current.rate;
        }, 0) +
        (taxValue / 100) *
        data.reduce(function (total, current) {
          return total + current.qty * current.rate;
        }, 0),
      invoiceLink: "",
      status: "Pending",
      invoiceNote: invoiceNote,
      project: selectedProject,
      agency: id,
    };

    const { status, ids } = await dispatch(
      addInvoiceToProject(id, newInvoiceData)
    );

    if (status) {
      history.push(`/invoice/${ids}`);
    }

  };

  const previewInvoice = () => {
    if (selectedcurrency !== "" && invoiceNumber && receiverDetails.receiverName !== "") {
      setBillGenerated(false);
    } else {
      setSnackMessage("Cannot proceed wiithout required (*) fields!");
      setSnack(true);
    }
  }

  const Status = (status) => {
    return (
      <>
        {status === "Pending" ? (
          <p className="pending">Pending</p>
        ) : (
          <p className="paid">Paid</p>
        )}
      </>
    );
  };

  // const getStatus = (a, b) => {
  //   return new Date(b).getTime() - new Date(a).getTime() > 0
  //     ? "Pending"
  //     : "Paid";
  // };

  // converting image to base 64
  const imageToBase64 = async (url) => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      };
    });
  };

  const getInvoiceLastNumber = async () => {
    let { data } = await getAgencyLastInvoiceNumber(profile?._id);
    console.log("data", data);
    let extractedNumber = data?.data[0]?.invoiceNumber?.match(/\d+/g)[data?.data[0]?.invoiceNumber?.match(/\d+/g).length - 1];
    let incrementedNumber = parseInt(extractedNumber, 10) + 1;
    let updatedInvoiceNumber = data?.data[0]?.invoiceNumber?.replace(extractedNumber, incrementedNumber.toString());
    setInvoiceNumber(updatedInvoiceNumber);
  }

  // use effect
  useEffect(() => {
    imageToBase64(profile?.logoURL).then((res) => setImage(res));
    getInvoiceLastNumber();
  }, [profile]);

  useEffect(() => {
    invoiceNumber === "" && setInvoiceNumber(`INV-${Math.floor(1000 + Math.random() * 9000)}`)
  }, [invoiceNumber]);

  return (
    <>
      <div className="NewInvoice-container">
        <div className="heading">
          <div className="leftHead">
            <button onClick={props.setNewInvoice}>
              <i class="fa fa-arrow-left" aria-hidden="true"></i>
            </button>
            {/* <h1>New Invoice</h1> */}
          </div>
          {/* <div className="rightHead">
            {billGenerated ? (
              <button
                onClick={() => {
                  setBillGenerated(false);
                }}
                className="generate"
              >
                Create Invoice
              </button>
            ) : (
              <>
                <button onClick={() => setBillGenerated(true)} className="edit">
                  Edit
                </button>
                <button onClick={createNewInvoice} className="generate">
                  Save
                </button>
              </>
            )}
          </div> */}
        </div>
        <div className="invoice-container">
          <div className="left-invoice">

            <div className="invoice-box" id="capture">
              <div className="invoiceNum">
                <div className="header">
                  <div
                    style={{ flexDirection: "column", alignItems: "center" }}
                  >
                    <h1>Invoice</h1>
                    <br />
                    <p>
                      {billGenerated ? (
                        <input
                          type="text"
                          placeholder="INV-1239"
                          onChange={(e) => setInvoiceNumber(e.target.value)}
                          value={invoiceNumber}
                        />
                      ) : (
                        <span style={{ fontWeight: "bold" }}>{invoiceNumber}</span>
                      )}
                    </p>
                  </div>
                </div>
                <div className="header">
                  {profile?.logoURL && <img width="72px" src={image} alt="logo" />}
                </div>
              </div>

              <div className="invoiceNum">
                <div className="header" onClick={handleOpenSender}>
                  {/* <div className="title">
      <img src={send} alt="send" />
    </div> */}
                  <div className="logo-selector">
                    <h4>From</h4>
                    {senderDetails.senderName.trim() ? (
                      <p className="company">{senderDetails.senderName}</p>
                    ) : (
                      <p className="company">Sender Name</p>
                    )}

                    {senderDetails.address.trim() ? (
                      <>
                        <p>{senderDetails.address}</p>
                        <p>{senderDetails.email}</p>
                        <p>{senderDetails.phone}</p>
                        {senderDetails.taxRegistration && (
                          <>
                            <p className="company" style={{ marginTop: "12px" }}>
                              Tax Registration Number
                            </p>
                            <p>{senderDetails.taxRegistration}</p>
                          </>
                        )}
                      </>
                    ) : (
                      <p>Sender Contact Details</p>
                    )}
                  </div>
                </div>
                <div className="header" onClick={handleOpenReceiver}>
                  {/* <div className="title">
      <h4>TO</h4>
      <img src={receive} alt="receive" />
    </div> */}
                  <div className="logo-selector">
                    <h4>To<span style={{color: 'red'}}>*</span></h4>
                    {receiverDetails?.receiverName?.trim() ? (
                      <p className="company">{receiverDetails?.receiverName}</p>
                    ) : (
                      <p className="company">Receiver Name</p>
                    )}

                    {receiverDetails.address.trim() ? (
                      <>
                        <p>{receiverDetails.organisation}</p>
                        <p>{receiverDetails.country}</p>
                        <p>{receiverDetails.address}</p>
                        <p>{receiverDetails.email}</p>
                        <p>{receiverDetails.phone}</p>
                      </>
                    ) : (
                      <p>Receiver Contact Details</p>
                    )}
                  </div>
                </div>
              </div>

              <div className="invoiceNum">
                <div className="header">
                  {/* <img src={send} alt="send" /> */}
                  <div
                    className="logo-selector"
                    style={{ flexDirection: "column", alignItems: "flex-start" }}
                  >
                    <h4>Invoice Date</h4>
                    {billGenerated ? (
                      <input
                        type="Date"
                        value={dates.invoice}
                        onChange={(e) =>
                          setDates({ ...dates, invoice: e.target.value })
                        }
                      />
                    ) : (
                      <p>{dates.invoice}</p>
                    )}
                  </div>
                </div>
                <div className="header">
                  <div
                    className="logo-selector"
                    style={{ flexDirection: "column", alignItems: "flex-start" }}
                  >
                    <h4>Due</h4>
                    {billGenerated ? (
                      <input
                        type="Date"
                        value={dates.due}
                        onChange={(e) =>
                          setDates({ ...dates, due: e.target.value })
                        }
                      />
                    ) : (
                      <p>{dates.due}</p>
                    )}
                  </div>
                </div>
              </div>

              <div className="table">
                <div className="Header">
                  <div className="Title Description">Description</div>
                  <div className="Title Quantity">Quantity</div>
                  <div className="Title Rate">Rate</div>
                  <div className="Title Amount">Amount</div>
                  <div className="Title Edit"></div>
                </div>
                {data.map((item) => (
                  <div className="ItemMap" key={item.id}>
                    <div className="Body Description">
                      {item.productDescription}
                    </div>
                    <div className="Body Quantity">{item.qty}</div>
                    <div className="Body Rate">
                      {selectedcurrency} {item.rate}
                    </div>
                    <div className="Body Amount">
                      {selectedcurrency} {item.qty * item.rate}
                    </div>
                    {billGenerated ? (
                      <div className="Body Edit">
                        <button
                          onClick={() => {
                            setEdit(true);
                            setAddData({
                              ...addData,
                              productDescription: item.productDescription,
                              qty: item.qty,
                              rate: item.rate,
                              id: item.id,
                            });
                          }}
                        >
                          <i class="fa fa-pencil" aria-hidden="true"></i>
                        </button>
                        <button
                          onClick={() => {
                            filterArray(item.id);
                          }}
                        >
                          <i class="fa fa-trash-o" aria-hidden="true"></i>
                        </button>
                      </div>
                    ) : (
                      <div className="Body Edit">

                      </div>
                    )}
                  </div>
                ))}

                {/* <div className="Bar"></div> */}
                {billGenerated && (
                  <div className="InputRow">
                    <div className="Desc">
                      <input
                        style={{ height: "auto" }}
                        placeholder="Item Name & Description"
                        type="text"
                        value={addData.productDescription}
                        onChange={(e) =>
                          setAddData({
                            ...addData,
                            productDescription: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="Quantity">
                      <input
                        style={{ height: "auto" }}
                        placeholder="Qty"
                        type="number"
                        value={addData.qty}
                        onChange={(e) =>
                          setAddData({ ...addData, qty: e.target.value })
                        }
                      />
                    </div>
                    <div className="Rate">
                      <input
                        placeholder="Rate"
                        type="number"
                        value={addData.rate}
                        onChange={(e) =>
                          setAddData({ ...addData, rate: e.target.value })
                        }
                      />
                    </div>
                    <div className="blank"></div>
                    <div className="blank">
                      {billGenerated && (
                        <>
                          {edit ? (
                            <button
                              className="addUpdateIcon"
                              onClick={() => {
                                setEdit(false);
                                updateDataTable();
                              }}
                            >
                              Update
                            </button>
                          ) : (
                            // <button className="EditInvoice" onClick={addDataTable}>
                            <button className="addUpdateIcon" onClick={addDataTable}>
                              + Add
                            </button>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                )}
                <div className="Bar"></div>
                <div className="invoiceSummary">
                  <div className="title">
                    <h3>Invoice Summary</h3>
                    <table>
                      <tr>
                        <td style={{ textAlign: "left" }}>Subtotal</td>
                        <td>
                          {selectedcurrency} {data.reduce(function (total, current) {
                            return total + current.qty * current.rate;
                          }, 0)}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ textAlign: "left" }}>
                          Tax{" "}
                          {billGenerated ? (
                            <input
                              value={taxValue}
                              placeholder="0"
                              style={{ width: "64px", padding: "4px" }}
                              // type="number"
                              onChange={(e) => setTaxValue(e.target.value)}
                            />
                          ) : (
                            taxValue
                          )}
                          %
                        </td>
                        <td>
                          {selectedcurrency} {(taxValue / 100) *
                            data.reduce(function (total, current) {
                              return total + current.qty * current.rate;
                            }, 0)}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ textAlign: "left" }}>Total</td>
                        <td>
                          {selectedcurrency} {data.reduce(function (total, current) {
                            return total + current.qty * current.rate;
                          }, 0) +
                            (taxValue / 100) *
                            data.reduce(function (total, current) {
                              return total + current.qty * current.rate;
                            }, 0)}
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
                <div className="note-box">
                  {billGenerated ? (
                    <textarea
                      className="textArea"
                      placeholder="Invoice Note"
                      onChange={(e) => setInvoiceNote(e.target.value)}
                      value={invoiceNote}
                    />
                  ) : (
                    <p style={{ margin: "32px 0" }}>
                      {invoiceNote.split(/\n/g).map((item, i) => (
                        <>
                          {item}
                          <br />
                        </>
                      ))}
                    </p>
                  )}
                  {/* <h3>
      <span>Email</span> : test@gmail.com
    </h3> */}
                </div>
              </div>
            </div>
            <InvoiceSender
              openSender={openSender}
              handleCloseSender={handleCloseSender}
              handleSenderInput={handleSenderInput}
              senderDetails={senderDetails}
            />
            <InvoiceReceiver
              openReceiver={openReceiver}
              handleCloseReceiver={handleCloseReceiver}
              handleReceiverInput={handleReceiverInput}
              receiverDetails={receiverDetails}
            />
          </div>
          <div className="right-invoice">
            <div className="input-row">
              <h3>Invoice Settings</h3>

              <div className="input-box">
                <h4>Currency<span style={{color: 'red'}}>*</span></h4>
                <select
                  className="input"
                  onChange={(e) => setSelectedCurrency(e.target.value)}
                  disabled
                >
                  <option value="" hidden>
                    Select Currency
                  </option>
                  {currencyJSON.map((item, index) => (
                    <option value={item.cc} selected={item.cc === selectedcurrency}>{item.cc}</option>
                  ))}
                </select>
                <small className="text-12 text-[#e7e7e7]"></small>
              </div>

              <div className="input-box">
                <h4>Project<span style={{color: 'red'}}>*</span></h4>
                <select
                  className="input"
                  onChange={(e) => setSelectedProject(e.target.value)}
                >
                  <option value="" defaultValue hidden>
                    Select Project
                  </option>
                  {projects?.length > 0 &&
                    projects?.map((item, index) => (
                      <option value={item?._id}>{item?.name}</option>
                    ))}
                </select>
              </div>
            </div>

            <div className="invoice-action">
              {billGenerated ? (
                <button
                  onClick={() => {
                    previewInvoice()
                  }}
                  className="generate"
                >
                  Preview Invoice
                </button>
              ) : (
                <>
                  <button onClick={() => setBillGenerated(true)} className="edit">
                    Edit
                  </button>
                  <button onClick={createNewInvoice} className="generate">
                    Save
                  </button>
                </>
              )}
            </div>
          </div>

        </div>


        {
          snack && <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            open={snack}
            autoHideDuration={1200}
            onClose={() => setSnack(false)}
            message={snackMessage}
          />
        }
      </div>
    </>

  );
}

export default NewInvoice;
