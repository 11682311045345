import React, { useState } from "react";
import Page from "../Page";
import { useDispatch, useSelector } from "react-redux";
import { generateReport } from "../../services/api.services";
import InvoiceReportTable from "../../components/reports/InvoiceReportTable";
import { currencyJSON } from "../../asset/json/currency";
import { useEffect } from "react";
import ExpenseReportTable from "../../components/reports/ExpenseReportTable";
import ProjectReportTable from "../../components/reports/ProjectReportTable";

function ReportGenerator() {
    const dispatch = useDispatch();
    const { selectedCurrency, profile: { projects } } = useSelector((state) => state.Profile);

    const [reportType, setReportType] = useState('revenue');
    const [period, setPeriod] = useState('all');
    const [status, setStatus] = useState('all');
    const [client, setClient] = useState('all');
    const [report, setReport] = useState(null);
    const [currency, setCurrency] = useState(selectedCurrency || 'all');
    const [projectId, setProjectId] = useState(null);
    const [reportComponent, setReportComponent] = useState(<p className="w-full text-center">Report Appears Here</p>);

    const projectList = projects && projects?.map((d, i) => { return { _id: d._id, name: d.name } });

    const generateReportFunction = async () => {
        try {
            const body = {
                reportType,
                dateRange: period,
                status,
                client,
                project: 'all',
                currency,
                projectId
            }

            const { data: { success, result, message = 'something went wrong' } } = await generateReport(body);

            if (success) {
                setReport(result);
            } else {
                dispatch({
                    type: "ALERT-ON",
                    payload: {
                        severity: "error",
                        msg: message,
                    },
                });
            }
        } catch (e) {
            dispatch({
                type: "ALERT-ON",
                payload: {
                    severity: "error",
                    msg: e.message,
                },
            });
        }
    }

    useEffect(() => {
        setCurrency(selectedCurrency);
    }, [selectedCurrency])

    useEffect(() => {
        setReport(report);

        if (report) {
            switch (reportType) {
                case "revenue": {
                    setReportComponent(<InvoiceReportTable data={report?.data} paidAmount={report?.totalPaidAmount} pendingAmount={report?.totalPendingAmount} selectedCurrency={currency} />)
                    break;
                }

                case "expense": {
                    setReportComponent(<ExpenseReportTable data={report} selectedCurrency={currency} />)
                    break;
                }

                case "project": {
                    setReportComponent(<ProjectReportTable data={report} selectedCurrency={currency} />)
                    break;
                }

                default: {
                    setReportComponent(<p className="w-full text-center">Report Appears Here</p>)
                }
            }
        }
    }, [report]);

    return (
        <Page>
            <div className="container p-4">
                <div className="flex flex-row my-3">
                    <h1 className="font-bold text-[#333333]">Report Generator</h1>
                </div>
                <div className="bg-white rounded w-full h-auto shadow-sm border border-[#e8e8e8] p-3 flex flex-col">
                    <div className="w-full flex flex-row align-center">
                        <div className="flex flex-col w-1/5 p-1">
                            <span className="text-[12px] font-semibold text-[#888]">Report Type</span>
                            <select className="rounded-sm mb-0" onChange={(e) => setReportType(e.target.value)}>
                                <option value='revenue'>Revenue</option>
                                <option value='expense'>Expenses</option>
                                <option value='project'>Project</option>
                            </select>
                        </div>
                        {["expense", "revenue"].includes(reportType) && <div className="flex flex-col w-1/5 p-1">
                            <span className="text-[12px] font-semibold text-[#888]">Currency</span>
                            <select className="rounded-sm mb-0" onChange={(e) => setCurrency(e.target.value)}>
                                {currencyJSON.map((item, index) => (
                                    <option value={item.cc} selected={selectedCurrency === item.cc}>{item.cc}</option>
                                ))}
                            </select>
                        </div>
                        }
                        {["project"].includes(reportType) && <div className="flex flex-col w-1/5 p-1">
                            <span className="text-[12px] font-semibold text-[#888]">Project</span>
                            <select
                                name="project"
                                onChange={(e) => setProjectId(e.target.value)}
                                className='w-full'
                            >
                                <option value={'no-project'} selected disabled>Select a project</option>
                                {
                                    projectList && projectList?.map((d, i) => (
                                        <option value={d._id}>{d.name}</option>
                                    ))
                                }
                                {/* Render projects options here */}
                            </select>
                        </div>
                        }
                        {["expense", "revenue"].includes(reportType) && <div className="flex flex-col w-1/5 p-1">
                            <span className="text-[12px] font-semibold text-[#888]">Period</span>
                            <select className="rounded-sm mb-0" onChange={(e) => setPeriod(e.target.value)}>
                                <option value='all'>All</option>
                                <option value='7 days'>7 Days</option>
                                <option value='30 days'>30 Days</option>
                                <option value='60 days'>60 Days</option>
                                <option value='90 days'>90 Days</option>
                            </select>
                        </div>
                        }
                        {["expense", "revenue"].includes(reportType) && <div className="flex flex-col w-1/5 p-1">
                            <span className="text-[12px] font-semibold text-[#888]">Status</span>
                            <select className="rounded-sm mb-0" onChange={(e) => setStatus(e.target.value)}>
                                <option value='all'>All</option>
                                <option value='Paid'>Paid</option>
                                <option value='Pending'>Pending</option>
                            </select>
                        </div>
                        }
                        {["expense", "revenue"].includes(reportType) && <div className="flex flex-col w-1/5 p-1">
                            <span className="text-[12px] font-semibold text-[#888]">Client</span>
                            <select className="rounded-sm mb-0" onChange={(e) => setClient(e.target.value)}>
                                <option value='all'>All</option>
                            </select>
                        </div>
                        }
                        <div className="w-1/5 mt-4 p-1">
                            <button className="bg-purple text-white px-8 py-2 rounded-md border-none text-14 w-full" onClick={() => generateReportFunction()}>
                                Generate
                            </button>
                        </div>
                    </div>
                </div>


                <div className="mt-5 bg-white p-5 border border-[#e8e8e8] shadow-sm rounded">
                    {reportComponent && reportComponent}
                </div>
            </div>
        </Page >
    );
}

export default ReportGenerator;
