/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import ClientTable from "../../components/clientTable/ClientTable";
import "./ResourceManagement.scss";
import Page from "../Page";
import SearchClient from "../../components/searchClient/SearchClient";
import { useDispatch, useSelector } from "react-redux";
import {
  addResouurceToAgency,
  fetchProfileDetails,
} from "../../redux/profile/ProfileActions";
import ResourceTable from "../../components/ResourceTable/ResourceTable";
import { updateResource } from "../../services/api.services";

function ResourceManagement() {
  // state
  const [addResource, setAddResource] = useState(false);
  const [resourceInput, setResourceInput] = useState({
    id: "",
    name: "",
    email: "",
    type: "",
  });
  const [updateMode, setUpdateMode] = useState(false);

  // selector
  const id = useSelector((state) => state?.Profile?.decode?._id);
  const resources = useSelector((state) => state?.Profile?.profile?.resources);

  // dispatch
  const dispatch = useDispatch();

  // fetch all details
  const fetchDetails = () => {
    dispatch(fetchProfileDetails(id));
  };

  // ADD RESOURCE
  const addNewResource = async () => {
    dispatch(addResouurceToAgency(id, resourceInput));
    setResourceInput({
      ...resourceInput,
      id: "",
      name: "",
      email: "",
      type: "",
    });
    setAddResource(false);
  };

  const updateResourceById = async () => {
    try {
      const formData = {
        resourceID: resourceInput?.id,
        updatedData: {
          name: resourceInput?.name,
          email: resourceInput?.email,
          type: resourceInput?.type,
        },
      };
      const { data } = await updateResource(formData);
      if (data?.success) {
        dispatch({
          type: "ALERT-ON",
          payload: {
            severity: "success",
            msg: "Resource Updated",
          },
        });
        setResourceInput({
          ...resourceInput,
          id: "",
          name: "",
          email: "",
          type: "",
        });
        setAddResource(false);
        fetchDetails();
      }
    } catch (e) {
      dispatch({
        type: "ALERT-ON",
        payload: {
          severity: "error",
          msg: "Something went wrong",
        },
      });
    }
  };

  // USE EFFECT
  useEffect(() => {
    const close = (e) => {
      if (e.key === "Escape") {
        setAddResource(false);
        setUpdateMode(false);
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  });

  return (
    <>
      <Page>
        <div className="container Resource-Management">
          <div className="client-heading">
            <h1>Team Management</h1>
            <div className="Add-Resources">
              {addResource ? (
                <button
                  className="openCloseButton"
                  onClick={() => {
                    setAddResource(false);
                    setUpdateMode(false);
                  }}
                >
                  <i class="fa fa-times fa-2x" aria-hidden="true"></i>
                </button>
              ) : (
                <button
                  className="openCloseButton"
                  onClick={() => {
                    setAddResource(true);
                    setResourceInput({
                      ...resourceInput,
                      id: "",
                      email: "",
                      name: "",
                      type: "",
                    });
                  }}
                >
                  <i class="fa fa-plus" aria-hidden="true"></i>Add Resource
                </button>
              )}

              {addResource ? (
                  <div className="add-more">
                    <div className="input-box">
                      <p>Resource Name</p>
                      <input
                        placeholder="Name"
                        value={resourceInput.name}
                        onChange={(e) =>
                          setResourceInput({
                            ...resourceInput,
                            name: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="input-box">
                      <p>Email</p>
                      <input
                        placeholder="eg: sushant@company.com"
                        value={resourceInput.email}
                        onChange={(e) =>
                          setResourceInput({
                            ...resourceInput,
                            email: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="input-box">
                      <p>Designation</p>
                      <input
                        placeholder="eg: UX Designer, Developer"
                        value={resourceInput.type}
                        onChange={(e) =>
                          setResourceInput({
                            ...resourceInput,
                            type: e.target.value,
                          })
                        }
                      />
                    </div>
                    {updateMode ? (
                      <button className="submit" onClick={updateResourceById}>
                        Update
                      </button>
                    ) : (
                      <button className="submit" onClick={addNewResource}>
                        Save
                      </button>
                    )}
                  </div>
                ) : null
              }
            </div>
          </div>
          <div className="table-container">
            <ResourceTable
              fetch={fetchDetails}
              data={resources}
              openEditor={() => {
                setAddResource(true);
                setUpdateMode(true);
              }}
              setValue={(id, name, type, email) =>
                setResourceInput({
                  ...resourceInput,
                  id,
                  name,
                  email,
                  type,
                })
              }
            />
          </div>
        </div>
      </Page>
    </>
  );
}

export default ResourceManagement;
