import { Dialog } from "@material-ui/core";
import "./InvoiceDetails.scss";

import React from "react";

function InvoiceSender({
  handleCloseSender,
  openSender,
  handleSenderInput,
  senderDetails,
}) {
  return (
    <div>
      <Dialog
        onClose={handleCloseSender}
        aria-labelledby="customized-dialog-title"
        open={openSender}
        className="invoiceDetail-container"
      >
        <div className="form-container">
          <h2>Sender Details</h2>
          <p>Fill in the fields</p>
          <div className="forms">
            <input
              className="fullwidth"
              placeholder="Company/Sender Name"
              name="senderName"
              value={senderDetails.senderName}
              onChange={handleSenderInput}
            />
            <input
              className="halfwidth"
              placeholder="Email"
              name="email"
              value={senderDetails.email}
              onChange={handleSenderInput}
            />
            <input
              className="halfwidth"
              style={{ float: "right" }}
              placeholder="Phone Number"
              type="Number"
              name="phone"
              value={senderDetails.phone}
              onChange={handleSenderInput}
            />
            <input
              className="halfwidth"
              placeholder="Tax Registration Number"
              name="taxRegistration"
              value={senderDetails.taxRegistration}
              onChange={handleSenderInput}
            />
            <input
              className="halfwidth"
              style={{ float: "right" }}
              placeholder="Website"
              name="website"
              value={senderDetails.website}
              onChange={handleSenderInput}
            />
            <textarea
              className="fullwidth"
              placeholder="Address"
              name="address"
              value={senderDetails.address}
              onChange={handleSenderInput}
            />
          </div>
          <div className="button-container">
            <button className="close" onClick={handleCloseSender}>
              Close
            </button>
            <button
              className="save"
              onClick={() => {
                handleCloseSender();
              }}
            >
              Save
            </button>
          </div>
        </div>
      </Dialog>
    </div>
  );
}

export default InvoiceSender;
