import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

const ExpenseForm = ({ editExpense, onCancel, onCreateExpense, onUpdateExpense }) => {
  const { selectedCurrency, profile: { projects } } = useSelector((state) => state.Profile);
  const [formData, setFormData] = useState(editExpense || {
    date: '',
    category: '',
    description: '',
    amount: '',
    currency: selectedCurrency,
    receiptUrl: '',
    status: 'Approved',
    project: null,
  });

  const expenseCategories = [
    "Office Supplies",
    "Travel",
    "Utilities",
    "Marketing",
    "Software and Tools",
    "Freelancer Fees",
    "Client Meetings",
    "Rent and Office Space",
    "Insurance",
    "Training and Development",
    "Bank Fees",
    "Miscellaneous",
    "Others",
  ];

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value === 'no-project' ? null:value }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (formData.date && formData.category && formData.description && formData.amount && formData.currency) {
      if (formData._id) {
        onUpdateExpense({ updatedData: formData, expenseID: formData._id });
      } else {
        onCreateExpense(formData);
      }
      onCancel();
    }
  };

  const projectList = projects && projects?.map((d, i) => { return { _id: d._id, name: d.name } });

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-6 rounded-lg w-[480px]">
        <h2 className="text-xl font-bold mb-4">Expense Form</h2>
        <form onSubmit={handleSubmit} className="grid grid-cols-2 gap-4">
          <div>
            <label>
              Date
            </label>
            <input
              type="date"
              name="date"
              value={`${moment(formData?.date).format('YYYY-MM-DD')}`}
              onChange={handleFormChange}
              placeholder="Select date"
              required
              className='w-full'
            />
          </div>
          <div>
            <label>
              Category
            </label>
            <select
              name="category"
              value={formData.category}
              onChange={handleFormChange}
              required
              className='w-full'
            >
              <option value="">Select Category</option>
              {expenseCategories.map((category) => (
                <option key={category} value={category}>{category}</option>
              ))}
            </select>
          </div>
          <div>
            <label>
              Description
            </label>
            <input
              name="description"
              value={formData.description}
              onChange={handleFormChange}
              placeholder="Enter description"
              required
              className='w-full'
            />
          </div>
          <div>
            <label>
              Amount
            </label>
            <div className="relative">
              <span className="absolute top-[9px] left-2 text-12 text-black">{selectedCurrency}</span>
              <input
                type="number"
                name="amount"
                value={formData.amount}
                onChange={handleFormChange}
                placeholder="Enter amount"
                required
                className='w-full pl-9'
              />
            </div>
          </div>
          <div>
            <label>
              Receipt URL (optional)
            </label>
            <input
              name="receiptUrl"
              value={formData.receiptUrl}
              onChange={handleFormChange}
              placeholder="Enter receipt URL"
              className='w-full'
            />
          </div>
          <div>
            <label>
              Project (optional)
            </label>
            <select
              name="project"
              onChange={handleFormChange}
              className='w-full'
            >
              <option value={'no-project'}>No Project</option>
              {
                projectList && projectList?.map((d, i) => (
                  <option value={d._id} selected={d._id === formData?.project?._id}>{d.name}</option>
                ))
              }
              {/* Render projects options here */}
            </select>
          </div>
          <div className="col-span-2 flex justify-end">
            <button
              type="button"
              className="bg-gray-300 px-4 py-2 rounded-md mr-2"
              onClick={onCancel}
            >
              Cancel
            </button>
            <button type="submit" className="bg-purple text-white px-4 py-2 rounded-md">
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ExpenseForm;
