import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Checkbox from "@material-ui/core/Checkbox";

import "./ProjectTable.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteProjectFromAgency,
  fetchProjectSummary,
} from "../../redux/profile/ProfileActions";
import moment from "moment/moment";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
    padding: "8px 0",
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

export default function ProjectTable({
  data,
  setCurrentProject,
  setUpdateProject,
}) {
  const classes = useStyles();
  const [selected, setSelected] = React.useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [search, setSearch] = useState("");

  // SELECTOR
  const clients = useSelector((state) => state?.Profile?.profile?.clientID);
  const resources = useSelector((state) => state?.Profile?.profile?.resources);
  const id = useSelector((state) => state?.Profile?.decode?._id);

  // DISPATCH
  const dispatch = useDispatch();

  const columns = [
    // { field: "id", headerName: "Id" },
    { field: "name", headerName: "Name" },
    { field: "type", headerName: "Type" },
    { field: "status", headerName: "Status" },
    { field: "dueDate", headerName: "Due Date" },
    { field: "client", headerName: "Client" },
    { field: "budget", headerName: "Budget" },
    // { field: "view", headerName: "View" },
  ];

  const singleClick = (e, id) => {
    if (selected !== id) {
      setSelected(id);
    } else {
      setSelected("");
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // const isSelected = (id) => selected.indexOf(id) !== -1;

  // search in table
  const searchTable = (data) => {
    return data?.filter(
      (datas) =>
        datas?.name
          ?.toString()
          ?.toLowerCase()
          ?.includes(search.toString().toLowerCase()) ||
        datas?.status
          ?.toString()
          ?.toLowerCase()
          ?.includes(search.toString().toLowerCase()) ||
        datas?.organisation
          ?.toString()
          ?.toLowerCase()
          ?.includes(search.toString().toLowerCase())
    );
  };

  return (
    <div className={classes.root}>
      <div className="table-header">
        <h2>Projects</h2>
        <div className="control">
          <button
            disabled={!selected}
            onClick={() => {
              dispatch(
                deleteProjectFromAgency(id, {
                  projectIDs: [selected],
                })
              );
              dispatch(fetchProjectSummary(id));
            }}
          >
            <i class="fa fa-trash" aria-hidden="true"></i>
          </button>
          <div class="form">
            <span>
              <i class="fa fa-search" aria-hidden="true"></i>
            </span>
            <input
              placeholder="Search.."
              type="text"
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className={classes.paper}>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
          >
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox"> </TableCell>
                {columns.map((row, id) => (
                  <TableCell key={id} align={"left"} style={{ fontWeight: "bold" }}>
                    {row.headerName}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {data?.length > 0 &&
                searchTable(data)
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map((row, index) => {
                    const isItemSelected = row?._id === selected;

                    const labelId = row._id;

                    return (
                      <TableRow
                        hover
                        // onClick={(event) => singleClick(event, row._id)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        selected={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isItemSelected}
                            inputProps={{ "aria-labelledby": labelId }}
                            onChange={(event) => singleClick(event, row._id)}
                          />
                        </TableCell>
                        <TableCell
                          style={{ whiteSpace: "nowrap", fontSize: "13px" }}
                          align="left"
                        >
                          <button
                            className="table-button"
                            onClick={() => {
                              setCurrentProject(row._id);
                              setUpdateProject();
                            }}
                          >
                            {row.name}
                          </button>
                        </TableCell>
                        <TableCell align="left" style={{ fontSize: "13px" }}>
                          {row?.type?.map((item, i) => (
                            <p>{item}</p>
                          ))}
                        </TableCell>
                        <TableCell align="left" style={{ fontSize: "13px" }}>
                          <strong className={`uppercase rounded-sm p-2 ${row?.status?.toLowerCase() === 'completed' ? 'bg-[#D5F5E3]' : 'bg-[#FCF3CF]'}`}>{row.status}</strong>
                        </TableCell>
                        <TableCell align="left" style={{ fontSize: "13px" }}>
                          {moment(row?.dueDate).format('DD-MMM-YYYY')}
                        </TableCell>

                        <TableCell
                          align="left"
                          style={{ whiteSpace: "nowrap", fontSize: '13px' }}
                        >
                          {
                            clients?.filter(
                              (item) => item?._id === row.client
                            )[0]?.name
                          }
                        </TableCell>
                        <TableCell align="left" style={{ fontSize: "13px", fontWeight: "bold" }}>{row.currency} {row.budget?.toLocaleString("en-US")}</TableCell>
                      </TableRow>
                    );
                  })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={searchTable(data)?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </div>
    </div>
  );
}
