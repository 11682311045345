/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import "./NewProject.scss";
import status from "../../asset/icons/status.png";
import NewInvoice from "../newInvoice/NewInvoice";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchProjectSummary,
  modifyProject,
} from "../../redux/profile/ProfileActions";
import { getProjectById } from "../../services/api.services";
import { capitalize } from "../../utilities/CapitalizeLetter";
import { Link } from "react-router-dom";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import CheckListItems from "../checklistItems/CheckListItems";
// import TabList from '@material-ui/core/TabList';
// import timer from "../../asset/icons/timer.svg";

function InvoiceCard({ data }) {
  return (
    <div className="invoice-card">
      <div className="content">
        <p>
          <img src={status} alt="status" /> Invoice #{data?.invoiceNumber}
        </p>
      </div>
      {/* <button className="view-invoice">View Bill</button> */}
      <Link
        style={{ textDecoration: "none" }}
        className="view-invoice"
        to={`/invoice/${data?._id}`}
      >
        View
      </Link>
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

function UpdateProject(props) {
  // states
  const [newInvoice, setNewInvoice] = useState(false);
  // const [resources, setResources] = useState([]);
  const [type, setType] = useState([]);
  const [inputData, setInputData] = useState({
    status: "",
    name: "",
    organisation: "",
    phone: "",
    budget: "",
    client: "",
    dueDate: "",
    projectSteps: [],
  });
  const [loadData, setLoadData] = useState(null);

  const dispatch = useDispatch();

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // SELECTORS
  const id = useSelector((state) => state?.Profile?.decode?._id);
  // const { profile } = useSelector((state) => state.Profile);

  const variants = {
    visible: { opacity: 1, y: 0 },
    hidden: { opacity: 0.5, y: -24 },
  };

  // CREATE PROJECT
  const createProject = () => {
    const data = {
      updatedContent: {
        name: inputData?.name,
        status: inputData?.status,
        budget: inputData?.budget,
        type: type,
        dueDate: inputData?.dueDate,
        projectSteps: inputData?.projectSteps,
      },
      projectID: props?.currentProject,
    };
    setInputData({
      ...inputData,
      projectSteps: inputData?.projectSteps,
    });
    dispatch(modifyProject(id, data));
    dispatch(fetchProjectSummary(id));
  };

  // fetch data
  const fetchProject = async () => {
    try {
      const { data } = await getProjectById(props?.currentProject);
      if (data?.success) {
        setLoadData(data?.result);
        setInputData({
          ...inputData,
          currency: data?.result?.currency,
          status: data?.result?.status,
          name: data?.result?.name,
          budget: data?.result?.budget,
          dueDate: data?.result?.dueDate,
          client: data?.result?.client?.name,
          projectSteps: data?.result?.projectSteps,
        });
        setType(data?.result?.type);
      }
    } catch (e) {}
  };

  // use effect
  useEffect(() => {
    fetchProject();
  }, []);

  return (
    <>
      <div className="NewProject-container">
        <motion.div
          transition={{ duration: 0.25 }}
          initial="hidden"
          animate="visible"
          variants={variants}
          className="container"
        >
          <div className="left-update">
            <h4>Project Details {props?.currentProject}</h4>
            <div className="top-dropdown">
              <div className="input-box">
                <label>Due Date</label>
                <input
                  placeholder="Due Date"
                  type="text"
                  onFocus={(e) => (e.currentTarget.type = "date")}
                  onBlur={(e) => (e.currentTarget.type = "text")}
                  onChange={(e) =>
                    setInputData({ ...inputData, dueDate: e.target.value })
                  }
                  style={{ marginBottom: 0 }}
                  value={new Date(inputData?.dueDate).toDateString()}
                />
              </div>
              <div className="input-box">
                <label>Status</label>
                <select
                  className="input"
                  onChange={(e) =>
                    setInputData({ ...inputData, status: e.target.value })
                  }
                  value={inputData?.status}
                >
                  <option value="" defaultValue hidden>
                    Status
                  </option>
                  <option value="Pending Confirmation">
                    Pending Confirmation
                  </option>
                  <option value="Confirmed">Confirmed</option>
                  <option value="Not Started">Not Started</option>
                  <option value="In Progress">In Progress</option>
                  <option value="Hold">On Hold</option>
                  <option value="Completed">Completed</option>
                  <option value="Cancelled">Cancelled</option>
                </select>
              </div>
            </div>
            <form class="forms">
              <div className="input-box">
                <label>Project Name</label>
                <input
                  placeholder="Project Name"
                  className="project_name"
                  onChange={(e) =>
                    setInputData({ ...inputData, name: e.target.value })
                  }
                  value={inputData?.name}
                />
              </div>
              {/* <input placeholder="Client" className="client" /> */}
              <div className="input-box client">
                <label>Client</label>
                <input
                  placeholder="Selected Client"
                  disabled
                  className=""
                  type="text"
                  value={inputData?.client}
                />
              </div>
              <div className="input-box budget">
                <label>Project Budget</label>
                 <div className="relative">
                  <span className="absolute top-[9px] left-2 text-12 text-black">{inputData?.currency}</span>
                  <input
                    placeholder="Bugdet"
                    className="pl-9"
                    type="number"
                    onChange={(e) =>
                      setInputData({ ...inputData, budget: e.target.value })
                    }
                    value={inputData?.budget}
                  />
                </div>
              </div>
              <div className="input-box">
                <label>Choose Project Type</label>
                <select
                  className="input"
                  onChange={(e) => {
                    if (!type.includes(e.target.value)) {
                      setType([...type, e.target.value]);
                    }
                  }}
                >
                  <option value="" defaultValue hidden>
                    Project Type
                  </option>
                  <option value="Graphic Design">Graphic Design</option>
                  <option value="Branding">Branding</option>
                  <option value="Motion Graphics &amp; Animation">
                    Motion Graphics &amp; Animation
                  </option>
                  <option value="UI/UX Design">UI/UX Design</option>
                  <option value="Web Development">Web Development</option>
                  <option value="Android Development">
                    Android Development
                  </option>
                  <option value="iOS Development">iOS Development</option>
                  <option value="Backend Development">
                    Backend Development
                  </option>
                  <option value="FullStack Development">
                    FullStack Development
                  </option>
                  <option value="DevOps">DevOps</option>
                  <option value="Content Writing">Content Writing</option>
                  <option value="Digital Marketing">Digital Marketing</option>
                  <option value="Digital Strategy">Digital Strategy</option>
                  <option value="Social Media Marketing">
                    Social Media Marketing
                  </option>
                  <option value="SEO Optimization">SEO Optimization</option>
                  <option value="Digital Analytics &amp; Insights">
                    Digital Analytics &amp; Insights
                  </option>
                </select>
              </div>
              {type.length > 0 && (
                <div className="typeTag">
                  {type.map((item, i) => (
                    <span key={i}>
                      {item}{" "}
                      <i
                        onClick={() =>
                          setType([
                            ...type.filter((tag) => type.indexOf(tag) !== i),
                          ])
                        }
                        class="fa fa-times"
                        aria-hidden="true"
                      ></i>
                    </span>
                  ))}
                </div>
              )}
            </form>
            <h3 style={{ fontSize: "16px" }}>Project Resource</h3>

            {loadData?.resources?.length > 0 && (
              <div className="resourceTag">
                {loadData?.resources?.map((item, i) => (
                  <span key={i}>
                    {item?.name}
                    {/* <i
                      onClick={() =>
                        setResources([
                          ...resources.filter((tag) => tag !== item),
                        ])
                      }
                      class="fa fa-times"
                      aria-hidden="true"
                    ></i> */}
                  </span>
                ))}
              </div>
            )}
          </div>
          <div className="right">
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Checklist" {...a11yProps(0)} />
              <Tab label="Invoices" {...a11yProps(1)} />
            </Tabs>
            <TabPanel value={value} index={0}>
              <CheckListItems
                inputData={inputData}
                setInputData={setInputData}
              />
            </TabPanel>
            <TabPanel value={value} index={1}>
              {loadData?.invoices?.length > 0 &&
                loadData?.invoices
                  ?.slice(0, 3)
                  ?.map((item, index) => (
                    <InvoiceCard key={index} data={item} />
                  ))}
              {/* <InvoiceCard /> */}
              <button
                className="create-new-invoice"
                onClick={() => setNewInvoice(true)}
              >
                <i class="fa fa-plus" aria-hidden="true"></i> Create New Invoice
              </button>
            </TabPanel>

            <div className="btn-ctr">
              <button onClick={props.setNewProject} className="close">
                Close
              </button>
              <button className="save" onClick={createProject}>
                Update
              </button>
            </div>
          </div>
        </motion.div>
      </div>
      {newInvoice ? (
        <NewInvoice setNewInvoice={() => setNewInvoice(false)} />
      ) : null}
    </>
  );
}

export default UpdateProject;
