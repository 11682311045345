/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import moment from "moment";
import jsPDF from "jspdf";
import "./InvoicePage.scss";
// import logoInput from "../../asset/images/logo-input.png";
import send from "../../asset/images/send.png";
import receive from "../../asset/images/receive.png";
import { Snackbar } from "@material-ui/core";
import logo from "../../asset/logo/icon.png";
import loader from "../../asset/loaders/loader.svg";

// import axios from "axios";
import HelmetComponent from "../../components/helmetComponent/HelmetComponent";
import { useHistory } from "react-router-dom";
import { getInvoiceById } from "../../services/api.services";
import * as htmlToImage from "html-to-image";
import { useDispatch, useSelector } from "react-redux";

function InvoicePage(props) {
  const history = useHistory();
  const [snack, setSnack] = React.useState(false);
  const [snackMessage, setSnackMessage] = React.useState("");
  const handleSnack = (message) => {
    setSnack(true);
    setSnackMessage(message);
  };
const [loading, setLoading] = React.useState(false);
  // selector
  const isLoggedIn = useSelector((state) => state.Profile.isLoggedIn);

  // DISPATCH
  const dispatch = useDispatch();
  const createPDF = () => {
    handleSnack("Preparing to download...");
    let domElement = document.getElementById("capture");
    htmlToImage
      .toPng(domElement, { quality: 1 })
      .then(function (dataUrl) {
        handleSnack("Almost done...");
        const pdf = new jsPDF("", "mm", "a4");
        pdf.internal.scaleFactor = 50;
        pdf.addImage(
          dataUrl,
          "PNG",
          0,
          0,
          210,
          domElement.offsetHeight * 0.2645833333 > 297
            ? 297
            : domElement.offsetHeight * 0.2645833333
        );
        pdf.save(`${invoiceData.data._id}.pdf`);
        handleSnack(`${invoiceData.data._id}.pdf downloading...`);
        // pdf.output("dataurlnewwindow");
      })
      .catch(function (error) {
        setLoading(false);
        dispatch({
          type: "ALERT-ON",
          payload: {
            severity: "error",
            msg: "Something went wrong, please try again.",
          },
        });
      });
  };

  const exportPdf = () => {
    createPDF();
  };

  const [invoiceData, setInvoiceData] = React.useState({
    data: {},
    loading: true,
  });

  const loadData = async () => {
    try {
      const data = await getInvoiceById(props.match.params.id);
      if (data.data.data !== "No Invoice") {
        setInvoiceData({ data: data.data.data, loading: false });
        getBase64FromUrl(data?.data?.data?.invoiceLogo)
          .then((res) => setImage(res))
          .catch((e) => console.log(e));
      }
    } catch (err) {
      setInvoiceData({ data: err.message, loading: true });
    }
  };

  const [image, setImage] = useState("");
  React.useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const Status = (props) => {
    return (
      <>
        {props === "Pending" ? (
          <p className="pending">Pending</p>
        ) : (
          <p className="paid">Paid</p>
        )}
      </>
    );
  };

  const getBase64FromUrl = async (url) => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      };
    });
  };

  return (
    <>
      <HelmetComponent title="Invoice" />
      {invoiceData.loading ? (
        <div className="loader-container">
          <img src={loader} alt="icon" />
        </div>
      ) : (
        <div className="NewInvoice-container">
           {isLoggedIn && (
                <div className="heading">
                  <div className="leftHead">
                    <button onClick={() => history.goBack()}>
                      <i class="fa fa-arrow-left" aria-hidden="true"></i>
                    </button>
                    <h1>Invoice #{invoiceData.data.invoiceNumber}</h1>
                  </div>
                  <div className="rightHead">
                    <button
                      className="edit"
                      onClick={() =>
                        history.push(`/edit-invoice/${invoiceData.data._id}`)
                      }
                    >
                      Edit
                    </button>
                  </div>
                </div>
              )}
          <div className="invoice-container">
            <div className="left-invoice">
              <div className="invoice-box" id="capture">
                <div className="invoiceNum">
                <div className="header">
                    <div
                      className="logo-selector"
                      style={{ flexDirection: "column", alignItems: "flex-start" }}
                    >
                      {Status(invoiceData.data.status)}
                      <br/>
                      <h1>Invoice</h1>
                      <br/>
                      <p>
                        <span style={{ fontWeight: "bold", margin: "0" }}>
                          {invoiceData.data.invoiceNumber}
                        </span>
                        {/* <span style={{ fontWeight: "bold" }}>001</span> */}
                      </p>
                    </div>
                  </div>
                  <div className="header">
                    <img width="72px" src={image} alt="logo" />
                    {/* <h2>VIVID MYND</h2> */}
                  </div>
                 
                </div>

                <div className="invoiceNum">
                  <div className="header">
                    <div className="logo-selector">
                      <h4>FROM</h4>
                      <p className="company">
                        {invoiceData.data.senderDetails.senderName}
                      </p>

                      <p>{invoiceData.data.senderDetails.address}</p>
                      <p>{invoiceData.data.senderDetails.email}</p>
                      <p>{invoiceData.data.senderDetails.phone}</p>
                      <p>{invoiceData.data.senderDetails.website}</p>

                      {
                        invoiceData.data.senderDetails.taxRegistration && invoiceData.data.senderDetails.taxRegistration !== "" && <>
                        <p className="company" style={{ marginTop: "12px" }}>
                        Tax Registration Number
                      </p>
                      <p>{invoiceData.data.senderDetails.taxRegistration}</p></>
                      }
                      
                    </div>
                  </div>
                  <div className="header">
                    <div className="logo-selector">
                      <h4>TO</h4>
                      <p className="company">
                        {invoiceData.data.receiverDetails.receiverName}
                      </p>

                      <p>{invoiceData.data.receiverDetails.organisation}</p>
                      <p>{invoiceData.data.receiverDetails.country}</p>
                      <p>{invoiceData.data.receiverDetails.phone}</p>
                      <p>{invoiceData.data.receiverDetails.email}</p>
                      <p>{invoiceData.data.receiverDetails.address}</p>
                    </div>
                  </div>
                </div>

                <div className="invoiceNum">
                  <div className="header">
                    {/* <img src={send} alt="send" /> */}
                    <div
                      className="logo-selector"
                      style={{
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                    >
                      <label>Invoice Date</label>
                      <p>
                        {moment(invoiceData.data.creationDate).format(
                          "DD-MM-YYYY"
                        )}
                      </p>
                    </div>
                  </div>
                  <div className="header">
                    <div
                      className="logo-selector"
                      style={{
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                    >
                      <label>Due</label>
                      <p>
                        {moment(invoiceData.data.dueDate).format("DD-MM-YYYY")}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="table">
                  <div className="Header">
                    <div className="Title Description">Description</div>
                    <div className="Title Quantity">Quantity</div>
                    <div className="Title Rate">Rate</div>
                    <div className="Title Amount">Amount</div>
                    <div className="Title Edit"></div>
                  </div>
                  {/* <table>
                  <tr className="TableHeading">
                    <th>Description</th>
                    <th>Quantity</th>
                    <th>Rate</th>
                    <th>Amount</th>
                  </tr> */}

                  {invoiceData.data.itemDetails.map((item, i) => (
                    <div className="ItemMap" key={i}>
                      <div className="Body Description">
                        {item.productDescription}
                      </div>
                      <div className="Body Quantity">{item.qty}</div>
                      <div className="Body Amount">
                      {invoiceData?.data?.currency} {item.rate}
                      </div>
                      <div className="Body Edit">
                      {invoiceData?.data?.currency} {item.qty * item.rate}
                      </div>
                      <div className="Body Edit"></div>
                    </div>
                  ))}
                  {/* </table> */}
                  <div className="Bar"></div>

                  <div className="invoiceSummary">
                    <div className="title">
                      <h3>Invoice Summary</h3>
                      <table>
                        <tr>
                          <td style={{ textAlign: "left" }}>Subtotal</td>
                          <td>
                          {invoiceData?.data?.currency} {invoiceData.data.subTotal}
                          </td>
                        </tr>
                        <tr>
                          <td style={{ textAlign: "left" }}>Tax</td>
                          <td>
                          {invoiceData?.data?.currency}
                            {invoiceData.data.taxes.gst +
                              invoiceData.data.taxes.sgst}
                          </td>
                        </tr>
                        <tr>
                          <td style={{ textAlign: "left" }}>Total</td>
                          <td>
                          {invoiceData?.data?.currency}
                            {invoiceData.data.grandTotal}
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div className="note-box">
                    <p style={{ margin: "32px 0" }}>
                      {invoiceData.data.invoiceNote
                        .split(/\n/g)
                        .map((item, i) => (
                          <>
                            {item}
                            <br />
                          </>
                        ))}
                    </p>

                    {/* <h3>
                    <span>Email</span> : test@gmail.com
                  </h3> */}
                  </div>
                </div>
              </div>
            </div>

            <div className="right-invoice">
              <div className="input-row">
              <h2>Invoice Actions</h2>
                <div className="input-box">
                  <h4 style={{ cursor: "pointer" }} onClick={exportPdf}>
                    <i class="fa fa-download" aria-hidden="true"></i> Download as
                    PDF
                  </h4>
                </div>

                <div className="input-box">
                  <h4 style={{ cursor: "pointer" }} onClick={() => {
                      navigator.clipboard.writeText(invoiceData.data.invoiceLink);
                      handleSnack("Link copied to clipboard!");
                    }}>
                    <i class="fa fa-link" aria-hidden="true"></i> Copy Invoice Link
                  </h4>
                </div>
              </div>
            </div>
          </div>


          {
          snack && <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            open={snack}
            autoHideDuration={1200}
            onClose={() => setSnack(false)}
            message={snackMessage}
          />
        }
        </div>
      )}
    </>
  );
}

export default InvoicePage;
