import React, { useState } from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarDensitySelector
} from '@material-ui/data-grid';
import { Checkbox } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  dataGrid: {
    border: 'none',
    padding: '0 20px',
    letterSpacing: 'normal',
    '& .MuiDataGrid-columnHeader': {
      background: '#f9f9f9',
      fontWeight: '600 !important'
    }
  },
}));

const ExpenseTable = ({ expenses, onEditExpense, onDeleteExpense, selectedCurrency, isReportPage }) => {
  const classes = useStyles();
  const [selectedRows, setSelectedRows] = useState([]);

  // Handle row selection
  const handleRowSelection = (rowId) => {
    if (selectedRows.includes(rowId)) {
      setSelectedRows(selectedRows.filter((id) => id !== rowId));
    } else {
      setSelectedRows([...selectedRows, rowId]);
    }
  };

  // Map expenses data to rows for DataGrid
  const rows = expenses?.map((expense) => ({ id: expense._id, ...expense }));


  // Create columns configuration for DataGrid
  let columns = [
    { field: 'date', headerName: 'Date', flex: 1, valueFormatter: (params) => moment(params.value).format('DD-MMM-YYYY') },
    { field: 'amount', headerName: 'Amount', flex: 1, renderCell: (params) => (<div className='font-semibold'>{selectedCurrency} {params?.value?.toLocaleString()}</div>) },
    { field: 'project', headerName: 'Project', flex: 2, renderCell: (params) => (<div className='font-semibold'>{params?.value?.name || '-'}</div>) },
    { field: 'description', headerName: 'Description', flex: 1 },
    { field: 'category', headerName: 'Category', flex: 1 }
  ];

  if (!isReportPage) {
    columns = [
      { field: '', headerName: '', align:'center', width: 20, renderCell: (params) => <Checkbox color="primary" checked={selectedRows.includes(params.id)} onChange={() => handleRowSelection(params.id)} /> }, 
      ...columns, 
      {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      renderCell: (params) => (
        <div>
          <button className='text-purple px-6' onClick={() => onEditExpense(params.row)}>Edit</button>
        </div>
      ),
    }];
  }
  // Custom toolbar with filters and export options
  const CustomToolbar = () => (
    <GridToolbarContainer className='w-full flex flex-row items-center justify-between h-10 mb-5'>
      <h3>Expenses ({expenses?.length})</h3>
      <div className='flex items-center justify-center'>
        <GridToolbarDensitySelector />
        <GridToolbarExport />
        {!isReportPage && <button onClick={() => onDeleteExpense({ expenseID: selectedRows })} disabled={selectedRows.length === 0} className={`ml-2 ${selectedRows.length === 0 ? 'text-grey-50' : 'text-purple'} hover:bg-[#3f51b50a] py-1 px-1.5 rounded-sm`}>
          <i class="fa fa-trash" aria-hidden="true"></i> DELETE
        </button>}
      </div>
    </GridToolbarContainer>
  );

  return (
    <div className={`${classes.root} p-5 w-full h-auto mb-10 bg-white shadow-[0_1px_10px_0_rgba(0,0,0,0.02)] border rounded-md border-solid border-[#e8e8e8]`}>
      {
        expenses && expenses?.length ? <DataGrid
          rows={rows}
          columns={columns}
          autoHeight
          disableColumnMenu
          disableColumnSelector
          hideFooter
          components={{
            Toolbar: CustomToolbar,
          }}
          className={classes.dataGrid}
        /> : <div className='text-center'>
          <h4>Your expenses will appear here.</h4>
        </div>
      }
    </div>
  );
};

export default ExpenseTable;
