import React from "react";
import { Bar, BarChart, CartesianGrid, PieChart, Pie, Cell, Tooltip, Legend, XAxis, YAxis  } from "recharts";
import ExpenseTable from "../expenses/ExpenseList";

const ExpenseReportTable = ({ data, selectedCurrency }) => {
    const { totalExpenses, topSpendingCategories, expensesByProject, expensesByCategory, expenseList } = data;

    const pieColors = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042", "#FF5733", "#3D9970", "#C70039"];

    return (
        <div>
            <div className="bg-white p-5 border border-[#e8e8e8] shadow-sm w-full rounded">
                <h3 className="font-semibold text-16 text-grey-500 mb-2">Total Expenses</h3>
                <div className="flex flex-row items-center">
                    <span className="text-black text-24 font-bold">
                        {selectedCurrency} {totalExpenses?.toLocaleString()}
                    </span>
                </div>
            </div>

            <div className="flex flex-row items-center justify-evenly">
                <div className="bg-white p-5 border border-[#e8e8e8] shadow-sm w-1/3 rounded mt-5">
                    <h3 className="font-semibold text-16 text-grey-500 mb-2">Top Spending Categories</h3>
                    <BarChart width={300} height={250} data={topSpendingCategories}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="category" fontSize={12} />
                        <YAxis fontSize={12} />
                        <Tooltip />
                        <Legend formatter={(value, entry, index) => <span className="text-black text-12">Category</span>} />
                        <Bar dataKey="totalSpends" fill="#0088FE" radius={[6, 6, 0, 0]} barSize={30} />
                    </BarChart>
                </div>

                <div className="bg-white p-5 border border-[#e8e8e8] shadow-sm w-1/3 rounded mt-5 mx-3">
                    <h3 className="font-semibold text-16 text-grey-500 mb-2">Expenses by Project</h3>
                    <PieChart width={300} height={250}>
                        <Pie data={expensesByProject && Object.entries(expensesByProject)} dataKey="1" nameKey="0" cx="50%" cy="50%" innerRadius={50} fill="#8884d8" stroke="transparent">
                            {expensesByProject && Object.entries(expensesByProject)?.map((entry, index) => (
                                <Cell
                                    key={entry[0]}
                                    fill={pieColors[index % pieColors.length]}
                                />
                            ))}
                        </Pie>
                        <Tooltip />
                        <Legend formatter={(value, entry, index) => <span className="text-black text-12">{value}</span>} />
                    </PieChart>
                </div>

                <div className="bg-white p-5 border border-[#e8e8e8] shadow-sm w-1/3 rounded mt-5">
                    <h3 className="font-semibold text-16 text-grey-500 mb-2">Expenses by Category</h3>
                    <PieChart width={300} height={250}>
                        <Pie data={expensesByCategory && Object.entries(expensesByCategory)} dataKey="1" nameKey="0" cx="50%" cy="50%" innerRadius={50} fill="#8884d8" stroke="transparent">
                            {Object.entries(expensesByCategory)?.map((entry, index) => (
                                <Cell
                                    key={entry[0]}
                                    fill={pieColors[index % pieColors.length]}
                                />
                            ))}
                        </Pie>
                        <Tooltip />
                        <Legend formatter={(value, entry, index) => <span className="text-black text-12">{value}</span>} />
                    </PieChart>
                </div>
            </div>

            <div className="w-full rounded mt-5">
                <ExpenseTable expenses={expenseList} selectedCurrency={selectedCurrency} isReportPage={true} />
            </div>
        </div>
    );
};

export default ExpenseReportTable;
