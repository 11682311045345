import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Steps } from "intro.js-react";
import { ComposedChart, Bar, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";
import Page from "../Page";
import "intro.js/introjs.css";
import "./Dashboard.scss";
import active from "../../asset/images/active.png";
import paymentPending from "../../asset/images/payment-pending.svg";
import paymentDone from "../../asset/images/payment-done.svg";
import checkmark from "../../asset/icons/checkmark.svg";
import timer from "../../asset/icons/timer.svg";
import cancelled from "../../asset/icons/cancelled.svg";
import hold from "../../asset/icons/hold.svg";
import statsZerothCase from '../../asset/zero-state/statistics.png';
import projectZerothCase from '../../asset/zero-state/projects.png';

import NewProject from "../../components/newProject/NewProject";
import ToDoItemListWidget from '../../components/todoItems/ToDoItemListWidget';
import { fetchSalesGraphData, fetchToDoItems, addNewToDoItem } from "../../redux/profile/ProfileActions";
import { capitalize } from "../../utilities/CapitalizeLetter";

// component for card
function Card(props) {
  return (
    <div className="card">
      <div
        style={{ backgroundColor: `${props.background}` }}
        className="img-ctr"
      >
        <img src={props.img} alt="active" />
      </div>
      <div className="content">
        <p>{props.title}</p>
        <h2>{props.data}</h2>
      </div>
    </div>
  );
}

const statusIcon = {
  "In Progress": <img src={timer} alt="project status" />,
  Completed: <img src={checkmark} alt="project status" />,
  Hold: <img src={hold} alt="project status" />,
  Cancelled: <img src={cancelled} alt="project status" />,
};

// component for projects
function ProjectCard({ data }) {
  return (
    <div className="project-card">
      <h5>{data?.name}</h5>
      <div className="details">
        <div className="content">
          {statusIcon[data?.status]}
          <p>{capitalize(data?.status)}</p>
        </div>
        {capitalize(data?.status) !== "Completed" ?
          <>
            <div className="content">
              <p>
                , Due on <strong>{new Date(data?.dueDate).toDateString()}</strong>
              </p>
            </div>
          </> : null}

      </div>
    </div>
  );
}

function Dashboard() {
  // STATE
  const [newProject, setNewProject] = useState(false);
  const [newTodoItem, setNewTodoItem] = useState(false);

  // react-intro configurations starts
  const [stepsEnabled, setStepsEnabled] = useState(false);
  const [initialStep, setInitialStep] = useState(0);
  const [purchaseSuccess, setPurchaseSuccess] = useState(false);

  let onExit = () => {
    setStepsEnabled(false);
  };

  const [activeDuration, setActiveDuration] = useState(6);

  // react-intro config ends

  // SELECTORS
  const id = useSelector((state) => state?.Profile?.decode?._id);
  const { profile, projectSummary, invoiceSummary, salesGraphData, toDoItems, topClients, selectedCurrency } = useSelector((state) => state?.Profile);

  const [todoItemInput, setTodoItemInput] = useState({
    title: "",
    description: "",
    dueDate: "",
  })

  const onGoingProjectList = profile?.projects?.filter((data) => {
    return data.status !== 'Completed'
  });

  // DISPATCH
  const dispatch = useDispatch();

  const setGraphData = async (requestedDuration) => {
    setActiveDuration(requestedDuration);
    dispatch(fetchSalesGraphData(requestedDuration, id));
  };

  // USE EFFECT
  useEffect(() => {
    const close = (e) => {
      if (e.key === "Escape") {
        setNewProject(false);
      }
    };

    if (window.location.search.includes('status=TXN_SUCCESS')) {
      dispatch({
        type: "ALERT-ON",
        payload: {
          severity: "success",
          msg: "Purchase Successful!",
        },
      });
    }

    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  });

  useEffect(() => {
    dispatch(fetchToDoItems());
  }, []);

  return (
    <Page>
      <div className="container Dashboard">
        <div className="header">
          <h1>Business Overview</h1>
        </div>
        <div className="left">
          <div className="card-container">
            <Card
              title="Active projects"
              background="#dfffee"
              img={active}
              data={projectSummary?.activeProjects}
            />
            <Card
              title="Collected payment"
              background="#dfffee"
              img={paymentDone}
              data={`${selectedCurrency} ${invoiceSummary?.totalCollected}`}
            />
            <Card
              title="Pending payment"
              background="#ffede2"
              img={paymentPending}
              data={`${selectedCurrency} ${invoiceSummary?.totalPending}`}
            />
          </div>
          <div className="chart-container">
            <div className="controller">
              <div className="left-control">
                <h3>Revenue Trend</h3>
                {
                  Object.keys(salesGraphData).length ? <p>
                    <strong>{salesGraphData?.fromDate}</strong> to{" "}
                    <strong>{salesGraphData?.toDate}</strong>
                  </p> : ''
                }

              </div>
              {
                Object.keys(salesGraphData).length ?
                  <div className="right-control">
                    <button onClick={() => setGraphData(1)} className={activeDuration === 1 ? 'active' : ''}>1M</button>
                    <button onClick={() => setGraphData(6)} className={activeDuration === 6 ? 'active' : ''}>6M</button>
                    <button onClick={() => setGraphData(12)} className={activeDuration === 12 ? 'active' : ''}>1Y</button>
                    <button onClick={() => setGraphData(18)} className={activeDuration === 18 ? 'active' : ''}>1.5Y</button>
                    <button onClick={() => setGraphData(24)} className={activeDuration === 24 ? 'active' : ''}>2Y</button>
                  </div> : ''
              }
            </div>
            <div className="chart">
              {
                Object.keys(salesGraphData).length ?
                  <ResponsiveContainer width="100%" height={300}>
                    <ComposedChart
                      data={salesGraphData?.result}
                      margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" strokeWidth={0.5} />
                      <XAxis dataKey="date" />
                      <YAxis />
                      <Tooltip />
                      <Legend radius={[6, 6, 6, 6]} />

                      {/* Customized Bar */}
                      <Bar dataKey="amount" fill="url(#colorGradient)" radius={[6, 6, 0, 0]} barSize={30} />
                      {/* Customized Line */}
                      <Line type="monotone" dataKey="amount" stroke="#8048f4" strokeWidth={2} dot={false} />

                      {/* Color Gradient for Bar */}
                      <defs>
                        <linearGradient id="colorGradient" x1="0" y1="0" x2="0" y2="1">
                          <stop offset="5%" stopColor="#dbc9ff" stopOpacity={0.8} />
                          <stop offset="95%" stopColor="#dbc9ff" stopOpacity={0.2} />
                        </linearGradient>
                      </defs>
                    </ComposedChart>
                  </ResponsiveContainer> :
                  <div className="zeroth-case">
                    <img src={statsZerothCase} alt="sales graph data" />
                    <h3>No data to show.</h3>
                    <p>Sales statistics would load once you start creating invoices.</p>
                    <Link to="/invoice-management">
                      <button className="btn-ctr">
                        Create Invoice
                      </button>
                    </Link>
                  </div>
              }

            </div>
          </div>
        </div>
        <div className="right">
          <div className="todo-list">
            <div className="header">
              <h3>To-Do List</h3>

              <div className="popup-container">
                {newTodoItem ? (
                  <button
                    className="openCloseButton"
                    onClick={() => {
                      setNewTodoItem(false);
                    }}
                  >
                    <i class="fa fa-times" aria-hidden="true"></i>
                  </button>
                ) : (
                  <button
                    className="openCloseButton"
                    onClick={() => {
                      setNewTodoItem(true);
                    }}
                  >
                    <i class="fa fa-plus" aria-hidden="true"></i>
                  </button>
                )}

                {newTodoItem ? (
                  <div className="add-more">
                    <div className="input-box">
                      <p>Task Name</p>
                      <input
                        placeholder="Eg: Email Creation"
                        onChange={(e) =>
                          setTodoItemInput({
                            ...todoItemInput,
                            title: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="input-box">
                      <p>Description</p>
                      <input
                        placeholder="Eg: Email regarding new invoice"
                        onChange={(e) =>
                          setTodoItemInput({
                            ...todoItemInput,
                            description: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="input-box">
                      <p>Due Date</p>
                      <input
                        type="Date"
                        placeholder="Due Date"
                        onChange={(e) =>
                          setTodoItemInput({
                            ...todoItemInput,
                            dueDate: e.target.value,
                          })
                        }
                      />
                    </div>
                    <button
                      className="submit"
                      onClick={() => {
                        dispatch(addNewToDoItem(todoItemInput));
                        setNewTodoItem(false)
                      }}
                    >
                      Save
                    </button>
                  </div>
                ) : null}
              </div>
            </div>
            {
              toDoItems && <ToDoItemListWidget listData={toDoItems} />
            }
          </div>
          <div className="projects">
            <div className="header">
              <h3>Ongoing Projects ({onGoingProjectList?.length})</h3>
              <button onClick={() => setNewProject(true)}>
                <i class="fa fa-plus" aria-hidden="true"></i>
              </button>
            </div>
            <div className="projects-card-container">
              {
                onGoingProjectList?.length > 0 ?
                  onGoingProjectList?.slice(0, 5)?.map((item, index) => (
                    <ProjectCard key={index} data={item} />
                  )) : <div className="zeroth-case">
                    <img src={projectZerothCase} alt="sales graph data" />
                    <h3>No projects</h3>
                    <p>Recent project would display here</p>
                  </div>
              }
            </div>
            <Link
              style={{ textDecoration: "none" }}
              className="view-more"
              to="/project-management"
            >
              View more{" "}
              <i class="fa fa-long-arrow-right" aria-hidden="true"></i>
            </Link>
          </div>
        </div>
      </div>

      {newProject ? (
        <NewProject setNewProject={() => setNewProject(false)} />
      ) : null}
    </Page>
  );
}

export default Dashboard;
