/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Route, Switch, useHistory } from "react-router-dom";
import "./App.scss";
import Toast from "./components/toast/Toast";
import ClientManagement from "./pages/clientManagement/ClientManagement";
import ForgotPassword from "./pages/forgotPassword/ForgotPassword";
import Dashboard from "./pages/dashboard/Dashboard";
import EditInvoice from "./pages/editInvoice/EditInvoice";
import InvoiceManagement from "./pages/invoiceManagement/InvoiceManagement";
import InvoicePage from "./pages/invoicePage/InvoicePage";
import Login from "./pages/Login/Login";
import Plans from "./pages/plans/Plans";
import ProjectManagement from "./pages/projectManagement/ProjectManagement";
import Settings from "./pages/settings/Settings";
import SignUp from "./pages/signUp/SignUp";
import jwt_decode from "jwt-decode";
import { getAllPlans } from "./services/api.services";
import AuthRoute from "./auth/AuthRoute";
import ResourceManagement from "./pages/resourceManagement/ResourceManagement";
import moment from "moment";
import ProjectProposal from "./pages/projectProposal/ProjectProposal";
import ReportGenerator from "./pages/reports/ReportGenerator";
import ExpenseTracker from "./pages/expenses/ExpensesTracker";
import Success from "./pages/payments/success";
import Cancel from "./pages/payments/cancel";

function App() {
  // DISPATCH
  const dispatch = useDispatch();
  const history = useHistory();
  const [isExpired, setIsExpired] = useState(false);
  // LOGGING IN FROM EXISTING TOKEN
  const fetchToken = () => {
    const token = localStorage.getItem("SYNCHRO-TOKEN");
    if (token) {
      const decode = jwt_decode(token);
      dispatch({
        type: "LOG-IN",
        payload: token,
      });
      dispatch({
        type: "DECODE",
        payload: decode?.AGENCY,
      });
    }
  };

  const currentPlan = useSelector(
    (state) => state?.Profile?.profile?.planDetails
  );

  // FETCH PLANS
  const fetchPlans = async () => {
    try {
      const { data } = await getAllPlans();
      if (data?.success) {
        dispatch({
          type: "GET-PLANS",
          payload: data?.result,
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  // USE EFFECT
  useEffect(() => {
    fetchToken();
    fetchPlans();
    if (currentPlan) {
      setIsExpired(
        !moment(currentPlan?.expiryDate).isAfter(new Date().toISOString())
      );
    }

    if (isExpired) {
      dispatch({
        type: "ALERT-ON",
        payload: {
          severity: "error",
          msg: "Plan Expired! Renew from Settings Page.",
        },
      });
    }
  }, [currentPlan, isExpired]);

  window.addEventListener('error', e => {
    if (e.message) {
      const resizeObserverErrDiv = document.getElementById(
        'webpack-dev-server-client-overlay-div'
      );
      const resizeObserverErr = document.getElementById(
        'webpack-dev-server-client-overlay'
      );
      if (resizeObserverErr) {
        resizeObserverErr.setAttribute('style', 'display: none');
      }
      if (resizeObserverErrDiv) {
        resizeObserverErrDiv.setAttribute('style', 'display: none');
      }
    }
  });

  return (
    <div className="super-container">
      <BrowserRouter>
        <Switch>
          <Route path="/" exact component={Login} />
          <Route path="/forgot-password" exact component={ForgotPassword} />
          <Route path="/sign-up" exact component={SignUp} />
          <Route path="/plans" exact component={Plans} />
          <Route path="/success" exact component={Success} />
          <Route path="/cancel" exact component={Cancel} />
          <Route path="/invoice/:id" exact component={InvoicePage} />
          <AuthRoute path="/dashboard" exact component={Dashboard} />
          <AuthRoute path="/edit-invoice/:id" exact component={EditInvoice} />

          <AuthRoute
            path="/project-management"
            exact
            component={ProjectManagement}
          />
          <AuthRoute
            path="/invoice-management"
            exact
            component={InvoiceManagement}
          />
          <AuthRoute
            path="/client-management"
            exact
            component={ClientManagement}
          />
          <AuthRoute
            path="/resource-management"
            exact
            component={ResourceManagement}
          />
          <AuthRoute
            path="/reports"
            exact
            component={ReportGenerator}
          />
          <AuthRoute
            path="/expenses"
            exact
            component={ExpenseTracker}
          />
          <AuthRoute path="/settings" exact component={Settings} />
          <AuthRoute path="/proposal" exact component={ProjectProposal} />
        </Switch>
        <Toast />
      </BrowserRouter>
    </div>
  );
}

export default App;
