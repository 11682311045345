import React, { useState } from "react";
import { motion } from "framer-motion";
import "./NewProject.scss";
import NewInvoice from "../newInvoice/NewInvoice";
import { useDispatch, useSelector } from "react-redux";
import {
  addProjectToAgency,
  fetchProjectSummary,
} from "../../redux/profile/ProfileActions";
import CheckListItems from "../checklistItems/CheckListItems";

function NewProject(props) {
  // SELECTORS
  const id = useSelector((state) => state?.Profile?.decode?._id);
  const { profile, selectedCurrency } = useSelector((state) => state.Profile);

  // states
  const [newInvoice, setNewInvoice] = useState(false);
  const [resources, setResources] = useState([]);
  const [type, setType] = useState([]);
  const [inputData, setInputData] = useState({
    status: "",
    name: "",
    organisation: "",
    phone: "",
    budget: "",
    client: "",
    dueDate: "",
    projectSteps: []
  });

  const dispatch = useDispatch();

  const variants = {
    visible: { opacity: 1, y: 0 },
    hidden: { opacity: 0.5, y: -24 },
  };

  // CREATE PROJECT
  const createProject = async () => {
    const data = {
      ...inputData,
      type,
      resources,
      agency: id,
      projectSteps: JSON.parse(localStorage.getItem("checklistItems")),
      currency: selectedCurrency
    };
    const resp = await dispatch(addProjectToAgency(id, data));
    if (resp) {
      props.setNewProject();
      dispatch(fetchProjectSummary(id));
      localStorage.setItem("checklistItems", "[]");
    }
  };

  return (
    <>
      <div className="NewProject-container">
        <motion.div
          transition={{ duration: 0.25 }}
          initial="hidden"
          animate="visible"
          variants={variants}
          className="container-new"
        >
          <div className="left">
            <h3>Project Details</h3>
            <div className="top-dropdown">
              {/* <p>Fill in the fields</p> */}
              <div className="input-box">
                <label>Due Date</label>
                <input
                  placeholder="Due Date"
                  type="text"
                  onFocus={(e) => (e.currentTarget.type = "date")}
                  onBlur={(e) => (e.currentTarget.type = "text")}
                  onChange={(e) =>
                    setInputData({ ...inputData, dueDate: e.target.value })
                  }
                  style={{ marginBottom: 0 }}
                />
              </div>
              <div className="input-box">
                <label>Status</label>
                <select
                  className="input"
                  onChange={(e) =>
                    setInputData({ ...inputData, status: e.target.value })
                  }
                >
                  <option value="" defaultValue hidden>
                    Status
                  </option>
                  <option value="Pending Confirmation">Pending Confirmation</option>
                  <option value="Confirmed">Confirmed</option>
                  <option value="Not Started">Not Started</option>
                  <option value="In Progress">In Progress</option>
                  <option value="Hold">On Hold</option>
                  <option value="Completed">Completed</option>
                  <option value="Cancelled">Cancelled</option>
                </select>
              </div>
            </div>
            <form class="forms">
              <div className="input-box">
                <label>Project Name</label>
                <input
                  placeholder="Project Name"
                  className="project_name"
                  onChange={(e) =>
                    setInputData({ ...inputData, name: e.target.value })
                  }
                />
              </div>
              {/* <input placeholder="Client" className="client" /> */}
              <div className="input-box client">
                <label>Choose Client</label>
                <select
                  className=""
                  onChange={(e) =>
                    setInputData({ ...inputData, client: e.target.value })
                  }
                >
                  <option value="" defaultValue hidden>
                    Client
                  </option>
                  {profile?.clientID?.map((item, index) => (
                    <option key={index} value={item?._id}>
                      {item?.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="input-box budget">
                <label>Project Budget</label>
                <div className="relative">
                  <span className="absolute top-[9px] left-2 text-12 text-black">{selectedCurrency}</span>
                  <input
                    placeholder="Bugdet"
                    className="pl-9"
                    type="number"
                    onChange={(e) =>
                      setInputData({ ...inputData, budget: e.target.value })
                    }
                  />
                </div>
              </div>
              <div className="input-box">
                <label>Choose Project Type</label>
                <select
                  className="input"
                  onChange={(e) => {
                    if (!type.includes(e.target.value)) {
                      setType([...type, e.target.value]);
                    }
                  }}
                >
                  <option value="" defaultValue hidden>
                    Project Type
                  </option>
                  <option value="Graphic Design">Graphic Design</option>
                  <option value="Branding">Branding</option>
                  <option value="Motion Graphics &amp; Animation">Motion Graphics &amp; Animation</option>
                  <option value="UI/UX Design">UI/UX Design</option>
                  <option value="Web Development">Web Development</option>
                  <option value="Android Development">Android Development</option>
                  <option value="iOS Development">iOS Development</option>
                  <option value="Backend Development">Backend Development</option>
                  <option value="FullStack Development">FullStack Development</option>
                  <option value="DevOps">DevOps</option>
                  <option value="Content Writing">Content Writing</option>
                  <option value="Digital Marketing">Digital Marketing</option>
                  <option value="Digital Strategy">Digital Strategy</option>
                  <option value="Social Media Marketing">Social Media Marketing</option>
                  <option value="SEO Optimization">SEO Optimization</option>
                  <option value="Digital Analytics &amp; Insights">Digital Analytics &amp; Insights</option>
                </select>
              </div>
              {type.length > 0 && (
                <div className="typeTag">
                  {type.map((item, i) => (
                    <span key={i}>
                      {item}{" "}
                      <i
                        onClick={() =>
                          setType([
                            ...type.filter((tag) => type.indexOf(tag) !== i),
                          ])
                        }
                        class="fa fa-times"
                        aria-hidden="true"
                      ></i>
                    </span>
                  ))}
                </div>
              )}

              <div className="input-box">
                <label>Choose Team Members</label>
                <select
                  className="input"
                  onChange={(e) => {
                    if (!resources.includes(e.target.value)) {
                      setResources([...resources, e.target.value]);
                    }
                  }}
                >
                  <option value="" defaultValue hidden>
                    Resource
                  </option>
                  {profile?.resources?.map((item, index) => (
                    <option key={index} value={item?._id}>
                      {item?.name}
                    </option>
                  ))}
                  {/* <option value="Resource 1">Resource 1</option>
                <option value="Resource 2">Resource 2</option> */}
                </select>
              </div>
            </form>
            {resources.length > 0 && (
              <div className="resourceTag">
                {resources.map((item, i) => (
                  <span key={i}>
                    {
                      profile?.resources?.filter((data) => data._id === item)[0]
                        ?.name
                    }
                    <i
                      onClick={() =>
                        setResources([
                          ...resources.filter((tag) => tag !== item),
                        ])
                      }
                      class="fa fa-times"
                      aria-hidden="true"
                    ></i>
                  </span>
                ))}
              </div>
            )}
          </div>
          <div className="right">
            <h3>Checklist</h3>

            <CheckListItems inputData={inputData} setInputData={setInputData}/>

            <div className="btn-ctr">
              <button onClick={props.setNewProject} className="close">
                Close
              </button>
              <button className="save" onClick={() => profile?.clientID !== undefined && profile?.clientID?.length ? createProject() : dispatch({
                type: "ALERT-ON",
                payload: {
                  severity: "error",
                  msg: "Add a client first",
                },
              })}>
                Save
              </button>
            </div>
          </div>
        </motion.div>
      </div>
      {newInvoice ? (
        <NewInvoice setNewInvoice={() => setNewInvoice(false)} />
      ) : null}
    </>
  );
}

export default NewProject;
