import { callBackend } from "./http.service";

// LOGIN
export const login = async (data) => {
  return await callBackend("POST", "agency/login", data);
};

export const forgotPasswordEmailTrigger = async (data) => {
  return await callBackend("POST", "agency/forgotPassword", data);
};

export const changePassword = async (data, token) => {
  return await callBackend("POST", "agency/changePassword", data, {
    Authorization: `Bearer ${token}`,
  });
};

// SIGN UP
export const signup = async (data) => {
  return await callBackend("POST", "agency/createAgencyProfile", data);
};

// ACCOUNT ACTIVATION
export const accountActivationEmailTrigger = async (data) => {
  return await callBackend("POST", "agency/sendAccountActivationLink", data);
};

export const activateAccount = async (data, token) => {
  return await callBackend("POST", "agency/activateAccount", data, {
    Authorization: `Bearer ${token}`,
  });
};

// LOGGED IN USER DATA
export const currentUserDetails = async (id) => {
  return await callBackend("GET", `agency/getAgencyProfileById/${id}`);
};

// CLIENT LIST SEARCH
export const clientSearch = async (term) => {
  return await callBackend("GET", `client/getClientListByTerm/${term}`);
};

// ADD CLIENT By ID
export const addClient = async (id) => {
  return await callBackend("POST", `agency/addClient/${id}`);
};

// CREATE CLIENT
export const createClientProfile = async (data) => {
  return await callBackend("POST", `client/createClientProfileByAgency`, data);
};

// REMOVE CLIENT BY ID
export const removeClient = async (id) => {
  return await callBackend("POST", `agency/removeClient/${id}`);
};

// FETCH ALL PLANS
export const getAllPlans = async () => {
  return await callBackend("GET", "plans/getAllPlans");
};

// CREATE PLAN ORDER
export const initPurchase = async (data) => {
  return await callBackend("POST", "plans/initPurchasePlan", data);
};

// ADD RESOURCE
export const addResource = async (data) => {
  return await callBackend("POST", "agency/addResource", data);
};

// DELETE RESOURCE
export const deleteResource = async (data) => {
  return await callBackend("DELETE", "agency/deleteResource", data);
};

// UPDATE RESOURCE
export const updateResource = async (data) => {
  return await callBackend("PUT", "agency/updateResource", data);
};

// CREATE NEW PROJECT
export const createProject = async (data) => {
  return await callBackend("POST", "project/createProject", data);
};

// DELETE PROJECT
export const deleteProject = async (data) => {
  return await callBackend("DELETE", "project/deleteProject", data);
};

// CREATE INVOICE
export const createInvoice = async (data) => {
  return await callBackend("POST", "invoice/createNewInvoice", data);
};

// GET INVOICE BY ID
export const getInvoiceById = async (id) => {
  return await callBackend("GET", `invoice/getInvoiceById/${id}`);
};

// UPDATE INVOICE BY ID
export const updateInvoiceById = async (id, data) => {
  return await callBackend("PUT", `invoice/updateInvoiceById/${id}`, data);
};

// GET ALL INVOICES BY USER ID
export const getAllInvoices = async (data) => {
  return await callBackend("POST", "invoice/getAllAgencyInvoices", data);
};

// GET PROJECT BY ID
export const getProjectById = async (id) => {
  return await callBackend("GET", `project/getProjectDetailsByID/${id}`);
};

// UPDATE PROJECT BY ID
export const updateProjectById = async (data) => {
  return await callBackend("PUT", "project/modifyProject", data);
};

// UPDATE AGENCY PROFILE
export const updateAgencyById = async (data) => {
  return await callBackend("PUT", "agency/updateAgencyProfile", data);
};

// DELETE INVOICE BY ID
export const deleteInvoiceById = async (id) => {
  return await callBackend("DELETE", `invoice/deleteInvoiceById/${id}`);
};

// INVOICE SUMMARY
export const getInvoiceSummary = async (id) => {
  return await callBackend("GET", `invoice/getInvoicePageSummary/${id}`);
};

// PROJECT SUMMARY
export const getProjectSummary = async (id, currency) => {
  return await callBackend("GET", `project/getProjectsSummary/${id}`);
};

// DASHBOARD SALES REPORT
export const getSalesReportGraphData = async (requestedDuration, agencyID, currency) => {
  return await callBackend(
    "GET",
    `invoice/getSalesGraphData/${agencyID}/${requestedDuration}`
  );
};

export const getAgencyLastInvoiceNumber = async (agencyID) => {
  return await callBackend(
    "GET",
    `invoice/getAgencyLastInvoiceNumber/${agencyID}`
  );
};

export const getTopClients = async () => {
  return await callBackend("GET", `agency/report/topClients`);
};

// todo items
export const getTodoItems = async () => {
  return await callBackend("GET", `todoList/getToDoItemsByAgencyID`);
};

export const createTodoItem = async (data) => {
  return await callBackend("POST", `todoList/createToDoItem`, data);
};

export const modifyTodoItem = async (data) => {
  return await callBackend("PUT", `todoList/modifyToDoItem`, data);
};

export const razorpayCreateOrder = async (amount, orderId) => {
  var data = {
    amount: String(amount),
    orderId,
  };

  return await callBackend("POST", `pay/razorpay/rzp-createOrder`, data);
};

// coupon code
export const applyCouponCode = async (couponCode) => {
  return await callBackend("GET", `coupons/applyCoupon/${couponCode}`);
};

export const redeemCouponCode = async (data) => {
  return await callBackend("POST", `coupons/redeemCoupon`, data);
};

export const razorpayCallBack = async (
  customerId,
  orderId,
  planId,
  customerType,
  paymentAgenda,
  isYearlyPayment,
  rzpOrderId,
  paymentId,
  rzpSignature
) => {
  var data = {
    customerId,
    orderId,
    planId,
    customerType,
    paymentAgenda,
    isYearlyPayment,
    rzpOrderId,
    paymentId,
  };
  //
  return await callBackend("POST", `pay/razorpay/callback`, data, {
    "x-razorpay-signature": rzpSignature,
  });
};

// proposals

export const generateProposal = async (body) => {
  return await callBackend("POST", "project/generateProposal", body);
};

export const redeemProposalToken = async () => {
  return await callBackend("POST", "project/redeemProposalToken");
}

export const getProposalLimit = async () => {
  return await callBackend("GET", "project/getProposalLimits");
}

// reports
export const generateReport = async (body) => {
  const {reportType} = body;
  let endpoint = 'getRevenueReport';
  switch (reportType) {
    case "expense" : {
      endpoint = "getExpenseReport";
      break;
    }

    case "project": {
      endpoint = "getProjectReport";
      break;
    }

    default: {
      endpoint = 'getRevenueReport'
      break;
    }
  }

  return await callBackend("POST", `reports/${endpoint}`, body);
}

// expenses
export const getExpenses = async () => {
  return await callBackend("GET", "expenses/getAllExpenses");
}

export const createExpense = async (body) => {
  return await callBackend("POST", "expenses/createNewExpense", body);
}

export const updateExpense = async (body) => {
  return await callBackend("PUT", "expenses/updateExpense", body);
}

export const deleteExpense = async (body) => {
  return await callBackend("DELETE", "expenses/deleteExpense", body);
}

export const getExpenseSummary = async () => {
  return await callBackend("GET", "expenses/getExpenseSummary");
}

// stripe
export const stripePay = async (amount, orderId, metaInformation) => {
  var data = {
    amount,
    orderId,
    metaInformation
  };

  return await callBackend("POST", `pay/stripe/payment`, data);
};