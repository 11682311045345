/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./PackageUpgrade.scss";

function PackageUpgrade({ closeModal, message, CTA }) {

  // USEEFFECT
  useEffect(() => {
    const close = (e) => {
      if (e.key === "Escape") {
        closeModal();
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  });

  return (
    <div className="AddNew-Container">
      <div className="box">
            <h2>Limit Reached!</h2>
            <p>
                {message}
            </p>
            <div className="button-ctr">
            <button className="Close" onClick={closeModal}>
                Close
            </button>
            <Link to={CTA.link}>
            <button className="Save">{CTA.CTAText}</button>
            </Link>
        </div>
      </div>
    </div>
  );
}

export default PackageUpgrade;
