/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addClient, clientSearch, createClientProfile } from "../../services/api.services";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import "./SearchClient.scss";
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div>{children}</div>
      )}
    </div>
  );
}
function SearchClient({ closeModal, fetch }) {
  // STATE
  const [search, setSearch] = useState("");
  const [clientDetails, setClientDetails] = useState({ name: null, email: null })
  const [response, setResponse] = useState([]);
  const [notFound, setNotFound] = useState([]);

  // SELECTOR
  const clientID = useSelector(
    (state) => state.Profile?.profile?.clientID
  )?.map((item) => item?._id);

  // REDUX DISPATCH
  const dispatch = useDispatch();

  // SUBMIT
  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      const { data } = await clientSearch(search);
      if (data?.status && data?.result?.length > 0) {
        setResponse(data?.result);
        setNotFound([]);
      } else {
        setNotFound([search]);
        setResponse([]);
      }
    } catch (e) { }
  };

  // CREATE CLIENT
  const createClient = async (e) => {
    e.preventDefault();
    let form = document.getElementById("add-client-form");
    try {
      const { data } = await createClientProfile(clientDetails);
      if (data?.success) {
        await addClientHandler(data?.id);

        setClientDetails({name: null, email: null})
        form.reset();
      } else {
        dispatch({
          type: "ALERT-ON",
          payload: {
            severity: "error",
            msg: data?.message || data?.errors[0],
          },
        });
        setClientDetails({name: null, email: null})
        form.reset();
      }
    } catch (e) { }
  };

  // ADDING CLIENT
  const addClientHandler = async (id) => {
    try {
      const resp = await addClient(id);
      if (resp?.data?.success) {
        dispatch({
          type: "ALERT-ON",
          payload: {
            severity: "success",
            msg: resp?.data?.message,
          },
        });
        fetch();
      } else {
        dispatch({
          type: "ALERT-ON",
          payload: {
            severity: "error",
            msg: resp?.data?.message,
          },
        });
      }
    } catch (e) { }
  };

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // USEEFFECT
  useEffect(() => {
    const close = (e) => {
      if (e.key === "Escape") {
        closeModal();
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  });

  return (
    <div className="AddNew-Container">
      <div className="box">
        <h2 className="heading"><i class="fa fa-user-plus"></i> New Client</h2>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Add" {...a11yProps(0)} className="tab" />
          <Tab label="Create & Add" {...a11yProps(1)} className="tab" />
        </Tabs>
        <TabPanel value={value} index={0}>
          <div>
            <form className="Forms" onSubmit={(e) => submitHandler(e)}>
              <label>Search or invite your client</label>
              <input
                type="text"
                placeholder="Search for client e-mail"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              <span className="hit-enter">Hit Enter</span>
              {response.length > 0 &&
                response?.map((item, key) => (
                  <div
                    className="Response"
                    key={key}
                    onClick={() => addClientHandler(item?._id)}
                  >
                    <p>
                      {item?.name}, <span>{item?.organizationName}</span>
                    </p>

                    {clientID?.includes(item._id) && (
                      <p style={{ color: "#5048f4" }}>
                        <i class="fa fa-check" aria-hidden="true"></i> Already Added
                      </p>
                    )}
                  </div>
                ))}
              {notFound.length > 0 &&
                notFound?.map((item, key) => (
                  <div className="Response">
                    <p>
                      {item}, Not Found. <a href="#">Send an invite</a>
                    </p>
                  </div>
                ))}
            </form>
          </div>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <form className="Forms" onSubmit={(e) => createClient(e)} id="add-client-form">
            <label>Client Name</label>
            <input
              type="text"
              placeholder="Eg: Sushant Kumar"
              defaultValue={clientDetails?.name}
              value={clientDetails?.name}
              onChange={(e) => setClientDetails(prev => ({ ...prev, name: e.target.value }))}
            />
            <label>Client Email</label>
            <input
              type="email"
              placeholder="Eg: sushant@company.com"
              defaultValue={clientDetails?.email}
              value={clientDetails?.email}
              onChange={(e) => setClientDetails(prev => ({ ...prev, email: e.target.value }))}
            />
            <div className="button-ctr">
              <button className="Save" type="submit">Create Client</button>
            </div>
          </form>
        </TabPanel>
      </div>
    </div>
  );
}

export default SearchClient;
