/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Checkbox from "@material-ui/core/Checkbox";

import "./ClientTable.scss";
import { removeClient } from "../../services/api.services";
import { useDispatch } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
    padding: "8px 0",
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

export default function ClientTable({ data, fetch }) {
  // stated
  const classes = useStyles();
  const [selected, setSelected] = React.useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [search, setSearch] = useState("");

  // dispatch
  const dispatch = useDispatch();

  const columns = [
    // { field: "id", headerName: "Id" },
    { field: "name", headerName: "Name" },
    // { field: "phone", headerName: "Phone" },
    { field: "email", headerName: "Email" },
    { field: "organisation", headerName: "Organization" },
    // { field: "projectsActive", headerName: "Projects Active" },
  ];

  const rows = Array.from({
    length: 20,
  }).map(function (_, i) {
    return {
      id: i,
      name: "Client Name",
      phone: "+91 9988776655",
      email: "client@gmail.com",
      organisation: "XYZ",
      projectsActive: 4,
    };
  });

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const singleClick = (e, id) => {
    if (selected !== id) {
      setSelected(id);
    } else {
      setSelected("");
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  // const remove client
  const removeClientById = async () => {
    try {
      const { data } = await removeClient(selected);
      if (data?.success) {
        dispatch({
          type: "ALERT-ON",
          payload: {
            severity: "success",
            msg: data?.message,
          },
        });
        fetch();
      }
    } catch (e) {
      console.log(e);
    }
  };

  // search in table
  const searchTable = (data) => {
    return data?.filter(
      (datas) =>
        datas?.name
          ?.toString()
          ?.toLowerCase()
          ?.includes(search.toString().toLowerCase()) ||
        datas?.email
          ?.toString()
          ?.toLowerCase()
          ?.includes(search.toString().toLowerCase()) ||
        datas?.organizationName
          ?.toString()
          ?.toLowerCase()
          ?.includes(search.toString().toLowerCase())
    );
  };

  return (
    <div className={classes.root}>
      <div className="table-header">
        <h2>Total : {data?.length}</h2>
        <div className="control">
          <button onClick={removeClientById} disabled={!selected}>
            <i class="fa fa-trash" aria-hidden="true"></i>
          </button>
          {/* <button>
            <i class="fa fa-check-circle" aria-hidden="true"></i>
          </button> */}
          <div class="form">
            <span>
              <i class="fa fa-search" aria-hidden="true"></i>
            </span>
            <input
              placeholder="Search.."
              type="text"
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className={classes.paper}>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
          >
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox"> </TableCell>
                {columns.map((row, id) => (
                  <TableCell key={id} align={"left"}>
                    {row.headerName}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {data?.length > 0 &&
                searchTable(data)
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = row?._id === selected;
                    const labelId = row?._id;

                    return (
                      <TableRow
                        hover
                        // onClick={(event) => singleClick(event, row?._id)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        selected={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isItemSelected}
                            inputProps={{ "aria-labelledby": labelId }}
                            onChange={(event) => singleClick(event, row?._id)}
                          />
                        </TableCell>
                        {/* <TableCell
                          component="th"
                          id={index}
                          scope="row"
                          padding="none"
                          align="left"
                        >
                          {row?._id.slice(0, 5) + "..." + row?._id.slice(19)}
                        </TableCell> */}
                        <TableCell align="left">{row?.name}</TableCell>
                        {/* <TableCell align="center">{row?.phone}</TableCell> */}
                        <TableCell align="left">{row?.email}</TableCell>
                        <TableCell align="left">
                          {row?.organizationName}
                        </TableCell>
                      </TableRow>
                    );
                  })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={searchTable(data)?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </div>
    </div>
  );
}
