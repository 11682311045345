import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import success from '../../asset/icons/payment-success.svg';

function Success() {
    const history = useHistory();
    const [countdown, setCountdown] = useState(10);

    useEffect(() => {
        const timer = setTimeout(() => {
            if (countdown > 0) {
                setCountdown(countdown - 1);
            } 
        }, 1000);

        return () => clearTimeout(timer);
    }, [countdown]);

    useEffect(() => {
        if (countdown === 0) {
            history.push('/dashboard');
        }
    }, [countdown]);

    return (
        <div className="Login-Container">
            <div className="Form-Container flex items-center justify-center flex-col">
                <img src={success} alt="payment-success" className="h-[80px] w-[80px]" />
                <h3 className="text-20">Payment Successful!</h3>
                <span className="text-14">Redirecting in {countdown}sec...</span>
            </div>
        </div>
    );
}

export default Success;
