import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { forgotPasswordEmailTrigger, changePassword } from "../../services/api.services";
import "./ForgotPassword.scss";
import logo from "../../asset/logo/logo.svg";

function ForgotPassword(props) {
  // HISTORY
  const history = useHistory();
  // DISPATCH
  const dispatch = useDispatch();
  // STATE
  const [email, setEmail] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [reNewPassword, setReNewPassword] = useState("");
  const [resetPasswordState, setResetPasswordState] = useState(false);
  const [token, setToken] = useState(null);

  const triggerForgotPasswordEmail = async () => {
    try {
      const { data } = await forgotPasswordEmailTrigger({ email });
      if (!data?.success) {
        dispatch({
          type: "ALERT-ON",
          payload: {
            severity: "error",
            msg: data?.message,
          },
        });
      } else {
        setEmail("");
        dispatch({
          type: "ALERT-ON",
          payload: {
            severity: "success",
            msg: data?.message,
          },
        });
      }
    } catch (e) {
      dispatch({
        type: "ALERT-ON",
        payload: {
          severity: "error",
          msg: e.message,
        },
      });
    }
  };

  const updatePassword = async () => {
    if(newPassword === reNewPassword) {
      try {
        const { data } = await changePassword({ password: newPassword }, token);
        if (!data?.success) {
          setNewPassword("");
          setReNewPassword("");
          dispatch({
            type: "ALERT-ON",
            payload: {
              severity: "error",
              msg: data?.message,
            },
          });
        } else {
          setNewPassword("");
          setReNewPassword("");
          dispatch({
            type: "ALERT-ON",
            payload: {
              severity: "success",
              msg: data?.message,
            },
          });
          history.push("/");
        }
      } catch(e) {
        dispatch({
          type: "ALERT-ON",
          payload: {
            severity: "error",
            msg: e.message,
          },
        });
      }
    } else {
      dispatch({
        type: "ALERT-ON",
        payload: {
          severity: "error",
          msg: "Passwords do not match. Check it again!",
        },
      });
    }
  }

  useEffect(() => {
    if (props.location.search !== undefined && props.location.search !== '' && props.location.search.split("=")[1] !== null) {
      setResetPasswordState(true);
      setToken(props.location.search.split("=")[1]);
    }
  })
  return (
    <div className="Login-Container">
      <img src={logo} alt="logo" />

      {
        resetPasswordState && token!==null ?
          <div className="Form-Container">
            <h2>Update Password</h2>

            <div className="input-box">
              <label>
                New Password
              </label>
              <input
                type="password"
                placeholder="Enter your new password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
            </div>

            <div className="input-box">
              <label>
                Re-Type New Password
              </label>
              <input
                type="password"
                placeholder="Re-type your new password"
                value={reNewPassword}
                onChange={(e) => setReNewPassword(e.target.value)}
              />
            </div>

            <div className="button-ctr">
              <p>
                Already have an account? <Link to="/">Login</Link>
              </p>
              <button onClick={updatePassword}>Update Password</button>
            </div>
          </div> 
          : 
          <div className="Form-Container">
            <h2>Forgot Password</h2>

            <div className="input-box">
              <label>
                Email
              </label>
              <input
                type="email"
                placeholder="Enter your email id"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            <div className="button-ctr">
              <p>
                Already have an account? <Link to="/">Login</Link>
              </p>
              <button onClick={triggerForgotPasswordEmail}>Send Reset Link</button>
            </div>
          </div>
      }
    </div>
  );
}

export default ForgotPassword;
