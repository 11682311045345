import React, { useEffect, useState } from "react";
import { BarChart, CartesianGrid, PieChart, Pie, Cell, Tooltip, Legend, XAxis, YAxis, Bar, LineChart, Line, ReferenceLine, LabelList } from "recharts";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import moment from "moment";

const ProjectReportTable = ({ data, selectedCurrency }) => {
    const { totalExpenses, projectCompletionPercentage, expenseVsTotalBudget, profit, resourceAllotment, committedVsActualTimeline: { committedTimeline, actualCompletionDate, isOverdue, daysDifference }, tasks } = data;

    const pieColors = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042", "#FF5733", "#3D9970", "#C70039"];
    const barColors = ["#0088FE", "#00C49F"];

    const [progress, setProgress] = useState(0);

    useEffect(() => {
        const animationDuration = 4000; // Animation duration in milliseconds
        const targetProgress = parseFloat(projectCompletionPercentage);

        const step = (targetProgress * animationDuration) / 100;
        let currentProgress = 0;

        const interval = setInterval(() => {
            currentProgress += step;
            if (currentProgress >= targetProgress) {
                currentProgress = targetProgress;
                clearInterval(interval);
            }

            setProgress(currentProgress);
        }, animationDuration / step);

        return () => clearInterval(interval);
    }, [projectCompletionPercentage]);

    return (
        <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
            <div className="bg-white p-5 border border-[#e8e8e8] shadow-sm rounded col-span-1 row-span-2">
                <h3 className="font-semibold text-16 text-grey-500 mb-2 w-full">Project Progress</h3>
                <div className="flex items-center justify-center">
                    <div className="flex flex-col justify-start w-1/2">
                        <div className="text-left">
                            <p className="text-md text-grey-500">Completed Tasks</p>
                            <p className="font-semibold text-32">{tasks.completed.length}</p>
                        </div>
                        <div className="text-left">
                            <p className="text-md text-grey-500">Pending Tasks</p>
                            <p className="font-semibold text-32">{tasks.pending.length}</p>
                        </div>
                    </div>
                    <div className="flex items-center justify-center w-1/2">
                        <div style={{ width: 160, height: 160 }}>
                            <CircularProgressbar
                                value={progress}
                                text={`${Math.round(progress)}%`}
                                styles={buildStyles({
                                    textSize: "16px",
                                    pathColor: `#8048f4`,
                                    textColor: "#333",
                                    trailColor: "#d6d6d6",
                                    trailWidth: 30
                                })}
                            />
                        </div>
                    </div>
                </div>
                <div className="flex flex-col w-full mt-10">
                    <span className="font-bold text-grey-500">Completed Tasks</span>
                    <ul style={{"list-style-type": "disc"}}>
                        {
                            tasks?.completed?.map((d, i) => (
                                <li className="text-grey-500 ml-4">{d.title}</li>
                            ))
                        }
                    </ul>
                    <span className="font-bold mt-3 text-grey-500">Pending Tasks</span>
                    <ul style={{"list-style-type": "disc"}}>
                        {
                            tasks?.pending?.map((d, i) => (
                                <li className="text-grey-500 ml-4">{d.title}</li>
                            ))
                        }
                    </ul>
                </div>
            </div>

            <div className="bg-white p-5 border border-[#e8e8e8] shadow-sm rounded col-span-2 flex flex-row">
                <div className="flex flex-col w-2/5 border-r border-[#e7e7e7]">
                    <div className="flex flex-col h-2/3">

                        <h3 className="font-semibold text-16 text-grey-500 mb-2 w-full">Total Profit</h3>
                        <div className="flex items-center h-full">
                            {/* <span className={profit >= 0 ? "text-green-600 text-24" : "text-red-600 text-24"}>{profit >= 0 ? "+" : "-"}</span> */}
                            <span className={`font-bold ${profit >= 0 ? "text-green-600 text-24" : "text-red-400 text-24"}`}>
                                {selectedCurrency} {Math.abs(profit)?.toLocaleString()}
                            </span>
                        </div>
                    </div>

                    <div className="flex flex-row justify-center items-center w-full h-1/3">
                        <div className="w-1/2">
                            <p className="font-normal text-14 text-grey-500 mb-2">Total Budget</p>
                            <div className="flex items-center w-full">
                                <span className="text-black text-16 font-bold">
                                    {selectedCurrency} {expenseVsTotalBudget?.totalBudget?.toLocaleString()}
                                </span>
                            </div>
                        </div>
                        <div className="w-1/2">
                            <p className="font-normal text-14 text-grey-500 mb-2">Total Expenses</p>
                            <div className="flex items-center w-full">
                                <span className="text-black text-16 font-bold">
                                    {selectedCurrency} {expenseVsTotalBudget?.utilizedBudget?.toLocaleString()}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-3/5">
                    <BarChart width={420} height={200} data={[expenseVsTotalBudget]}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="category" fontSize={12} />
                        <YAxis fontSize={12} />
                        <Tooltip />
                        <Legend formatter={(value, entry, index) => <span className="text-black text-12 uppercase">{value}</span>} />
                        <Bar dataKey="totalBudget" stackId="a" fill="#8048f4" radius={[8, 8, 0, 0]} barSize={30} />
                        <Bar dataKey="utilizedBudget" stackId="b" fill="#e3262f" radius={[8, 8, 0, 0]} barSize={30} />
                    </BarChart>
                </div>
            </div>

            <div className="bg-white p-5 border border-[#e8e8e8] shadow-sm rounded col-span-1">
                <div className="flex flex-col h-[240px]">
                    <div className="flex flex-col h-2/3">
                        <h3 className="font-semibold text-16 text-grey-500 mb-2 w-full">Project Timeline</h3>
                        <div className="flex items-center h-full">
                            {daysDifference ? <span className={`font-bold ${profit >= 0 ? "text-green-600 text-24" : "text-red-400 text-24"}`}>
                                {daysDifference * -1} days {daysDifference < 0 ? 'left' : 'late'}
                            </span> : <span className={`font-bold text-24 text-green-600`}>
                                {moment(committedTimeline).diff(new Date(), 'days')} days remaining
                            </span>}
                        </div>
                    </div>

                    <div className="flex flex-row justify-center items-center w-full h-1/3">
                        <div className="w-1/2">
                            <p className="font-normal text-14 text-grey-500 mb-2">Due Date</p>
                            <div className="flex items-center w-full">
                                <span className="text-black text-16 font-bold">
                                    {moment(committedTimeline).format('DD/MMM/YYYY')}
                                </span>
                            </div>
                        </div>
                        <div className="w-1/2">
                            <p className="font-normal text-14 text-grey-500 mb-2">Actual Completion Date</p>
                            <div className="flex items-center w-full">
                                <span className="text-black text-16 font-bold">
                                    {actualCompletionDate ? moment(actualCompletionDate).format('DD/MMM/YYYY') : '-'}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="bg-white p-5 border border-[#e8e8e8] shadow-sm rounded w-full">
                <h3 className="font-semibold text-16 text-grey-500 mb-2">Resource Allotment</h3>
                <div>
                    <PieChart width={300} height={200}>
                        <Pie data={Object.entries(resourceAllotment)} dataKey="1" nameKey="0" cx="50%" cy="50%" innerRadius={55} stroke="transparent">
                            {Object.entries(resourceAllotment)?.map((entry, index) => (
                                <Cell key={entry[0]} fill={pieColors[index % pieColors.length]} />
                            ))}
                        </Pie>
                        <Tooltip />
                        <Legend formatter={(value, entry, index) => <span className="text-black text-12">{value}</span>} />
                    </PieChart>
                </div>
            </div>
        </div>
    );
};

export default ProjectReportTable;
