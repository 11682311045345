const initialState = {
  open: false,
  severity: "",
  msg: "",
  plans: [],
};

const UtilityReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ALERT-ON":
      return {
        ...state,
        open: true,
        severity: action.payload.severity,
        msg: action.payload.msg,
      };
    case "ALERT-OFF":
      return {
        ...state,
        open: false,
        severity: "",
        msg: "",
      };
    case "GET-PLANS":
      return {
        ...state,
        plans: action.payload,
      };
    default:
      return state;
  }
};

export default UtilityReducer;
