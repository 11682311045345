// import { currentUserDetails } from "../../services/api.services";

const initialState = {
  isLoggedIn: false,
  token: "",
  selectedplan: {},
  profile: {},
  decode: {},
  invoices: [],
  invoiceSummary: {},
  projectSummary: {},
  salesGraphData: [],
  topClients: [],
  topProjects: [],
  toDoItems: [],
  selectedCurrency: ''
};

// REDUCERS
export const ProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOG-IN":
      localStorage.setItem("SYNCHRO-TOKEN", action.payload);
      localStorage.setItem("SYNCHRO-LOGGED", true);
      return {
        ...state,
        isLoggedIn: true,
        token: action.payload,
      };
    case "LOG-OUT":
      localStorage.removeItem("SYNCHRO-TOKEN");
      localStorage.removeItem("SYNCHRO-LOGGED");

      return {
        ...state,
        isLoggedIn: false,
        token: "",
        decode: {},
        profile: {},
        invoices: [],
      };
    case "GET-DETAILS":
      localStorage.setItem("_icon", action.payload.logoURL);
      return {
        ...state,
        profile: action.payload,
      };
    case "DECODE":
      return {
        ...state,
        decode: action.payload,
      };
    case "SELECT-PLAN":
      return {
        ...state,
        selectedplan: action.payload,
      };
    case "GET_INVOICES":
      return {
        ...state,
        invoices: action.payload,
      };
    case "GET_INVOICE_SUMMARY":
      return {
        ...state,
        invoiceSummary: action.payload,
      };
    case "GET_PROJECT_SUMMARY":
      return {
        ...state,
        projectSummary: action.payload,
      };
    case "GET_SALESGRAPH_DATA":
      return {
        ...state,
        salesGraphData: action.payload,
      };
    case "GET_TOP_CLIENTS":
      return {
        ...state,
        topClients: action.payload
      };

    case "GET_TOP_PROJECTS":
      return {
        ...state,
        topProjects: action.payload
      };
    case "ADD_TODO_ITEMS":
      return {
        ...state,
        toDoItems: [...state.toDoItems, action.payload]
      };
    case "GET_TODO_ITEMS":
      return {
        ...state,
        toDoItems: action.payload
      };
    case "REMOVE_TODO_ITEM": 
      return {
        ...state,
        toDoItems: state.toDoItems.filter((data) => {
          return data._id === action.payload
        })
      };
    case "SET_SELECTED_CURRENCY": {
      return {
        ...state,
        selectedCurrency: action.payload
      };
    }
    default:
      return state;
  }
};
