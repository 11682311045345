/* eslint-disable default-case */
import React from "react";
import "./CheckListItems.scss";

function CheckListItems({ inputData, setInputData }) {
  const removeListContent = (index) => {
    let data = inputData?.projectSteps?.filter((item, i) => i !== index);
    setInputData({
      ...inputData,
      projectSteps: data,
    });
  };

  const addNewBlankItem = () => {
    setInputData({
      ...inputData,
      projectSteps: inputData?.projectSteps ? [
        ...inputData?.projectSteps,
        { title: "", description: "", status: false }
      ] : [
        { title: "", description: "", status: false }
      ],
    });
  };

  return (
    <>
      <div className="checklist-items" id="checklist-items">
        {Array.isArray(inputData?.projectSteps) &&
          inputData?.projectSteps.map((data, index) => (
            <div className="item" key={index}>
              <input
                type="checkbox"
                checked={data.status}
                name="item-status"
                onChange={(e) => {
                  let data = inputData?.projectSteps?.map((item, i) => {
                    if (i === index) {
                      item.status = !item.status;
                    }
                    return item;
                  });
                  setInputData({
                    ...inputData,
                    projectSteps: data,
                  });
                }}
              />

              <input
                type="text"
                defaultValue={data?.title}
                placeholder="Enter item name"
                name="item-name"
                onChange={(e) => {
                  let data = inputData?.projectSteps?.map((item, i) => {
                    if (i === index) {
                      item.title = e.target.value;
                    }
                    return item;
                  });
                  setInputData({
                    ...inputData,
                    projectSteps: data,
                  });
                }}
              />
              <i
                class="fa fa-close"
                onClick={(e) => removeListContent(index)}
              ></i>
            </div>
          ))}
      </div>
      <button className="w-1/3 text-[black] bg-[#f2f2f3] mx-0 mt-2 px-2 py-1 rounded-md border-2 border-dashed border-[#dbdbdb]" onClick={() => addNewBlankItem()}>
        <i class="fa fa-plus" aria-hidden="true"></i> Add New Item
      </button>
    </>
  );
}

export default CheckListItems;
