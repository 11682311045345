/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";

function AuthRoute({ component, authed, ...rest }) {
  //   // const { state } = React.useContext(KPLContext);
  
  const Component = component;

  const [data, setData] = useState(
    useSelector((state) => state.Profile.isLoggedIn)
  );
  const [state, setState] = React.useState(
    localStorage.getItem("SYNCHRO-LOGGED")
  );

  return (
    <Route
      {...rest}
      render={(props) =>
        state ? <Component {...props} /> : <Redirect to="/" />
      }
    />
  );
}

export default AuthRoute;
